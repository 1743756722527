<template>
  <div class="md-layout md-gutter listItem-work-container">
    <div class="md-layout-item md-size-80 work-items">
      <div class="listItem-work">
        <div class="work-item" v-for="(item, index) in [1, 2, 3, 4]" :key="index">
          <div class="layout-header-exspan-more" @click="toogleItem(index)">
            <div class="toolbar-title">
              <md-icon>arrow_drop_down</md-icon>
              <div class="toolbar-section-start">
                <h1 class="md-title">Sales {{ item }}</h1>
                <div class="subtitle">
                  Khu vực / Chuyên môn - 6 vị trí công việc - 136 thành viên
                </div>
              </div>
            </div>
            <div class="toolbar-create-position">
              <div class="toolbar-action">
                <md-icon>add</md-icon>
                <span class="add-position">Create Position</span>
                <md-icon>expand_more</md-icon>
              </div>
            </div>
          </div>

          <div class="work-item-content" v-if="toogleList.includes(index)">
            <md-table>
              <md-table-row class="head-table-row-item">
                <md-table-head class="stt-numeric"  md-numeric>#</md-table-head>
                <md-table-head>Vị trí</md-table-head>
                <md-table-head>Khoảng lương</md-table-head>
                <md-table-head>Thành viên</md-table-head>
              </md-table-row>

              <md-table-row v-for="(item, index) in [1, 2, 3, 4, 5, 6]" :key="index">
                <md-table-cell md-numeric>{{ item }}</md-table-cell>
                <md-table-cell>
                  <md-list-item>
                    <md-icon class="md-primary">email</md-icon>

                    <div class="md-list-item-text">
                      <span>Giám đốc kinh doanh</span>
                      <div class="position-member">
                        <span>Giám đốc</span> > <span>gdkd</span>
                      </div>
                    </div>
                  </md-list-item>
                </md-table-cell>
                <md-table-cell>
                  <md-list-item>
                    <div class="group-member">
                      <md-icon class="md-primary">group</md-icon>
                      <span class="member-total">1</span>
                    </div>

                    <div class="md-list-item-text rank-salary-member">
                      <span class="rank-salary">40M - 100M đ</span>
                    </div>
                  </md-list-item>
                </md-table-cell>
                <md-table-cell>
                  <md-list-item class="member-list">
                    <div class="members">
                      <md-avatar>
                        <img src="/img/toolbar/logo_toolbar.svg" alt="Avatar">
                      </md-avatar>
                      <md-avatar>
                        <img src="/img/toolbar/logo_toolbar.svg" alt="Avatar">
                      </md-avatar>
                      <md-avatar>
                        <img src="/img/toolbar/logo_toolbar.svg" alt="Avatar">
                      </md-avatar>
                    </div>

                    <div class="button-acton-member">
                      <md-menu md-size="medium" md-align-trigger>
                        <md-button md-menu-trigger>
                          <span>Sửa</span>
                        <span class="icon-edit"> <md-icon>arrow_drop_down</md-icon></span>
                        </md-button>

                        <md-menu-content>
                          <md-menu-item>My Item 1</md-menu-item>
                          <md-menu-item>My Item 2</md-menu-item>
                          <md-menu-item>My Item 3</md-menu-item>
                        </md-menu-content>
                      </md-menu>
                    </div>
                  </md-list-item>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          
        </div>
      </div>
    </div>
    <div class="md-layout-item md-size-20 layout-option">
      <div class="display-option border-bottom">
        <div class="head-option">
          Display options
        </div>
        <div class="options">
          <div class="radio-option">
            <md-radio v-model="radio" :value="false">All Positions</md-radio>
          </div>
          <div class="radio-option">
            <md-radio v-model="radio" value="my-radio">Positions with employees</md-radio>
          </div>
          <div class="radio-option">
            <md-radio v-model="radio">Positions without employees</md-radio>
          </div>
        </div>
      </div>
      <div class="display-option orderings">
        <div class="head-option">
          Orderings
        </div>
        <div class="options">
          <div class="radio-option">
            <md-radio v-model="radio2" :value="false">Default order</md-radio>
          </div>
          <div class="radio-option">
            <md-radio v-model="radio2" value="my-radio1">Name A-Z</md-radio>
          </div>
          <div class="radio-option">
            <md-radio v-model="radio2" value="my-radio2">Code A-Z</md-radio>
          </div>
          <div class="radio-option">
            <md-radio v-model="radio2" value="my-radio3">Numbers of Employees</md-radio>
          </div>
        </div>
      </div>
    </div>
    <!-- teleport -->
    <Teleport to="#menu-card">
      <div class="menu-item menu-item-active">Ví trí công việc</div>
      <div class="menu-item">Phân loại</div>
    </Teleport>
  </div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
      search: null,
      searched: [],
      toogleList: [],
      radio: false,
      radio2: false
    };
  },
  created () {
  },
  methods: {
    toogleItem(item){
      if(this.toogleList.indexOf(item) === -1) {
        this.toogleList.push(item);
      }else{
        this.toogleList.splice(this.toogleList.findIndex((i) => i === item), 1)
      }
    }
  }
};
</script>
<style lang="scss">
// .table-toolbar-header{
//   .toolbar-left {
//     display: flex;
//     grid-gap: 10px;
//   }
//   padding: 0 20px;
//   .md-title{
//     padding-bottom: 0 !important;
//   }
  // .toolbar-action{
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   grid-gap: 10px;
  //   .add-position{
  //     border-right: 1px solid #dddcdc;
  //     adding-right: 10px;
  //   }
  // }
// }
.listItem-work-container{
  margin-left: 0 !important;
  .work-item{
    border-radius: 5px;
    margin-bottom: 10px;
    background: #fff;
    .layout-header-exspan-more {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .toolbar-title{
        display: flex;
        align-items: center;
        grid-gap: 10px;
        .md-title{
          margin-bottom: 0 !important;
        }
        .subtitle {
          font-size: 12px;
        }
      }
      .toolbar-create-position{
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: 10px;
        .add-position{
          border-right: 1px solid #dddcdc;
          padding: 0 10px;
          color: #2079e2;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .work-item-content{
      .group-member{
        margin-right: 20px;
      }
      .button-acton-member{
        .md-button{
          background-color: #fff !important;
          width: max-content;
          padding: 15px 5px 15px 15px;
          box-shadow: none;
          border: 1px solid #e3e3e3;
          .icon-edit{
            .md-icon{
              color: #000 !important;
            }
          }
        }
        .md-ripple{
          padding: 0 !important;
          color: #000;
          font-weight: 500;
          text-transform: capitalize;
          font-size: 14px;
        }
      }
      .md-list-item-content{
        display: flex;
        justify-content: space-between !important;
      }
      .members{
        .md-avatar{
          border-radius: 100%;
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          min-width: 30px;
          background: #f3f3f3;
          margin-left: -10px;
          z-index: 1;
        }
      }
      
      .head-table-row-item{
        background: #eaeaea;
        .stt-numeric{
          width: 50px;
        }
        .md-table-head-label{
          font-size: 14px;
        }
      }
    }
  }
  .orderings{
    margin-top: 10px;
  }
  .work-items{
    padding-left: 0 !important;
  }
  .layout-option{
    height: max-content;
    min-height: 400px;
    background: #fff;
    border-radius: 5px;
    padding: 10px 0;
    .border-bottom{
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 15px;
    }
    .head-option {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 10px;
      color: #8b8b8b;
    }
    .md-radio{
      margin: 0;
      .md-radio-label{
        font-size: 14px;
      }
    }
  }
}


</style>
