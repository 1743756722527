<template>
  <div class="sync-file-modal-container">
    <modal name="sync-file-modal"
           :click-to-close="false"
           width='500px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <form class="" @submit.prevent="save()">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">{{title}}</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('sync-file-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content class="import-coupon-modal">
          <div class="md-gutter pt-15px pb-15px">
            <label class="required heading-400 neutral_3 mb-5px">Đồng bộ theo</label>
            <v-select :options="options" label="name" placeholder="ID nhân viên hoặc tên tài khoản"
              :reduce="(option) => option.type"
              :clearable="false"
              :searchable="false"
              v-model="valueSelect">
            </v-select>
          </div>
          <div class="md-gutter pt-15px pb-15px">
            <label class="required heading-400 neutral_3 mb-5px">Chọn file đồng bộ</label>
            <div class="file-input-upload">
              <input type="file" class="form-control input-custom input-file" @change="onFileChange" required
                   name="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
              <a href="https://api.azagroup.asia/api/v1/download/198521" target="_blank">
                <md-icon title="Tải file mẫu">download</md-icon>
              </a> 
            </div>
          </div>
        </md-card-content>

        <md-dialog-actions>
          <button :class="{'disabled': (file == '')}" type="submit" class="btn-active md-button cursor-pointer">Đồng bộ</button>
          <div class="btn-stroke md-button" @click="$modal.hide('sync-file-modal')">Huỷ</div>
        </md-dialog-actions>
      </form>
    </modal>
  </div>
</template>

<script>

import TimeSheetManagementService from "@/store/services/TimeSheetManagementService";
import EmployeeService from "@/store/services/EmployeeService";

export default {
  name: 'sync-file-modal',
  computed: {},
  created() {
  },
  data() {
    return {
      show: false,
      file: '',
      user: this.$store.state.auth.user,
      title: '',
      options: [
        {type: 1, name: 'ID nhân viên'},
        {type: 2, name: 'Tên tài khoản'}
      ],
      valueSelect: 1
    }
  },
  methods: {
    async importFileExcel() {
      const formData = new FormData();

      formData.append("file", this.file);
      // 1: Đồng bộ theo ID nhân viên, 2 đồng bộ theo Tên tài khoản
      formData.append("sync_type", this.valueSelect);

      await EmployeeService.importUser(formData).then(res => {
        if (res) {
          this.show = false;
          if (res.status) {
            this.$store.dispatch("alerts/success", 'Đồng bộ file thành công');
            this.$modal.hide('sync-file-modal')
          } else {
            this.$store.dispatch("alerts/error", res.message);
          }
        }
      }).catch(err => {
        this.show = false;
        this.$store.dispatch("alerts/error", 'Đồng bộ file không thành công.');
      });
    },

    async onFileChange(e) {
      this.file = e.target.files[0];
    },

    save() {
      this.show = true;
      this.importFileExcel();
    },

    beforeOpened(event) {
      this.title = event.params.title;
    },

    beforeClosed(event) {

    },

  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 300px !important;
  }
}

.import-coupon-modal {
  min-height: 300px !important;
}

.sync-file-modal * {
  font-size: 14px;
}

.file-input-upload{
  display: flex;
  grid-gap: 10px;
  .md-icon{
    border: 1px solid #EDEFF5;
    height: 36px;
    width: 36px;
    border-radius: 4px;
    &:hover{
      cursor: pointer;
    }
  }
}
</style>
