export const GROUP_TYPE = {
    //0: Nhóm làm việc - 1: Nhóm kín - 2: Nhóm bí mật - 3: Tường công ty - 4: Group
    WORK_GROUP: 0,
    PRIVATE_GROUP: 1,
    SECRET_GROUP: 2,
    COMPANY_WALL_GROUP: 3,
    BRANCH_GROUP: 4,
};
export const POST_TYPE = {
    TEXT: 'TEXT',                   //Bài viết chỉ có text
    IMAGE: 'IMAGE',                 //Bài viết có chứa 1 ảnh
    ALBUM: 'ALBUM',                 //Bài viết chứa nhiều ảnh
    SUB_ALBUM: 'SUB_ALBUM',         //Bài viết con cho từng ảnh trong bài viết ALBUM
    FILE: 'FILE',                   //Bài viết chứa file
    CREATE_GROUP: 'CREATE_GROUP',   //Bài viết khi tạo 1 group
};
export const PERMISSION_CODE = {
    ADMIN_HRM: "admin.hrm",
    ADMIN_REWARD_MANAGE: "admin.reward_manage",
    ADMIN_SETTINGS: "admin.settings",
    ADMIN_TIMESHEET: "admin.timesheet",
    ADMIN_CURRENCY_MANAGE: "admin.currency_manage",
    ADMIN_CURRENCY_EDIT: "admin.currency_edit",
    ADMIN_SHOP_MANAGE: "admin.shop_manage",
    ADMIN_SHOP_EDIT: "admin.shop_edit"
};

export const SaleHeaderTeleport = {
    TONG_QUAN: 'tong-quan',
    THEO_NHAN_VIEN: 'theo-nhan-vien',
    THEO_SAN_PHAM: 'theo-san-pham',
};

export const TimesheetTeleport = {
    BANG_CONG: 'chi-tiet',
    LEAVE_REQUEST: 'request',
};

export const LEAVE_TYPE = {
    LEAVE_APPLICATION: 1, // Đơn xin nghỉ
    LEAVE_EARLY: 2, // Xin về sớm
    ADJUSTMENT_OF_ATTENDANCE: 3, // Kháng cáo bảng công
    VALUABLE_VACATION: 4, // Xin nghỉ có công
};

export const LEAVE_REQUEST_STATUS = {
    APPROVED: 1,
    REJECT: 2,
    WAITING_APPROVE: 3,
};
export const keywordTypeList = [
    {type: 'fullname', name: 'Tên đầy đủ'},
    {type: 'username', name: 'Tên đăng nhập'},
    {type: 'id', name: 'ID'},
    {type: 'timekeeper_id', name: 'Mã chấm công'},
    {type: 'email', name: 'email'},
    {type: 'phone', name: 'phone'},
];

export const SHIFT_TYPE = {
    FULL_DAY: 0,
    MORNING: 1,
    AFTERNOON: 2,
};

export const ShopManagementTeleport = {
    SHOP_LIST: 'danh-sach',
    SHOP_HISTORIES: 'lich-su',
};