<template>
  <div class="approval-reason-modal__container">
    <modal name="approval-reason-modal"
           :click-to-close="false"
           width='740px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
    >
      <div class="modal-content">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="heading-500">{{headerTitle}}</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('approval-reason-modal')">close</span>
          </md-dialog-title>
          <md-card-content>
            <div class="text-400-medium neutral_2 mb-10px">
              * ĐỒNG Ý không bắt buộc nhập mô tả, TỪ CHỐI bắt buộc nhập mô tả
            </div>
            <textarea v-model="description"></textarea>
          </md-card-content>
          <md-dialog-actions>
            <button type="submit" class="btn-active md-button" @click="approveLeaveRequest">Đồng ý</button>
            <button class="btn-stroke md-button" @click="$modal.hide('approval-reason-modal')">Huỷ</button>
          </md-dialog-actions>
        </md-card>
      </div>
    </modal>
  </div>
</template>

<script>

import TimeSheetService from "../../../../store/services/TimeSheetService";
import {helpers} from "../../../../helper/helpers";
import {eventBus} from "../../../../config/event-bus";

export default {
  name: 'approval-reason-modal',
  computed: {
    headerTitle() {
      let title = 'Lí do ';
      let textByType = this.status === 1 ? 'phê duyệt' : 'từ chối';
      title = title + textByType + ` đề xuất ${this.approveLeaveRequestForm.name}`;
      return title;
    }
  },
  components: {},
  data: () => ({
    isLoadingBtn: false,
    approveLeaveRequestForm: {},
    status: null,
    description: ''
  }),
  mounted() {
  },
  created() {
  },
  methods: {
    beforeOpened(event) {
      this.approveLeaveRequestForm = event.params.leaveRequestData;
      this.status = event.params.status;
      this.description = '';
    },
    approveLeaveRequest() {
      if (this.status === 2 && helpers.isEmpty(this.description)) {
        this.$store.dispatch("alerts/error", 'Bạn chưa nhập lí do');
        return;
      }
      TimeSheetService.approveLeaveRequest({
        leave_id: this.approveLeaveRequestForm.id,
        description: this.description,
        status: this.status
      }).then(res => {
        if (res && res.data) {
          this.$emit("approve-leave-request");
          eventBus.$emit('approve-leave-request');
          this.$modal.hide('approval-reason-modal');
        }
      });
    }
  }
}
</script>

<style lang="scss">
.approval-reason-modal__container {
  textarea {
    height: 120px;
    width: 100%;
    resize: none;
  }
}
</style>
