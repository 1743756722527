import router from "@/router";
import AuthService from "../services/AuthService";
import axios from "axios";
import Cookies from 'js-cookie';


export default {
    state: {
        isAuthenticated: localStorage.getItem("access_token") !== null,
        user: {},
        userPosition: {},
        userTeam: {},
        userTitle: {},
        modalIsOpen: false,
        permission: [],
        // tabActive: 0,
    },

    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },

        user(state) {
            return state.user;
        }
    },

    mutations: {
        isAuthenticated(state, data) {
            state.isAuthenticated = data.isAuthenticated;
        },

        updateUser(state, data) {
            state.user = data;
        },

        updateUserPosition(state, data) {
            state.userPosition = data;
        },

        updateUserTeam(state, data) {
            state.userTeam = data;
        },

        updateUserTitle(state, data) {
            state.userTitle = data;
        },

        updateModalIsOpen(state, data) {
            state.modalIsOpen = data;
        },

        // updateTabActive(state, data) {
        //     state.tabActive = data;
        // },
        updatePermission(state, data) {
            state.permission = data;
        },
    },

    actions: {
        login({commit, dispatch}, payload) {
            //get X-XSRF-TOKEN
            return AuthService.login(payload).then(res => {
                if (res.data && res.status) {
                    axios.defaults.headers.common.Authorization = `Bearer ${res.data.authorization.access_token}`;

                    localStorage.setItem("access_token", res.data.authorization.access_token);
                    localStorage.setItem("user_id", res.data.user.id);
                    Cookies.set('access_token', res.data.authorization.access_token);
                    Cookies.set('user_id', res.data.user.id);
                    commit("isAuthenticated", {isAuthenticated: true});
                    commit("updateUser", res.data.user);
                    if (res.data.permission) {
                        commit("updatePermission", res.data.permission);
                    } else {
                        commit("updatePermission", [{
                            name: "admin.hrm",
                            display_name: "QUản lý nhân sự"
                        },
                            {
                                name: "admin.reward_manage",
                                display_name: "Quan ly thulng"
                            },
                            {
                                name: "admin.timesheet",
                                display_name: "Quan ly thulng"
                            },
                            {
                                name: "admin.settings",
                                display_name: "Quan ly thulng"
                            },
                        ]);
                    }
                    location.href = '/'
                }
                return res;
            });
        },

        logout({commit, dispatch}, payload) {
            window.isAuthenticated = false;
            Cookies.remove('access_token');
            Cookies.remove('user_id');
            localStorage.removeItem("access_token");
            localStorage.removeItem("user_id");
            commit("isAuthenticated", {isAuthenticated: false});
            // router.push({name: "Login"});
            axios.defaults.headers.common.Authorization = ``;
            window.location.href = '/login';

            // return AuthService.logout();
        },

        getXsrfToken(context, payload) {
            AuthService.getXsrfToken().then(res => {
                let XsrfToken = res.config.headers['X-XSRF-TOKEN'];
                axios.defaults.headers.common.X_XSRF_TOKEN = XsrfToken;
            });
        },

        setAuth() {
            AuthService.getXsrfToken().then(res => {
                const XsrfToken = res.config.headers['X-XSRF-TOKEN'];
                axios.defaults.headers.common.X_XSRF_TOKEN = XsrfToken;
                localStorage.setItem("X_XSRF_TOKEN", XsrfToken);
            });

            // axios.interceptors.request.use(function (config) {
            //     console.log('axios.interceptors');
            //     const access_token = localStorage.getItem("access_token");
            //     const XsrfToken = localStorage.getItem("X_XSRF_TOKEN");
            //
            //     if (access_token) {
            //         config.headers.Authorization = `Bearer ${access_token}`;
            //         config.headers.X_XSRF_TOKEN = XsrfToken;
            //     }
            //
            //     return config;
            // });
        },

        // setAuth(auth) {
        //   // axios.interceptors.request.use(function (config) {
        //   //   if (auth) {
        //   axios.defaults.headers.common['Authorization'] = `Bearer ${auth.access_token}`;
        //   // config.headers.Authorization = `Bearer ${auth.access_token}`;
        //   //   }
        //   //   return config;
        //   // });
        // }
    }
};
