<template>
  <div class="employees-container">
    <div class="header-label">
      <div class="label">
        Lịch sử thay đổi shop
      </div>
    </div>
    <div class="filter-container mt-20px">
      <div class="d-flex-center-between">
        <div></div>
        <div class="mt-10px filter-sale-date-picker d-flex-center-between">
          <div class="filter-datepicker d-flex-center-between">
            <div class="datepicker">
              <date-picker
                  class="datetime-picked"
                  placeholder="Select date range"
                  v-model="timeRange"
                  range-separator=" - "
                  defaultDate="01/05/2023 - 31/05/2023"
                  format="DD/MM/YYYY"
                  type="date"
                  :clearable="true"
                  :confirm="false"
                  :confirm-text="'Xác nhận thời gian tìm kiếm.'"
                  :lang="lang"
                  @change="changeDate"
                  :range="true"
              >
                <template v-slot:input>
                  <input v-model="dateSelection" type="text" name="date"
                         readonly
                         autocomplete="off"
                         placeholder="Chọn thời gian"
                         class="mx-input"/>
                </template>
                <template v-slot:icon-calendar>
                  <md-icon>arrow_drop_down</md-icon>
                </template>
              </date-picker>
            </div>
            <div class="filter-button">
              <button class="btn filter-report" @click="getUpdatedShopHistories">
                Tạo báo cáo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-20px filter-selectbox-primevue mb-10px d-flex-center-between">
      <div></div>
      <pagination-template :params="pagingParams" @change-params="changePagingParam"/>
    </div>

    <updated-shop-histories-table :tableData="tableData" />
  </div>
</template>

<script>

import PaginationMixin from "@/mixins/pagination-mixin";
import PaginationTemplate from "@/components/PaginationTemplate";
import {helpers} from "@/helper/helpers";
import ShopManagementService from "@/store/services/ShopManagementService";
import UpdatedShopHistoriesTable from "@/pages/Workplace/Pages/ShopManagement/UpdatedShopHistoriesTable";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import vue2DatePicker from "@/mixins/config-vue2-datepicker";

export default {
  components: {
    PaginationTemplate,
    DatePicker,
    UpdatedShopHistoriesTable
  },

  mixins: [PaginationMixin, vue2DatePicker],

  data() {
    const date = new Date();
    return {
      helpers,
      tableData: [],
      timeRange: [],// ngày tháng đầu tiên của tháng
      timesheetFilterValue: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
      },
      dateSelection: '',
    };
  },
  created() {
    this.getUpdatedShopHistories();
    let dateNow = new Date();
    let start_date = moment(dateNow).startOf('month');
    let end_date = moment(dateNow).endOf('month');
    this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
    this.getUpdatedShopHistories();
  },
  mounted() {
  },
  methods: {
    changePagingParam(value) {
      this.pagingParams = value;
      this.getUpdatedShopHistories();
    },
    async getUpdatedShopHistories(){
      this.isLoading = true;
      let paging = {
        page: this.pagingParams.page,
        per_page: this.pagingParams.perPage
      };
      let params = {...this.timesheetFilterValue, ...paging};
      console.log(params)
      try {
        await ShopManagementService.getUpdatedShopHistories(params).then(res => {
          const resData = res.data;
          this.pagingParams.lastPage = resData.last_page;
          this.pagingParams.total = resData.total;
          this.tableData = resData.data;

        });
      } catch (error) {
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    changeDate(value) {
      this.dateSelection = '';
      this.timesheetFilterValue = {
        start_date: '',
        end_date: '',
      }
      if(value[0] == null && value[1] == null){
        return;
      }

      let start_date = moment(value[0]).startOf('month');
      let end_date = moment(value[1]).endOf('month');

      this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
      this.timesheetFilterValue = {
        start_date: start_date.format('YYYY-MM-DD'),
        end_date: end_date.format('YYYY-MM-DD'),
      }
      // =====================getTopRevenue================ //
    },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.md-menu-content {
  z-index: 9999;
}

.employees-container {
  background: #fff;
  padding: 10px;

  .search-container {
    .multiselect {
      width: 250px;
    }
    .keyword {
      height: 40px;
      border: 1px solid #EDEFF5;
    }
    .keyword-type {
      width: 165px !important;
      border-radius: initial !important;
      .vs__dropdown-toggle {
        cursor: pointer;
      }
      .vs__actions {
        .vs__clear {
          display: none;
        }
      }
    }
  }

  .header-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F3F4;
    font-size: 14px;

    .actions {
      display: flex;
      grid-gap: 20px;
      padding: 10px;

      .tab-name {
        font-weight: 500;
        color: #898989;
        position: relative;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }

        .tab-active {
          color: #1200ff;
          margin-left: 15px;

          &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            top: 50%;
            left: 0;
            background: #1200ff;
            transform: translateY(-50%);
          }
        }
      }
    }

    .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem;
      align-items: center;
      grid-gap: 10px;

      .total-records-pagination {
        font-size: 13px;

        .total {
          color: #000;
          font-weight: 500;
        }
      }

      .md-menu {
        align-items: center;
        display: flex;
      }

      .md-button {
        background-color: unset !important;
        color: #000 !important;
        height: max-content;
        box-shadow: unset;

        .md-ripple {
          padding: 0 !important;

          .md-button-content {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .ve-table-layout {
    .ve-table-container {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #8888887a;
        border-radius: 5px;

        &:hover {
          background: #555;
        }
      }
    }

    .ve-table-body {
      .address {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .money {
        font-weight: 500;
      }

      .employee-avatar {
        .md-avatar {
          border-radius: 100%;
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          min-width: 30px;
          background: #f3f3f3;
          margin-right: 5px;
          z-index: 1;
        }
      }

      .number-employee {
        color: #1fb800;
        font-weight: 500;
      }

      .status-employee {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        margin: auto;
      }

      .non-active {
        border: 1px solid #1fb800;
        background: #1fb8001a;
      }

      .active {
        background: #1fb800;
      }
    }
  }
  .datepicker{
    margin-right: 10px;
    width: 200px;
    .mx-datepicker-range{
      width: 100%;
    }
    .mx-input-wrapper{
      width: max-content;
    }
  }
}
</style>
