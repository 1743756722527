<template>
  <div>
    <modal name="edit-user-daily-timesheet-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom edit-user-daily-timesheet-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <md-card class="modal-header-custom">
        <md-dialog-title>
            <span class="neutral_1 heading-500">Chỉnh sửa chấm công ngày</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="closeModal">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content>
        <div class="md-gutter">
          <div class="start-day">
            <div class="label">
              <label>Nhân viên</label>
            </div>
            <div class="input input-fake">{{ user && user.fullname ? user.fullname : '' }}</div>
          </div>
          <div class="date-in-month">
            <div class="label">
              <label>Ngày</label>
            </div>
            <div class="day">
              <!-- <multiselect v-model="day" @select="changeDate" :options="options"></multiselect> -->
              <date-picker
                v-model="day"
                format="YYYY-MM-DD"
                type="date"
                placeholder="Chọn ngày"
                @change="changeDate($event)"
              ></date-picker>
            </div>
          </div>
          <div class="start-day">
            <div class="label">
              <label>Giờ checkin</label>
            </div>
            <date-picker v-model="checkin_time" format="HH:mm" type="time" placeholder="Giờ checkin"></date-picker>
          </div>
          <div class="end-day">
            <div class="label">
              <label>Giờ checkout</label>
            </div>
            <date-picker v-model="checkout_time" format="HH:mm" type="time" placeholder="Giờ checkout"></date-picker>
          </div>
        </div>
      </md-card-content>

      <md-dialog-actions>
        <button type="submit" class="btn-active md-button" @click="updateDailyTimesheet">
          <vue-element-loading :active="loading" spinner="bar-fade-scale"/>
          Đồng ý
        </button>
        <button class="btn-stroke md-button" @click="closeModal">Huỷ</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import AdminService from "../../../store/services/AdminService";
import DatePicker from 'vue2-datepicker';
import moment from "moment";
// import multiselect from "vue-multiselect";
export default {
  name: 'edit-user-daily-timesheet-modal',
  components: {
    DatePicker,
    // multiselect
  },
  mounted() {
  },
  data: () => ({
    checkin_time: null,
    checkout_time: null,
    day_info: null,
    user: null,
    day: new Date(),
    options: [],
    currentDate: null,
    loading: false
  }),
  computed: {
  },
  methods: {
    beforeOpened(event) {
      this.day_info = event.params.day;
      this.user = event.params.user;
      this.loading = false;
      this.currentDate = null;

      this.options = [];
      let current_date = moment().format('YYYY-MM-DD');
      if(this.day_info && this.day_info.date){
        this.currentDate = moment(this.day_info.date).format('YYYY-MM-DD');
        this.day = new Date(this.currentDate);
        current_date = moment(this.day_info.date);
        this.changeDate(this.currentDate);
      } else {
        this.day = new Date();
      }

      let checkin = null;
      let checkout = null;
      if(this.day_info.daily_timesheet.checkin_online_time && this.day_info.daily_timesheet.checkout_online_time){
        checkin = this.day_info.daily_timesheet.checkin_online_time;
        checkout = this.day_info.daily_timesheet.checkout_online_time;
      } else if(this.day_info.daily_timesheet.checkin_time && this.day_info.daily_timesheet.checkout_time){
        checkin = this.day_info.daily_timesheet.checkin_time;
        checkout = this.day_info.daily_timesheet.checkout_time;
      }

      if(this.day_info && this.day_info.daily_timesheet){
        this.checkin_time = new Date(`${this.day_info.date} ${checkin}`);
        this.checkout_time = new Date(`${this.day_info.date} ${checkout}`);
      }

      const endOfMonth = moment(current_date).endOf('month').format('DD');
      const currentMonth = moment(current_date);
      for (let day = 1; day <= endOfMonth; day++) {
        const formattedDate = currentMonth.date(day).format('YYYY-MM-DD');
        this.options.push(formattedDate);
      }
    },
    beforeClosed(event) {
      this.checkin_time = null;
      this.checkout_time = null;
    },

    closeModal(){
      this.$modal.hide('edit-user-daily-timesheet-modal')
    },

    async updateDailyTimesheet(){
      if(this.loading){
        return;
      }

      if(this.checkout_time && !this.checkin_time){
        this.checkin_time = this.checkout_time;
      }

      const params = {
        checkin_time: this.checkin_time ? moment(this.checkin_time).format('HH:mm') : '',
        checkout_time: this.checkout_time ? moment(this.checkout_time).format('HH:mm') : '',
        date: this.currentDate,
        user_id: this.user.id,
        timesheet_daily_id: this.day_info?.work_day?.id ?? ''
      }

      this.loading = true;
      try {
        await AdminService.updateUserDailyTimesheet( params).then(res => {
            if(res){
              this.$emit('reload-data', res.data);
              this.$store.dispatch("alerts/success", 'Chỉnh sửa thành công');
              this.$modal.hide('edit-user-daily-timesheet-modal');
            }
          });
      } catch (error) {
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
      }
      this.loading = false;
    },

    validation(){
      if(!this.checkin_time){
        this.$store.dispatch("alerts/error", 'Vui lòng nhập giờ checkin.');
        return true;
      } else if(!this.checkout_time){
        this.$store.dispatch("alerts/error", 'Vui lòng nhập giờ checkout.');
        return true;
      }
    },

    changeDate(day){
      day = moment(day).format('YYYY-MM-DD');
      this.currentDate = day;
      try {
        const params = {
            "date": day,
            "user_id": this.user.id
        }
        // console.log(params);
        AdminService.getWorkDayByDateAndUser(params).then(res => {
            if(res && res.status){
              this.checkin_time = res.data.work_day ? new Date(`${day} ${res.data.work_day.checkin_time}`) : null;
              this.checkout_time = res.data.work_day ? new Date(`${day} ${res.data.work_day.checkout_time}`) : null;
            }
          });
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
      }
    }
  }
}
</script>

<style lang="scss">
.edit-user-daily-timesheet-modal{
  .mx-datepicker{
    width: 100% !important;
    margin-bottom: 10px;
  }
  .holiday-name{
    margin-bottom: 10px;
  }
  .input{
    height: 35px;
    display: block;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    padding: 6px 8px;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  .input-fake{
    background-color: #eee;
  }
  .date-in-month{
    .multiselect__tags{
      padding: 8px 40px 0 0;
      .multiselect__single{
        font-size: 13px;
      }
    }
  }
}
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
  .loading-ex{
    position: unset !important;
  }
}
.mx-datepicker-popup{
  z-index: 99999 !important; 
}
</style>
