import BaseService from "./BaseService";

export default class FilterService extends BaseService {

  static async getCountryShop(params = {}) {
    const res = await this.get(`/common/country-shop`, params);
    return res.data;
  }

  static async getMarketingPerfomance(params = {}) {
    const res = await this.get(`/common/marketing-platform`, params);
    return res.data;
  }
  
}

