import BaseService from "./BaseService";

export default class AdminService extends BaseService {

  static async getEmployeeRewardOverView(params = {}) {
    const res = await this.get(`/reward/overview`, params);
    return res.data;
  }

  static async getEmployeeRewardByUser(params = {}) {
    const res = await this.get(`/reward/rewardByUser`, params);
    return res.data;
  }

    static async createGroup(params = {}) {
        const res = await this.post(`/groups`, params);
        return res.data;
    }

    static async getTimesheet(params = {}) {
      const res = await this.get(`/timesheet`, params);
      return res.data;
    }
    static async getDepartmentAssignedUser(params = {}) {
      const res = await this.get(`/team/me`, params);
      return res.data;
    }
  
    static async rewardByUserExport(params = {}) {
      const res = await this.get(`/reward/rewardByUser/export`, params);
      return res.data;
    }

    static async getWorkScheduleDetail(params = {}) {
        // const res = await this.get(`/timesheet/schedule/${params.id}`);
        const res = {
            "status": true,
            "message": null,
            "data": {
                "id": 1,
                "name": "a",
                "type": "string",
                "shift_number": 12,
                "required_checkout": true,
                "working_hour": 12,
                "accepted_late_checkin": 1,
                "accepted_early_checkout": 1,
                "ts_day": [
                    {
                        "id": 1,
                        "day_of_week": 0,
                        "dayoff": true
                    },
                    {
                        "id": 1,
                        "day_of_week": 1,
                        "dayoff": false,
                        "ts_shift": [
                            {
                                "id": 1,
                                "order": 1,
                                "start_time": 28800,
                                "end_time": 41220
                            },
                            {
                                "id": 2,
                                "order": 2,
                                "start_time": 46800,
                                "end_time": 64800
                            }
                        ]
                    },
                    {
                        "id": 1,
                        "day_of_week": 2,
                        "dayoff": false,
                        "ts_shift": [
                            {
                                "id": 1,
                                "order": 1,
                                "start_time": 28800,
                                "end_time": 41220
                            },
                            {
                                "id": 2,
                                "order": 2,
                                "start_time": 46800,
                                "end_time": 64800
                            }
                        ]
                    },
                    {
                        "id": 1,
                        "day_of_week": 3,
                        "dayoff": false,
                        "ts_shift": [

                            {
                                "id": 2,
                                "order": 2,
                                "start_time": 46800,
                                "end_time": 64800
                            }
                        ]
                    },
                    {
                        "id": 1,
                        "day_of_week": 4,
                        "dayoff": false,
                        "ts_shift": [
                            {
                                "id": 1,
                                "order": 1,
                                "start_time": 28800,
                                "end_time": 41220
                            }
                        ]
                    },
                    {
                        "id": 1,
                        "day_of_week": 5,
                        "dayoff": false,
                        "ts_shift": [
                            {
                                "id": 1,
                                "order": 1,
                                "start_time": 28800,
                                "end_time": 41220
                            },
                            {
                                "id": 2,
                                "order": 2,
                                "start_time": 46800,
                                "end_time": 64800
                            }
                        ]
                    },
                    {
                        "id": 1,
                        "day_of_week": 6,
                        "dayoff": false,
                        "ts_shift": [
                            {
                                "id": 1,
                                "order": 1,
                                "start_time": 28800,
                                "end_time": 41220
                            },
                            {
                                "id": 2,
                                "order": 2,
                                "start_time": 46800,
                                "end_time": 64800
                            },
                            {
                                "id": 3,
                                "order": 3,
                                "start_time": 28800,
                                "end_time": 36000
                            }
                        ]
                    }
                ]
            }
        };
        return res;
    }

    static async getRevenueOverviewOfUser(params = {}) {
       const res = await this.get(`/getRevenueOverviewOfUser`, params, 'aza-work');
       return res.data;
    }

    static async exportBanner(params = {}) {
        const res = await this.post(`/media/add-text-image`, params, 'AZAGROUP-BANNER');
        return res.data;
    }

    static async getHoliday(params = {}) {
        const res = await this.get(`/holidays`, params, 'timesheet-management');
        // const res = {
        //     "data": [
        //         {
        //             "id": 4,
        //             "name": "Quốc Khánh 2/9 vn",
        //             "type": "",
        //             "start_day": "2024-09-02",
        //             "end_day": "2024-09-02"
        //         },
        //         {
        //             "id": 1,
        //             "name": "Ngày thành lập AZA",
        //             "type": "1",
        //             "start_day": "2023-12-05",
        //             "end_day": "2023-12-05"
        //         }
        //     ],
        //     "message": "Thành công",
        //     "status": true
        // };
        return res.data;
    }

    static async createHoliday(params = {}) {
        const res = await this.post(`/add/holiday`, params, 'timesheet-management');
        return res.data;
    }

    static async updateHoliday(params, holiday_id) {
        const res = await this.post(`/update/${holiday_id}/holiday`, params, 'timesheet-management');
        return res.data;
    }

    static async deleteHoliday(holiday_id) {
        const res = await this.post(`/delete/${holiday_id}/holiday`, {},'timesheet-management');
        return res.data;
    }

    static async updateUserDailyTimesheet(params) {
        const res = await this.post(`/update-user-dailytimesheet`, params,'timesheet-management');
        return res.data;
    }

    static async getWorkDayByDateAndUser(params) {
        const res = await this.get(`/getWorkDayByDateAndUser`, params,'timesheet-management');
        // const res = {
        //     "data": {
        //         "work_day": {
        //             "id": 20,
        //             "timekeeper_user_id": 1000,
        //             "checkin_time": "08:33",
        //             "checkout_time": "17:32",
        //             "date": "2024-01-23",
        //             "created_at": "2023-12-15 14:21:13",
        //             "updated_at": "2023-12-15 14:21:13",
        //             "data": null,
        //             "history": null,
        //             "is_online": null
        //         }
        //     },
        //     "message": "Thành công",
        //     "status": true
        // }
        return res.data;
    }

    static async getMarketOnCurrency(params = {}) {
        const res = await this.get(`/markets`, params);
        // {
        //     "status": true,
        //     "message": "Thanh cong",
        //     "data": [
        //         {
        //             "id": 1,
        //             "name": "đồng",
        //             "abbr": "đ",
        //             "iso_code": "VND",
        //             "country": "Việt Nam",
        //             "rate": 1,
        //             "default": 1,
        //             "created_at": null,
        //             "updated_at": null,
        //             "active": 1,
        //             "type": 1,
        //             "expired_time": null,
        //             "history": null
        //         },
        //         {
        //             "id": 2,
        //             "name": "rupiah",
        //             "abbr": "Rp",
        //             "iso_code": "IDR",
        //             "country": "Indonesia",
        //             "rate": 1.4,
        //             "default": 0,
        //             "created_at": null,
        //             "updated_at": null,
        //             "active": 1,
        //             "type": 1,
        //             "expired_time": null,
        //             "history": null
        //         },
        //         {
        //             "id": 3,
        //             "name": "peso",
        //             "abbr": "₱",
        //             "iso_code": "PHP",
        //             "country": "Philippines",
        //             "rate": 430,
        //             "default": 0,
        //             "created_at": null,
        //             "updated_at": "2021-02-09 03:58:25",
        //             "active": 1,
        //             "type": 1,
        //             "expired_time": null,
        //             "history": null
        //         },
        //         {
        //             "id": 4,
        //             "name": "Ringgit",
        //             "abbr": "RM",
        //             "iso_code": "MYR",
        //             "country": "Malaysia",
        //             "rate": 5200,
        //             "default": 0,
        //             "created_at": null,
        //             "updated_at": null,
        //             "active": 1,
        //             "type": 1,
        //             "expired_time": null,
        //             "history": null
        //         },
        //         {
        //             "id": 5,
        //             "name": "Rupee",
        //             "abbr": "₹",
        //             "iso_code": "INR",
        //             "country": "India",
        //             "rate": 300,
        //             "default": 0,
        //             "created_at": null,
        //             "updated_at": null,
        //             "active": 1,
        //             "type": 1,
        //             "expired_time": null,
        //             "history": null
        //         },
        //         {
        //             "id": 6,
        //             "name": "Baht",
        //             "abbr": "฿",
        //             "iso_code": "THB",
        //             "country": "Thailand",
        //             "rate": 650,
        //             "default": 0,
        //             "created_at": null,
        //             "updated_at": null,
        //             "active": 1,
        //             "type": 1,
        //             "expired_time": null,
        //             "history": null
        //         },
        //         {
        //             "id": 7,
        //             "name": "Dollar",
        //             "abbr": "$",
        //             "iso_code": "USD",
        //             "country": "Cambodia",
        //             "rate": 21000,
        //             "default": 0,
        //             "created_at": null,
        //             "updated_at": null,
        //             "active": 1,
        //             "type": 1,
        //             "expired_time": null,
        //             "history": null
        //         },
        //         {
        //             "id": 9,
        //             "name": "Kyat",
        //             "abbr": "K",
        //             "iso_code": "MMK",
        //             "country": "Myanmar",
        //             "rate": 8,
        //             "default": 0,
        //             "created_at": null,
        //             "updated_at": null,
        //             "active": 1,
        //             "type": 2,
        //             "expired_time": null,
        //             "history": null
        //         }
        //     ]
        // };
        
        return res.data;
    }
    static async getListMarketCurrency(params = {}) {
        const res = await this.get(`/markets/list-change`, params);
        return res.data;
    }

    static async createChangeRate(params) {
        const res = await this.post(`/markets/create`, params);
        return res.data;
    }

    static async getDetailMarketCurrency(params = {}) {
        const res = await this.get(`/markets/detail`, params);
        return res.data;
    }
}

