import Vue from "vue";
import axios from "axios";
// Import Bootstrap and BootstrapVue CSS files (order is important)

// Plugins
import App from "./App.vue";
// import Chartist from "chartist";
import VueAxios from "vue-axios";
import DashboardPlugin from "./material-dashboard";
import Popover from 'vue-js-popover';
import "vue-select/dist/vue-select.css";
import VueJSModal from 'vue-js-modal';
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
import Cookies from 'js-cookie';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PrimeVue from 'primevue/config';
import VueElementLoading from "vue-element-loading";
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/tailwind-light/theme.css';
import 'primeicons/primeicons.css';
import 'vue2-datepicker/index.css';
import JsonExcel from 'vue-json-excel'
import 'vue-datetime/dist/vue-datetime.css';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement,
  ArcElement,
} from 'chart.js';
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement,
  ArcElement,
  ChartDataLabels
);

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Popover,{ tooltip: true });
Vue.use(VueAxios, axios);
Vue.use(VueJSModal, { dialog: true, dynamic: true, injectModalsContainer: true });
Vue.use(VueJSModal);
Vue.use(VueEasytable);
Vue.use(PrimeVue);
Vue.component("VueElementLoading", VueElementLoading);

// router & store setup
import router from "./router";
import store from "./store";
import {Datetime} from "vue-datetime";
const accessToken = localStorage.getItem("access_token");
if(accessToken !== null){
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

if((!Cookies.get('access_token') || !Cookies.get('user_id')) && window.location.pathname !== '/login'){
  store.dispatch("logout");
} 
/* eslint-disable no-new */
const app = new Vue({
  router: router,
  store: store,
  el: "#app",
  render: h => h(App)
});

store.$app = app;
window.app = app;
