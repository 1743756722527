<template>
  <div class="import-checkin-file-modal-container">
    <modal name="import-checkin-file-modal"
           :click-to-close="false"
           width='500px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <form class="" @submit.prevent="save()">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">Đồng bộ chấm công</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('import-checkin-file-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content class="import-coupon-modal">
          <div class="md-gutter pt-15px pb-15px">
            <label class="required heading-400 neutral_3 mb-5px">Chọn file đồng bộ</label>
            <input type="file" class="form-control input-custom input-file" @change="onFileChange" required
                   name="file">
          </div>
        </md-card-content>

        <md-dialog-actions>
          <button :class="{'disabled': (file == '')}" type="submit" class="btn-active md-button cursor-pointer">Đồng bộ</button>
          <div class="btn-stroke md-button" @click="$modal.hide('import-checkin-file-modal')">Huỷ</div>
        </md-dialog-actions>
      </form>
    </modal>
  </div>
</template>

<script>

import TimeSheetManagementService from "@/store/services/TimeSheetManagementService";

export default {
  name: 'import-checkin-file-modal',
  computed: {},
  created() {
  },
  data() {
    return {
      show: false,
      file: '',
      user: this.$store.state.auth.user,
    }
  },
  methods: {
    async importFileExcel() {
      const formFile = new FormData();

      formFile.append("file", this.file);

      await TimeSheetManagementService.importCheckinFileExcel(formFile).then(res => {
        if (res) {
          this.show = false;
          if (res.status) {
            this.$store.dispatch("alerts/success", 'Đồng bộ file thành công');
            this.$modal.hide('import-checkin-file-modal')
          } else {
            this.$store.dispatch("alerts/error", res.message);
          }
        }
      }).catch(err => {
        this.show = false;
        this.$store.dispatch("alerts/error", 'Đồng bộ file không thành công.');
      });
    },

    async onFileChange(e) {
      this.file = e.target.files[0];
    },

    save() {
      this.show = true;
      this.importFileExcel();
    },

    beforeOpened(event) {
    },

    beforeClosed(event) {

    },

  }
}
</script>

<style lang="scss">
.import-checkin-file-modal-container {
  .md-dialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.md-dialog {
  .md-dialog-container {
    width: 300px !important;
  }
}

.import-coupon-modal {
  min-height: 300px !important;
}

.import-checkin-file-modal * {
  font-size: 14px;
}

</style>
