<template>
  <div class="filter-common filter-selectbox-primevue" v-if="useForm.length > 0">
    <!-- thị trường -->
    <MultiSelect
      v-if="useForm.includes(1)"
      v-model="selectedMarket"
      :options="markets"
      optionLabel="country"
      dataKey="country"
      placeholder="Chọn thị trường"
      :filter="true"
      :showToggleAll="true"
      @change="changeMarket"
      class="multiselect-custom">
        <template #value="slotProps">
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              Chọn thị trường
            </template>
            <template v-else>
              <div class="list-item-label">
                Thị trường: 
                <div> <span class="department-selected">{{ slotProps.value[slotProps.value.length - 1].country }}</span>
                    <span class="count-item-selected" v-if="slotProps.value.length > 1">
                    +{{ slotProps.value.length - 1 }}
                  </span>
                </div>
              </div>
            </template>
        </template>
        <template #option="slotProps">
            <div class="agency-item">
                <!-- <div class="item-avatar">
                  <img src="/img/toolbar/logo_toolbar.svg" alt="Avatar">
                </div> -->
                <div class="text-400-medium">{{slotProps.option.country}}</div>
            </div>
        </template>
    </MultiSelect>

    <!-- Nền tảng quảng cáo -->
    <MultiSelect
      v-if="useForm.includes(2)"
      v-model="selectedMarketing"
      :options="marketingPerfomance"
      optionLabel="name"
      dataKey="name"
      placeholder="Nền tảng quảng cáo"
      :filter="true"
      :showToggleAll="true"
      @change="changeMarketingPerfomance"
      class="multiselect-custom">
        <template #value="slotProps">
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              Nền tảng quảng cáo
            </template>
            <template v-else>
              <div class="list-item-label">
                Nền tảng: <div> <span class="department-selected">{{ slotProps.value[slotProps.value.length - 1].name }}</span>
                  <span class="count-item-selected" v-if="slotProps.value.length > 1">
                  +{{ slotProps.value.length - 1 }}
                </span>
              </div>
              </div>
            </template>
        </template>
        <template #option="slotProps">
            <div class="agency-item">
                <!-- <div class="item-avatar">
                  <img src="/img/toolbar/logo_toolbar.svg" alt="Avatar">
                </div> -->
                <div class="text-400-medium">{{slotProps.option.name}}</div>
            </div>
        </template>
    </MultiSelect>

    <!-- Người thay đổi -->
    <MultiSelect
      v-if="useForm.includes(3)"
      v-model="selectedUserChange"
      :options="userChange"
      optionLabel="name"
      dataKey="id"
      placeholder="Người thay đổi"
      :filter="true"
      :showToggleAll="true"
      @change="changeUser"
      class="multiselect-custom">
          <template #value="slotProps">
              <template v-if="!slotProps.value || slotProps.value.length === 0">
                Người thay đổi
              </template>
              <template v-else>
                <div class="list-item-label">
                  user: <div> <span class="department-selected">{{ slotProps.value[slotProps.value.length - 1].name }}</span>
                    <span class="count-item-selected" v-if="slotProps.value.length > 1">
                    +{{ slotProps.value.length - 1 }}
                  </span>
                </div>
                </div>
              </template>
          </template>
          <template #option="slotProps">
              <div class="agency-item">
                  <div class="item-avatar">
                    <img src="/img/toolbar/logo_toolbar.svg" alt="Avatar">
                  </div>
                  <div class="text-400-medium">{{slotProps.option.name}}</div>
              </div>
          </template>
    </MultiSelect>
  </div>
</template>

<script>
import MultiSelect from 'primevue/multiselect';
// import Dropdown from 'primevue/dropdown';
import FilterService from "@/store/services/FilterService";
import { watch } from 'vue';
export default {
  components: {
    MultiSelect,
    // Dropdown
  },
  props:{
    useForm: {
        type: Array,
        default: () => []
      }
  },
  data() {
    return {
      selectedMarket: null,
      markets: null,
      selectedMarketing: null,
      marketingPerfomance: null,
      selectedUserChange: null,
      userChange: null,
      emitData: null
    };
  },
  created(){
    this.getShopCountry();
    this.getMarketingPerfomance();
  },
  watch:{
    'emitData'(newV){
      // this.$emit('filter', newV);
    }
  },

  methods: {
    changeMarket(e){
      console.log('Change Markets', e);
      let markets = e.value.map((item) => {return item.country})
      this.emitData = {...this.emitData, ...{markets: markets}}
      this.$emit('filter', this.emitData);
    },
    changeMarketingPerfomance(e){
      console.log('changeMarketingPerfomance', e);
      let ids = [];
      e.value.forEach(element => {
        ids.push(element.id);
      });
      this.emitData = {...this.emitData, ...{marketing_platform_ids: ids}}
      this.$emit('filter', this.emitData);
    },
    changeUser(){
      console.log('changeUser');
    },
    getShopCountry(){
      FilterService.getCountryShop().then(res => {
        if(res.status && res.data){
          this.markets = res.data;
        }
      });
    },

    getMarketingPerfomance(){
      FilterService.getMarketingPerfomance().then(res => {
        if(res.status && res.data){
          this.marketingPerfomance = res.data;
        }
      });
    }
  }
};
</script>
