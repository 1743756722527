<template>
   <div class="filter-selectbox-primevue">
            <MultiSelect
            v-model="selectedBranch"
            :options="companyBranchs"
            optionLabel="name"
            dataKey="id"
            placeholder="Chọn chi nhánh"
            :filter="true"
            :showToggleAll="true"
            @change="changeBranch"
            class="multiselect-custom">
                <template #value="slotProps">
                    <template v-if="!slotProps.value || slotProps.value.length === 0">
                        Chọn chi nhánh
                    </template>
                    <template v-else>
                      <div class="list-item-label">
                        Chi nhánh: <div> <span class="department-selected">{{ slotProps.value[slotProps.value.length - 1].name }}</span>
                          <span class="count-item-selected" v-if="slotProps.value.length > 1">
                          +{{ slotProps.value.length - 1 }}
                        </span>
                      </div>
                      </div>
                    </template>
                </template>
                <template #option="slotProps">
                    <div class="agency-item">
                        <div class="item-avatar">
                          <img src="/img/toolbar/logo_toolbar.svg" alt="Avatar">
                        </div>
                        <div class="text-400-medium">{{slotProps.option.name}}</div>
                    </div>
                </template>
            </MultiSelect>
            <!-- Phòng -->
            <MultiSelect
            v-model="departmentSelect.selected"
            :options="departmentSelect.list"
            optionLabel="name"
            dataKey="id"
            placeholder="Phòng"
            :filter="true"
            :showToggleAll="true"
            @change="changeDepartment"
            class="multiselect-custom">
                <template #value="slotProps">
                    <template v-if="!slotProps.value || slotProps.value.length === 0">
                        Chọn Phòng
                    </template>
                    <template v-else>
                      <div class="list-item-label">
                        Phòng: <div> <span class="department-selected">{{ slotProps.value[slotProps.value.length - 1].name }}</span>
                            <span class="count-item-selected" v-if="slotProps.value.length > 1">
                            +{{ slotProps.value.length - 1 }}
                        </span>
                      </div>
                      </div>
                    </template>
                </template>
                <template #option="slotProps">
                    <div class="agency-item">
                        <div class="text-400-medium">{{slotProps.option.name}}</div>
                    </div>
                </template>
            </MultiSelect>
            <!-- Team -->
            <MultiSelect
            v-model="teamSelect.selected"
            :options="teamSelect.list"
            optionLabel="name"
            dataKey="id"
            placeholder="Team"
            :filter="true"
            :showToggleAll="true"
            @change="changeTeam"
            class="multiselect-custom">
                <template #value="slotProps">
                    <template v-if="!slotProps.value || slotProps.value.length === 0">
                        Chọn Team
                    </template>
                    <template v-else>
                      <div class="list-item-label">
                          Team: <div> <span class="department-selected">{{ slotProps.value[slotProps.value.length - 1].name }}</span>
                            <span class="count-item-selected" v-if="slotProps.value.length > 1">
                            +{{ slotProps.value.length - 1 }}
                        </span>
                      </div>
                      </div>
                    </template>
                </template>
                <template #option="slotProps">
                    <div class="agency-item">
                        <div class="text-400-medium">{{slotProps.option.name}}</div>
                    </div>
                </template>
            </MultiSelect>
            <template v-if="fieldNotUse('status')">
              <Dropdown
                v-model="userStatus.selected"
                :options="userStatus.list"
                @change="changeStatus"
                optionLabel="name"
                placeholder="Trạng thái"
                >
              </Dropdown>
            </template>
            <!-- <Dropdown
              v-model="teamSelect.selected"
              :options="teamSelect.list"
              optionLabel="name"
              :filter="true"
              placeholder="Team"
              :showClear="true"
              :emptyFilterMessage="'không có dữ liệu!'"
              >
            </Dropdown> -->
            <!-- Nhân viên -->
            <!-- <Dropdown v-model="memberSelect.selected"
              :options="memberSelect.listist"
              optionLabel="name"
              :filter="true"
              placeholder="Nhân viên"
              :showClear="true"
              :emptyFilterMessage="'không có dữ liệu!'">
            </Dropdown> -->
    </div>
  </template>

<script>
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import AdminService from '../../../../store/services/AdminService';
import {PERMISSION_CODE} from "../../../../const/const";

  export default {
    components: {
      MultiSelect,
      Dropdown
    },
    props: {
      permission: {
        type: String,
        default: () => {
          return PERMISSION_CODE.ADMIN_REWARD_MANAGE;
        }
      },
      loadDefault: {
        type: Boolean,
        default: () => {
          return true;
        }
      },
      notUse: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
            selectedBranch: null,
            companyBranchs: [
                // {name: 'Australia', code: 'AU'},
                // {name: 'Brazil', code: 'BR'},
                // {name: 'China', code: 'CN'},
                // {name: 'Egypt', code: 'EG'},
                // {name: 'France', code: 'FR'},
                // {name: 'Germany', code: 'DE'},
                // {name: 'India', code: 'IN'},
                // {name: 'Japan', code: 'JP'},
                // {name: 'Spain', code: 'ES'},
                // {name: 'United States', code: 'US'}
            ],
            departmentSelect: {
                selected: null,
                list: []
            },
            teamSelect: {
                selected: null,
                list: []
            },
            memberSelect: {
                selected: null,
                list: []
            },
            userStatus: {
                selected: null,
                list: [
                  {name: 'Tất cả', code: 'all'},
                  {name: 'Đang hoạt động', code: 'active'},
                  {name: 'Đã nghỉ', code: 'inactive'},
                ]
            }
        }
    },
    created () {
      this.getDepartmentUserForFilter(this.loadDefault);
    },
    methods: {
      async getDepartmentUserForFilter(isCreatedOnLoad = false){
        const params = {
          permission_name: this.permission
        }
        let team_ids = [];
        await AdminService.getDepartmentAssignedUser(params).then(res => {
          this.companyBranchs = res.data;
          this.teamSelect.list = [].concat(...res.data.map(branch =>
              {
                team_ids.push(branch.id);
                return [].concat(...branch.department.map(department =>
                  department.team
                ))
              }
          ));
        });
        if(isCreatedOnLoad){
          this.$emit('teamIds', team_ids);
          this.$emit('onLoadPage', true);
        }
      },

      changeBranch(event){
        this.departmentSelect.list = event.value.reduce((accumulator, branch) => {
            return accumulator.concat(branch.department);
        }, []);

        // reset
        if(event.value.length === 0){
          this.departmentSelect.selected = null;
        }
        this.getTeamIds();
      },

      changeDepartment(event){
        this.getTeamIds();
        if(event.value.length > 0){
          this.teamSelect.list = event.value.reduce((accumulator, department) => {
            return accumulator.concat(department.team);
        }, []);
          return;
        };
        this.teamSelect.selected = null;
        this.teamSelect.list = [].concat(...this.selectedBranch.map(branch =>
            [].concat(...branch.department.map(department =>
                department.team
            ))
        ));
      },

      changeTeam(event){
        this.getTeamIds();
      },

      getTeamIds(){
        let ids = [];
        let data = []
        const isValidData = (data) => {
          return data !== null && Array.isArray(data) && data.length > 0;
        }

        // nếu phòng ban không được chọn thì măc định lấy ids chi nhánh
        if(!isValidData(this.teamSelect.selected) && !isValidData(this.selectedBranch) && this.loadDefault){
          data = this.companyBranchs;
        } else if(isValidData(this.teamSelect.selected)){
          data = this.teamSelect.selected
        } else if(isValidData(this.departmentSelect.selected)){
          data = this.departmentSelect.selected
        } else if(isValidData(this.selectedBranch) || (!isValidData(this.teamSelect.selected) && !isValidData(this.selectedBranch))){
          data = this.selectedBranch
        }

        data.map((item) => {
          ids.push(item.id)
        });
        this.$emit('teamIds', ids);
      },

      changeStatus(event){
        this.$emit('status', event.value.code);
      },

      fieldNotUse(value){
        if(this.notUse.find((item) => item === value)){
          return false;
        }
        return true;
      }
    }
  };
  </script>
  <style lang="scss">

  </style>
