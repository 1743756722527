<template>
  <div class="employees-container">
    <div v-if="$route.params.tab === ShopManagementTeleport.SHOP_LIST">
      <shop-list />
    </div>
    <div v-if="$route.params.tab === ShopManagementTeleport.SHOP_HISTORIES">
      <shop-histories />
    </div>
    <Teleport to="#menu-card">
      <div class="menu-item" :class="{'menu-item-active': $route.params.tab === ShopManagementTeleport.SHOP_LIST}"
           @click="$router.push(`/quan-ly-shop/${ShopManagementTeleport.SHOP_LIST}`)">
        Quản lý shop
      </div>
      <div class="menu-item" :class="{'menu-item-active': $route.params.tab === ShopManagementTeleport.SHOP_HISTORIES}"
           @click="$router.push(`/quan-ly-shop/${ShopManagementTeleport.SHOP_HISTORIES}`)">
        Lịch sử thay đổi
      </div>
    </Teleport>
  </div>
</template>

<script>

import PaginationMixin from "@/mixins/pagination-mixin";
import ShopHistories from "@/pages/Workplace/Pages/ShopManagement/ShopHistories";
import {ShopManagementTeleport} from "@/const/const";
import ShopList from "@/pages/Workplace/Pages/ShopManagement/ShopList";

export default {
  components: {
    ShopHistories,
    ShopList
  },

  mixins: [PaginationMixin],
  data() {
    return {
      ShopManagementTeleport,
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.md-menu-content {
  z-index: 9999;
}

.employees-container {
  background: #fff;
  padding: 10px;

  .search-container {
    .multiselect {
      width: 250px;
    }
    .keyword {
      height: 40px;
      border: 1px solid #EDEFF5;
    }
    .keyword-type {
      width: 165px !important;
      border-radius: initial !important;
      .vs__dropdown-toggle {
        cursor: pointer;
      }
      .vs__actions {
        .vs__clear {
          display: none;
        }
      }
    }
  }

  .header-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F3F4;
    font-size: 14px;

    .actions {
      display: flex;
      grid-gap: 20px;
      padding: 10px;

      .tab-name {
        font-weight: 500;
        color: #898989;
        position: relative;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }

        .tab-active {
          color: #1200ff;
          margin-left: 15px;

          &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            top: 50%;
            left: 0;
            background: #1200ff;
            transform: translateY(-50%);
          }
        }
      }
    }

    .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem;
      align-items: center;
      grid-gap: 10px;

      .total-records-pagination {
        font-size: 13px;

        .total {
          color: #000;
          font-weight: 500;
        }
      }

      .md-menu {
        align-items: center;
        display: flex;
      }

      .md-button {
        background-color: unset !important;
        color: #000 !important;
        height: max-content;
        box-shadow: unset;

        .md-ripple {
          padding: 0 !important;

          .md-button-content {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .ve-table-layout {
    .ve-table-container {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #8888887a;
        border-radius: 5px;

        &:hover {
          background: #555;
        }
      }
    }

    .ve-table-body {
      .address {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .money {
        font-weight: 500;
      }

      .employee-avatar {
        .md-avatar {
          border-radius: 100%;
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          min-width: 30px;
          background: #f3f3f3;
          margin-right: 5px;
          z-index: 1;
        }
      }

      .number-employee {
        color: #1fb800;
        font-weight: 500;
      }

      .status-employee {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        margin: auto;
      }

      .non-active {
        border: 1px solid #1fb800;
        background: #1fb8001a;
      }

      .active {
        background: #1fb800;
      }
    }
  }
}
</style>
