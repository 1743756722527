<template>
  <div class="work-schedule-container">
    <vue-element-loading :active="isLoading" is-full-screen spinner="bar-fade-scale"/>

    <div class="header-label">
      <div class="heading-600">
        Lịch làm việc
      </div>
    </div>
    <div class="filter-container mt-30px mb-20px">
      <div class="filter-sale-date-picker d-flex-center-between">
        <div>
          <v-select :options="roleList" label="display_name" placeholder="Chọn loại nhóm"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    :searchable="false"
                    v-model="workScheduleFilterValue.role_id">
            <template #search="{attributes, events}">
              <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
              />
            </template>
            <template slot="option" slot-scope="origin">
              <div class="flex">
                <div class="col text-overflow-ellipsis">
                  <span class="text-400-medium neutral-1">{{ origin.display_name }}</span>
                </div>
              </div>
            </template>
          </v-select>
        </div>
        <div class="filter-datepicker d-flex-center-between">
          <div class="datepicker">
            <date-picker
                class="datetime-picked"
                placeholder="Select date range"
                v-model="timeRange"
                range-separator=" - "
                format="DD/MM/YYYY"
                type="month"
                :clearable="false"
                :confirm="false"
                :confirm-text="'Xác nhận thời gian tìm kiếm.'"
                :lang="lang"
                @change="changeDate"
                :range="false"
            >
              <template v-slot:input>
                <input v-model="dateSelection" type="text" name="date"
                       readonly
                       autocomplete="off"
                       placeholder="Chọn thời gian"
                       class="mx-input"/>
              </template>
              <template v-slot:icon-calendar>
                <md-icon>arrow_drop_down</md-icon>
              </template>
            </date-picker>
          </div>
          <div class="filter-button">
            <button class="btn filter-report" @click="getWorkSchedule()">
              Tạo báo cáo
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex-center mb-20px" style="border-bottom: 1px solid #d9d9d9;">
      <div class="label-box cursor-pointer" :class="{'menu-item-active': tabActive === 1}" @click="tabActive=1">Ca làm việc hàng tuần</div>
      <div class="label-box cursor-pointer ml-10px" :class="{'menu-item-active': tabActive === 2}" @click="tabActive=2">Lịch chấm công online</div>
    </div>

    <div v-show="tabActive === 1">
      <ve-table border-y
                :columns="tableHeader"
                :tableData="tableData"
                :row-style-option="rowStyleOption"
      />
    </div>
    <div v-show="tabActive === 2">
      <update-work-schedule
          @updated="getWorkSchedule"
          :date-month="workScheduleFilterValue.month"
          :checkin-online-data="JSON.parse(JSON.stringify(ts_checkin_online))"/>
    </div>
    <!--    <Teleport to="#menu-card">-->
    <!--      <div class="menu-item menu-item-active">Bảng công chi tiết</div>-->
    <!--    </Teleport>-->
    <update-work-day-modal @updated="getWorkSchedule"/>
  </div>

</template>

<script>

import moment from "moment";
import vue2DatePicker from "@/mixins/config-vue2-datepicker";
import DatePicker from 'vue2-datepicker';
import TimeSheetService from "@/store/services/TimeSheetService";
import HRMService from "@/store/services/HRMService";
import UpdateWorkDayModal from "@/pages/Components/Modal/UpdateWorkDayModal";
import UpdateWorkSchedule from "@/pages/Workplace/Pages/WorkSchedule/UpdateWorkSchedule";

export default {
  components: {
    UpdateWorkDayModal,
    DatePicker,
    UpdateWorkSchedule
  },

  mixins: [vue2DatePicker],

  data() {
    const date = new Date();
    return {
      tabActive: 1,
      closeOnClick: true,
      isLoading: false,
      rowStyleOption: {
        hoverHighlight: false,
      },
      tableHeader: [
        {
          field: "1", key: "t2", title: "THỨ HAI", align: "center", width: 'calc(100% / 7)',
          renderBodyCell: ({row, column, rowIndex}, h) => this.renderTableColumn(row, 1)
        },
        {
          field: "2", key: "t3", title: "THỨ BA", align: "center", width: 'calc(100% / 7)',
          renderBodyCell: ({row, column, rowIndex}, h) => this.renderTableColumn(row, 2)
        },
        {
          field: "3", key: "t4", title: "THỨ TƯ", align: "center", width: 'calc(100% / 7)',
          renderBodyCell: ({row, column, rowIndex}, h) => this.renderTableColumn(row, 3)
        },
        {
          field: "4", key: "t5", title: "THỨ NĂM", align: "center", width: 'calc(100% / 7)',
          renderBodyCell: ({row, column, rowIndex}, h) => this.renderTableColumn(row, 4)
        },
        {
          field: "5", key: "t6", title: "THỨ SÁU", align: "center", width: 'calc(100% / 7)',
          renderBodyCell: ({row, column, rowIndex}, h) => this.renderTableColumn(row, 5)
        },
        {
          field: "6", key: "t7", title: "THỨ BẢY", align: "center", width: 'calc(100% / 7)',
          renderBodyCell: ({row, column, rowIndex}, h) => this.renderTableColumn(row, 6)
        },
        {
          field: "7", key: "cn", title: "CHỦ NHẬT", align: "center", width: 'calc(100% / 7)',
          renderBodyCell: ({row, column, rowIndex}, h) => this.renderTableColumn(row, 0)
        }
      ],
      tableData: [],
      valueSelect: null,
      options: ['list', 'of', 'options'],
      dateSelection: '',
      workScheduleFilterValue: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        month: moment().format('YYYY/MM'),
        role_id: 2
      },
      timeRange: [
        new Date(date.getFullYear(), date.getMonth(), 1),// ngày tháng đầu tiên của tháng
        new Date() // giờ hiện tại
      ],
      roleList: [],
      ts_checkin_online: null,
      ts_schedule_id: null,
    };
  },
  created() {
    this.dateSelection = `Tháng ${moment().month() + 1}`;
    this.getRole();
    this.getWorkSchedule();
  },
  mounted() {
    // this.fixHeightVeTable();
  },
  methods: {
    async getRole() {
      await HRMService.getRoles().then(res => {
        this.roleList = res.data;
      });
    },
    async getWorkSchedule() {
      this.isLoading = true;
      let resData = [];
      this.tableData = [];
      let params = {...this.workScheduleFilterValue};
      delete params.start_date;
      delete params.end_date;
      await TimeSheetService.getTimeSheetByRole(params).then(res => {
        resData = res.data;
        this.ts_schedule_id = resData.id;
        let ts_checkin_online = resData.ts_checkin_online;
        if (Array.isArray(ts_checkin_online) && ts_checkin_online.length > 0) {
          this.ts_checkin_online = ts_checkin_online[0];
        } else if (typeof ts_checkin_online === 'object' && ts_checkin_online !== null) {
          this.ts_checkin_online = ts_checkin_online;
        }
        if (this.ts_checkin_online) {
          this.ts_checkin_online.morning_checkin = `${this.ts_checkin_online.morning_checkin_from} - ${this.ts_checkin_online.morning_checkin_to}`;
          this.ts_checkin_online.afternoon_checkin = `${this.ts_checkin_online.afternoon_checkin_from} - ${this.ts_checkin_online.afternoon_checkin_to}`;
        }
      });
      this.tableData = this.convertResDataToTableData(resData);
      this.isLoading = false;
    },
    fixHeightVeTable() {
      const clientHeightTopbar = document.getElementById('menu-toolbar').clientHeight;
      const paddingMainConent = 60; //margin top 30 - bottom - 30
      const paddingContent = 40;// top-bottom
      const heightWidthScrollBottom = 6;
      const tabHeaderHieght = document.getElementsByClassName('tab-header')[0].clientHeight;
      const veTableHeader = document.getElementsByClassName('ve-table-header')[0].clientHeight;

      const heightByScreen = clientHeightTopbar + paddingMainConent + tabHeaderHieght + veTableHeader - paddingContent - heightWidthScrollBottom;

      document.getElementsByClassName('ve-table-container')[0].style.height = `calc(100vh - ${heightByScreen}px)`;
      document.getElementsByClassName('ve-table-container')[0].style.maxHeight = '100%';
    },

    renderTableColumn(row, day) {
      let dayTitle = "";
      switch (day) {
        case 0:
          dayTitle = 'CN-S';
          break;
        case 1:
          dayTitle = 'T2-S';
          break;
        case 2:
          dayTitle = 'T3-S';
          break;
        case 3:
          dayTitle = 'T4-S';
          break;
        case 4:
          dayTitle = 'T5-S';
          break;
        case 5:
          dayTitle = 'T6-S';
          break;
        case 6:
          dayTitle = 'T7-S';
          break;

      }
      let backgroundOutMonthClass = row[day].outMonth ? 'background-out-month' : '';
      let totalShift = row[day].ts_shift ? ` (${row[day].ts_shift.length} ca) ` : '';
      let backgroundActive = 'time-shift-wrapper active-shift';
      let isCheckinOnline = false;
      if (Object.keys(this.ts_checkin_online).length !== 0 && this.ts_checkin_online.active_days?.includes(row[day].initial_date)) {
        backgroundActive = 'time-shift-wrapper checkin-online';
        isCheckinOnline = true;
      }
      return (
          <div class={`w_100` + backgroundOutMonthClass}>
            <div>
              <div class="d-flex-center-between mb-5px">
                <div>
                  <span class="text-400-medium">{row[day].date}</span>
                  <span class="text-400-default">{totalShift}</span>
                </div>
                <div class="text-500-medium cursor-pointer" on-click={() => this.showUpdateWorkDayModal(row[day])}>
                  {row[day].outMonth ? '' : <span class="edit-icon material-symbols-outlined">border_color</span>}
                </div>
              </div>
              {[1, 2].map(index => (
                  <div
                      class={row[day].ts_shift_object[index] ? backgroundActive : 'time-shift-wrapper'}>
                    {row[day].ts_shift_object[index] && (
                        <div>
                        </div>
                    )}
                    {row[day].ts_shift_object[index] && (
                        <div class="ml-10px time-shift-content">
                          <div class="d-flex-center-between w_100">
                            <span class="text-300-medium">{dayTitle + index}</span>
                            <span class={isCheckinOnline ? 'text-300-medium mr-10px' : 'd-none'} style="color: #0d1a26">{index === 1 ? this.ts_checkin_online.morning_checkin : this.ts_checkin_online.afternoon_checkin}</span>
                          </div>
                          <div class="text-300-medium">
                            <span>{this.convertSecondsToTime(row[day].ts_shift_object[index].start_time)}</span>
                            <md-icon>arrow_right_alt</md-icon>
                            <span>{this.convertSecondsToTime(row[day].ts_shift_object[index].end_time)}</span>
                          </div>
                        </div>
                    )}
                  </div>
              ))}
            </div>
          </div>
      );
    },

    convertResDataToTableData(resData) {
      let tableData = [];
      const dayList = resData.ts_day_of_month;

      let currentDate = new Date(this.workScheduleFilterValue.start_date);
      const currentMonth = currentDate.getMonth();
      // nếu currentDate không phải thứ 2 thì lùi lại để lấy thứ 2
      if (currentDate.getDay() === 0) {
        currentDate.setDate(currentDate.getDate() - 6);
      } else if (currentDate.getDay() > 1) {
        currentDate.setDate(currentDate.getDate() - (currentDate.getDay() - 1));
      }
      // nếu endDate không phải chủ nhật thì tăng lên để lấy chủ nhật
      let endDate = new Date(this.workScheduleFilterValue.end_date);
      if (endDate.getDay() !== 0) {
        endDate.setDate(endDate.getDate() + (7 - endDate.getDay()));
      }

      let index = 0;
      while (currentDate <= new Date(endDate)) {
        let tableRow = {};
        for (let i = 1; i <= 7; i++) {
          let day = currentDate.getDate();
          let month = currentDate.getMonth() + 1;
          let dayOfWeek = currentDate.getDay();
          let formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}`;
          if (currentDate.getMonth() !== currentMonth) {
            tableRow[dayOfWeek] = {outMonth: true, date: formattedDate, ts_shift_object: {}};
          } else {
            tableRow[dayOfWeek] = this.prepareColumnContent(dayList[index], formattedDate);
            tableRow[dayOfWeek].initial_date = moment(currentDate).format('YYYY-MM-DD');
            index++;
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        tableData.push(tableRow);
      }

      return tableData;
    },

    prepareColumnContent(dayInfo, date) {
      let content = {};
      content.id = dayInfo.id;
      content.outMonth = false;
      content.day = dayInfo.day;
      content.date = date;
      content.ts_shift = dayInfo.ts_shift;
      content.ts_shift_object = {};
      if (content.ts_shift && content.ts_shift.length > 0) {
        content.ts_shift.forEach(item => {
          content.ts_shift_object[item.order] = item;
        })
      } else {
        content.ts_shift = [];
      }

      return content;
    },

    changeDate(value) {
      let start_date = moment(value).startOf('month');
      let end_date = moment(value).endOf('month');
      let month = start_date.month();

      this.dateSelection = `Tháng ${month + 1}`;
      this.workScheduleFilterValue.start_date = start_date.format('YYYY-MM-DD');
      this.workScheduleFilterValue.end_date = end_date.format('YYYY-MM-DD');
      this.workScheduleFilterValue.month = start_date.format('YYYY/MM');
      // =====================getTopRevenue================ //
    },
    convertSecondsToTime(seconds) {
      if (seconds == null) {
        return '';
      }
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);

      // Định dạng thời gian thành chuỗi HH:MM
      let formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

      return formattedTime;
    },

    redirectUpdateWorkSchedulePage() {
      this.$router.push(`/lich-lam-viec/cap-nhat?role_id=${this.workScheduleFilterValue.role_id}&month=${this.workScheduleFilterValue.month}`)
    },

    showUpdateWorkDayModal(row) {
      this.$modal.show("update-work-day-modal", {dayData: row, ts_schedule_id: this.ts_schedule_id});
    }
  }
};
</script>
<style src="../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.md-menu-content {
  z-index: 9999;
}

.work-schedule-container {
  background: #fff;
  padding: 10px;

  .label-box {
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
  }

  .menu-item-active {
    border-bottom: 3px solid #FF7B00;
    color: #FF7B00;
  }

  .fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-bottom: 8px;
    .field{
      line-height: 28px;
    }
  }
  .label-field {
    margin-bottom: 4px;
  }

  .filter-button {
    .filter-report {
      &:hover {
        cursor: pointer;
      }

      border-radius: 4px;
      background: #4D77EC;
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #fff;
      border: none;
      height: 35px;
    }
  }

  .time-shift-wrapper.active-shift {
    border-left: 3px solid #45dc6b;
    background-color: #EBFAE6;
    color: #005f18;

    .md-icon {
      color: #34C759 !important;
    }
  }

  .time-shift-wrapper.checkin-online {
    border-left: 3px solid #ff6600;
    background-color: #ffc299;
    color: #ff6600;

    .md-icon {
      color: #ff6600 !important;
    }
  }

  .edit-icon {
    font-size: 15px !important;
  }

  .time-shift-wrapper {
    margin-bottom: 5px;
    height: 45px;

    .md-icon {
      margin: 0 !important;
    }

    .time-shift-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .background-out-month {
    background-color: #F5F5F5 !important;
  }

  .checkin-time, .checkout-time {
    display: flex;

    .md-icon {
      margin: 0 !important;
    }
  }

  .checkout-time {
    color: #FCAF17 !important;

    .md-icon {
      color: #FCAF17 !important;
    }
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F3F4;
    font-size: 14px;

    .actions {
      display: flex;
      grid-gap: 20px;
      padding: 10px;

      .tab-name {
        font-weight: 500;
        color: #898989;
        position: relative;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }

        .tab-active {
          color: #1200ff;
          margin-left: 15px;

          &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            top: 50%;
            left: 0;
            background: #1200ff;
            transform: translateY(-50%);
          }
        }
      }
    }

    .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem;
      align-items: center;
      grid-gap: 10px;

      .total-records-pagination {
        font-size: 13px;

        .total {
          color: #000;
          font-weight: 500;
        }
      }

      .md-menu {
        align-items: center;
        display: flex;
      }

      .md-button {
        background-color: unset !important;
        color: #000 !important;
        height: max-content;
        box-shadow: unset;

        .md-ripple {
          padding: 0 !important;

          .md-button-content {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .ve-table-layout {
    .ve-table-container {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #8888887a;
        border-radius: 5px;

        &:hover {
          background: #555;
        }
      }
    }

    .ve-table-body {
      .address {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .employee-info {
        display: flex;
        align-items: center;

        .md-avatar {
          margin: 0 !important;
          border-radius: 100%;
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          min-width: 30px;
          background: #f3f3f3;
          z-index: 1;
        }
      }

      .number-employee {
        color: #1fb800;
        font-weight: 500;
      }

      .status-employee {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        margin: auto;
      }

      .non-active {
        border: 1px solid #1fb800;
        background: #1fb8001a;
      }

      .active {
        background: #1fb800;
      }
    }
  }

  .filter-container {
    border-radius: 4px;
    border: 0.5px solid #D3D3D3;
    background: #F9F9F9;
    padding: 17px 32px 19px 22px;

    .v-select {
      width: 250px;
    }

    .filter-mode-sale {
      &:hover {
        cursor: pointer;
      }

      border: 1px solid #D3D3D3;
      border-radius: 4px;
      width: max-content;
      margin-bottom: 20px;

      .active-mode {
        border-radius: 4px;
        border-right: 0.5px solid rgba(0, 0, 0, 0.15);
        border-left: 0.5px solid rgba(0, 0, 0, 0.15);
        background: #FFF9D9;
      }
    }

    .estimated-sales {
      background: #f9f9f9;
      padding: 6px 9px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .actual-sales {
      background: #f9f9f9;
      padding: 6px 9px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .filter-label {
      color: #101840;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    .filter-sale-date-picker {
      align-items: start;

      .filter-datepicker {
        input {
          display: flex;
          width: 200px;
          padding: 6px 8px;
          align-items: center;
          gap: 8px;
          border-radius: 4px;
          border: 1px solid #D3D3D3;
          background: #FFF;
        }
      }
    }
  }
}
</style>
