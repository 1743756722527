import BaseService from "./BaseService";
import axios from "axios";

export default class AuthService extends BaseService {

  static async login(params = {}) {
    const res = await this.post(`/login`, params);
    return res.data;
  }

  static async getXsrfToken() {
    const domainCsrfToken = process.env.VUE_APP_API_CSRF_URL;
    const res = await axios.get(domainCsrfToken, {withCredentials: true});
    return res;
  }

  static async logout() {
    const res = await this.post(`/logout`);
    return res.data;
  }

}

