import BaseService from "./BaseService";

export default class TimeSheetManagementService extends BaseService {
    static async importCheckinFileExcel(params = {}) {
        const res = await this.post(`/importUserDailyTimesheet`, params, 'timesheet-management');
        return res.data;
    }

    static async getTimesheet(params = {}) {
        const res = await this.get(`/timesheet`, params, 'timesheet-management');
        return res.data;
    }
    static async employeeTimesheet(params = {}, user_id) {
        const res = await this.get(`/${user_id}`, params, 'TIMESHEET');
        return res.data;
    }

    static async getTimesheetByUser(params = {}, user_id) {
        const res = await this.get(`/timesheetByUser/${user_id}`, params, 'TIMESHEET');
        return res.data;
      }
    
      
    static async getLeaves(params = {}) {
        const res = await this.get(`/leaves`, params, 'TIMESHEET');
        return res.data;
    }

    static async updateTSDayMonth(params = {}, dayId = '') {
        const res = await this.post(`/ts-day-of-month/${dayId}/update`, params, 'timesheet-management');
        return res.data;
    }

    static async finalizeWorkTime(params = {}) {
        const res = await this.post(`/finalizeWorkTime`, params, 'timesheet-management');
        return res.data;
    }

    static async importCheckinOnlineFileExcel(params = {}) {
        const res = await this.post(`/importCheckinOnline`, params, 'timesheet-management');
        return res.data;
    }

    static async exportTimeSheet(params = {}) {
        const res = await this.get(`/exportExcelFinalWork`, params, 'timesheet-management');
        return res.data;
    }
    
    static async createLeaveRequest(params = {}) {
        const res = await this.post(`/storeLeaveRequest`, params, 'TIMESHEET');
        return res.data;
    }
}

