<template>
  <div
      class="sidebar sidebar-custom"
      :data-color="activeColor"
  >
    <!--left-content-->
    <div class="full-sidebar">
      <div class="page-title-sidebar">
        <md-list-item>
          <md-avatar>
            <img src="/img/toolbar/logo_toolbar.svg" alt="Avatar">
          </md-avatar>

        <div class="md-list-item-text">
          <div class="aza-title">Aza Group</div>
          <div class="page-admin">
            <span>Trang quản trị</span>
          </div>
        </div>
      </md-list-item>
      </div>
<!--      <div class="sidebar-wrapper sidebar-workplace">-->
<!--      <md-list-item class="cursor-pointer workplace-icon"-->
<!--                    v-bind:class="tab === 'admin' ? 'icon-active' : ''"-->
<!--                    v-on:click="changeMenu('admin')">-->
<!--        <i class="material-icons social-network background-no-repeat"></i>-->
<!--      </md-list-item>-->
<!--      <md-list-item class="cursor-pointer workplace-icon"-->
<!--                    v-bind:class="tab === 'settings' ? 'icon-active' : ''"-->
<!--                    v-on:click="changeMenu('settings')">-->
<!--        <i class="material-icons settings background-no-repeat"></i>-->
<!--      </md-list-item>-->

<!--      &lt;!&ndash; <md-image v-popover:foo.right class="avatar"></md-image>-->
<!--      <popover name="foo">-->
<!--        <md-list class="p-0px popover-avatar">-->
<!--          <md-list-item v-on:click="logout()">-->
<!--            <md-icon>logout</md-icon>-->
<!--            <div class="md-list-item-text">Logout</div>-->
<!--          </md-list-item>-->
<!--        </md-list>-->
<!--      </popover> &ndash;&gt;-->
<!--    </div>-->

    <div class="sidebar-wrapper sidebar-menu" ref="sidebarScrollArea">
     <div class="list">
      <!-- menu for default -->
      <md-list class="nav nav-menu-item" v-if="tab === 'admin'">
        <md-list-item md-expand :md-expanded.sync="expand1" v-if="showAzaWorkTab">
          <div class="md-list-item-text">
            <span>AZA WORK</span>
          </div>

          <md-list slot="md-expand">
            <!-- <md-list-item class="md-inset sidebar-nav-item"
              :class="{'menu-active-item' : linkId === 'yeu-cau-phe-duyet'}"
              @click="activeMenu"
            >
              <router-link to="/yeu-cau-phe-duyet">
                Yêu cầu & Phê duyệt
              </router-link>
            </md-list-item> -->
            <md-list-item class="md-inset sidebar-nav-item"
                          :class="{'menu-active-item' : linkId === 2}"
                          @click="activeMenu" v-if="permissionList.includes(permissionCode.ADMIN_REWARD_MANAGE)">
              <router-link to="/quan-ly-thuong-doanh-so/tong-quan">
                Quản lý thưởng doanh số
              </router-link>
            </md-list-item>
            <md-list-item class="md-inset sidebar-nav-item"
              :class="{'menu-active-item' : linkId === 3}"
              @click="activeMenu" v-if="permissionList.includes(permissionCode.ADMIN_HRM)">
              <router-link to="/danh-sach-nhan-vien">
                Danh sách nhân viên
              </router-link>
            </md-list-item>
            <md-list-item class="md-inset sidebar-nav-item"
                          :class="{'menu-active-item' : linkId === 4}"
                          @click="activeMenu" v-if="permissionList.includes(permissionCode.ADMIN_TIMESHEET)">
              <router-link to="/bang-cong/chi-tiet">
                Quản lý chấm công
              </router-link>
            </md-list-item>
            <md-list-item class="md-inset sidebar-nav-item"
                          :class="{'menu-active-item' : linkId === 10}"
                          @click="activeMenu" v-if="permissionList.includes(permissionCode.ADMIN_SHOP_MANAGE)">
              <router-link to="/quan-ly-shop/danh-sach">
                Quản lý shop
              </router-link>
            </md-list-item>

            <md-list-item class="md-inset sidebar-nav-item"
              :class="{'menu-active-item' : linkId === 9}"
              @click="activeMenu"
              v-if="permissionList.includes(permissionCode.ADMIN_CURRENCY_MANAGE)"
              >
              <router-link to="/quan-ly-ty-gia">
                Quản lý tỷ giá
              </router-link>
            </md-list-item>
          </md-list>
        </md-list-item>
        <md-list-item md-expand :md-expanded.sync="expand1" v-if="permissionList.includes(permissionCode.ADMIN_SETTINGS)">
          <div class="md-list-item-text">
            <span>CÀI ĐẶT CHUNG</span>
          </div>

          <md-list slot="md-expand">
            <md-list-item class="md-inset sidebar-nav-item"
                          :class="{'menu-active-item' : $route.meta.tab === 'setting-bonus'}"
                          @click="activeMenu"
            >
              <router-link to="/cai-dat/chinh-sach-thuong">
                Chính sách thưởng
              </router-link>
            </md-list-item>
            <md-list-item class="md-inset sidebar-nav-item"
                          :class="{'menu-active-item' : linkId === 7}"
                          @click="activeMenu"
            >
              <router-link to="/lich-lam-viec">
                Lịch làm việc
              </router-link>
            </md-list-item>
            <md-list-item class="md-inset sidebar-nav-item"
                          :class="{'menu-active-item' : linkId === 8}"
                          @click="activeMenu"
            >
              <router-link to="/holiday">
                Ngày nghỉ lễ
              </router-link>
            </md-list-item>
          </md-list>
        </md-list-item>

      </md-list>
      <!-- menu for setting -->
      <md-list class="nav nav-menu-item" v-else>
        <md-list-item md-expand :md-expanded.sync="expand1">
          <div class="md-list-item-text">
            <span>Cài đặt chung</span>
          </div>

          <md-list slot="md-expand">
            <md-list-item class="md-inset sidebar-nav-item"
              :class="{'menu-active-item' : $route.meta.tab === 'setting-bonus'}"
              @click="activeMenu"
            >
              <router-link to="/cai-dat/chinh-sach-thuong">
                Chính sách thưởng
              </router-link>
            </md-list-item>
            <md-list-item class="md-inset sidebar-nav-item"
              :class="{'menu-active-item' : $route.meta.tab === 'setting-area'}"
              @click="activeMenu"
            >
              <router-link to="/cai-dat/khu-vuc-chuyen-mon">
                Khu vực / Chuyên môn
              </router-link>
            </md-list-item>
          </md-list>
        </md-list-item>
      </md-list>
     </div>
    </div>
    </div>
  </div>
</template>
<script>
import GroupService from "@/store/services/GroupService";
import {GROUP_TYPE, PERMISSION_CODE} from "../../../const/const";
export default {
  name: "sidebar",
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`
      };
    },
    permissionList() {
      return this.$store.state.auth.permission.map(item => item.name);
    },
    showAzaWorkTab() {
      let arr = [this.permissionCode.ADMIN_HRM, this.permissionCode.ADMIN_REWARD_MANAGE, this.permissionCode.ADMIN_TIMESHEET];
      const result = arr.some(x => this.permissionList.includes(x));
      return result;
    }
  },
  props: {
    activeColor: {
      type: String,
      default: "green",
    },
    backgroundImage: {
      type: String,
    },
    // backgroundColor: {
    //   type: String,
    //   default: "black",
    //   validator: value => {
    //     let acceptedValues = ["", "black", "white", "red"];
    //     return acceptedValues.indexOf(value) !== -1;
    //   }
    // },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    // this.getGroups();
    this.activeMenu();
  },
  created() {
    // this.$sidebar.toggleMinimize();
    this.activeMenu();
  },
  data() {
    return {
      tab: 'admin',
      workGroups: [],
      branchGroups: [],
      companyWallGroups: [],
      pinnedGroups: [],
      groupImageDefault: '/img/group/group_image_low.jpg',
      expand1: false,
      expand2: false,
      expand3: false,
      expand4: false,
      linkId: 2,
      sideBarMenuActive: 1, // 1:  default, 2: setting
      permissionCode: PERMISSION_CODE
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

    activeMenu(){
      const tab = this.$route.meta.tab;
      // console.log(this.$route);
      this.expand1 = this.expand2 = this.expand3 = this.expand4 = false;
      // if(tab === 'tab-work'){
        this.expand1 = true;
        this.linkId = this.$route.meta.id;
        // this.$route.path.replace('/', '');
      // }
    },
    inlineClickHandler(){
      this.activeMenu();
    },
    changeMenu(type) {
      this.tab = type;
    },
    async logout() {
      await this.$store.dispatch("logout");
    },
  },

  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }

  .sidebar-menu{
   .list{
    width: 280px;
   }
  }
  .nav-menu-item{
    .md-active{
      border: none !important;
    }
    .md-icon-image{
      svg{
        fill: #FFF !important;
      }
    }
    .md-list-item-content{
      padding: 0 !important;
      color: #FFF;
    }
    .sidebar-nav-item{
      padding-left: 10px !important;
    }
    .menu-active-item{
      border-radius: 8px;
      background: rgba(86, 154, 175, 0.57);
      .md-list-item-content{
        color: #00F0FF;
      }
    }
    .md-inset{
      &:hover{
        background-color: rgba(200, 200, 200, 0.2);
        border-radius: 4px !important;
      }
    }
    .md-list-item{
      display: flex;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      padding: 0;
      margin: 0;
      &:hover{
        cursor: pointer;
      }
      .md-list-item-content{
        font-size: 14px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.05px;
      }
      .md-list-item-button{
        &:hover{
          background-color: unset !important;
        }
      }
      a{
        padding: 10px 0;
      }
    }
   .md-list-item-text{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.05px;
   }
  }

}
  .title-sidebar{
    margin: 10px 0 23px;
  }
.subtitle-sidebar{
  margin: 22px 0 12px;
}
</style>
