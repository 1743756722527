<template>
  <div>
    <modal name="confirm-download-image-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
    >
      <md-card class="modal-header-custom">
        <md-dialog-title>
            <span class="neutral_1 heading-500">Xuất bản ảnh thưởng doanh số</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="closeModal">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content>
        <div class="md-gutter">
          {{ content }}
        </div>
      </md-card-content>

      <md-dialog-actions>
        <template v-if="isExporting">
            <!-- <div class="loading-ex"> -->
              <vue-element-loading :active="isExporting" spinner="bar-fade-scale" />
            <!-- </div> -->
          </template>
          <template v-else>
            <button type="submit" class="btn-active md-button" @click="exportImage">Đồng ý</button>
            <button class="btn-stroke md-button" @click="closeModal">Huỷ</button>
          </template>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import AdminService from "../../../store/services/AdminService";

export default {
  name: 'confirm-download-image-modal',
  computed: {},
  mounted() {
  },
  data: () => ({
    content : '',
    data: {},
    isExporting: false
  }),
  methods: {
    beforeOpened(event) {
      this.isExporting = false;
      this.content = event.params.content;
      this.data = event.params.data;
    },

    closeModal(){
      if(!this.isExporting){
        this.$modal.hide('confirm-download-image-modal')
      }
    },

    async exportImage(){
      this.isExporting = true;
      try {
        await AdminService.exportBanner(this.data).then(res => {
          if(res.data){
            let link = document.createElement('a');
            link.href = process.env.VUE_APP_API_BASE_URL + '/media/download/' + res.data.id;
            link.download = res.data.filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => {
              this.$modal.hide('confirm-download-image-modal');
              this.isExporting = false;
            }, 1500);
          }
        });
      } catch (error) {
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
        this.isExporting = false;
      }
    }
  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
  .loading-ex{
    position: unset !important;
  }
}
</style>
