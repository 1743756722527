<template>
    <div class="detail-panel" style="position: absolute !important;">
        <vue-element-loading :active="isLoading" is-full-screen spinner="bar-fade-scale" />
        <div class="detail-panel-header">
            <div class="employee-detail-infomation">
                <div class="back-page d-flex-center-between cursor-pointer" @click="() => $emit('back-page', true)">
                        <md-icon>arrow_back</md-icon>
                    </div>
                <div class="employee-avatar">
                    <md-avatar>
                        <img :src="data_detail && data_detail.marketing.avatar ? data_detail.marketing.avatar : '/img/default-avatar.png'" alt="avatar">
                    </md-avatar>
                </div>
                <div class="employee-fullname-department">
                    <div class="fullname">{{ data_detail && data_detail.marketing.fullname ? data_detail.marketing.fullname : '' }}</div>
                    <div class="department-name">
                        {{ data_detail.marketing.role.display_name ? data_detail.marketing.role.display_name : '----' }}
                    </div>
                </div>
            </div>
            <div class="filter-detail">
                <!-- <md-field class="filter-select">
                    <md-select v-model="statusMode" name="statusMode" placeholder="...">
                        <md-option value="1">Doanh số ước tính</md-option>
                        <md-option value="2">Doanh số thực tế</md-option>
                    </md-select>
                </md-field> -->
                <div class="filter-select">
                    <drop-down>
                    <div slot="title" class="dropdown-toggle cursor-pointer" data-toggle="dropdown">
                        <span>{{ statusMode === 1 ? 'Doanh số ước tính' : 'Doanh số thực tế' }}</span>
                        <md-icon>arrow_drop_down</md-icon>
                    </div>
                    <ul class="dropdown-menu dropdown-menu-right">
                        <li @click="changeMode(1)"><a href="#">Doanh số ước tính</a></li>
                        <li @click="changeMode(2)"><a href="#">Doanh số thực tế</a></li>
                    </ul>
                    </drop-down>
                </div>
                <date-picker 
                    class="datetime-picked" 
                    placeholder="Select date range" 
                    v-model="dateTimeRange" 
                    range-separator=" - " 
                    format="DD/MM/YYYY"
                    type="month"
                    :clearable="false"
                    :confirm="false"
                    :confirm-text="'Xác nhận thời gian tìm kiếm.'"
                    :lang="lang"
                    @change="changeDate"
                    :range="false"
                    >
                    <template v-slot:input>
                    <input v-model="dateSelection" type="text" name="date" 
                        readonly 
                        autocomplete="off" 
                        placeholder="Chọn thời gian" 
                        class="mx-input" />
                    </template>
                    <template v-slot:icon-calendar>
                        <md-icon>arrow_drop_down</md-icon>
                    </template>
                </date-picker>
            </div>
        </div>
        <div class="detail-panel-container">
            <!--  -->
            <div class="md-layout md-alignment-space-between-center revenue-layout">
                <div class="md-layout-item">
                <div class="layout-money income">
                    <div class="title-money">
                    <span>Tổng thưởng</span>
                    </div>
                    <div class="total-money">
                    <span>
                    {{ formatCurrency(data_detail.total_reward_amount) }}
                    </span>
                    </div>
                </div>
                </div>
                <div class="md-layout-item">
                <div class="layout-money total-reward">
                    <div class="title-money">
                    <span>Thưởng Daily Deals</span>
                    </div>
                    <div class="total-money">
                    <span>
                        {{ formatCurrency(data_detail.daily_reward_amount) }}
                    </span>
                    </div>
                </div>
                </div>
                <div class="md-layout-item">
                <div class="layout-money salary">
                    <div class="title-money">
                    <span>Thưởng sản phẩm mới</span>
                    </div>
                    <div class="total-money">
                    <span>{{ formatCurrency(data_detail.product_reward_amount) }}</span>
                    </div>
                </div>
                </div>
            </div>
            <!--  -->
             <!-- chỉ số đơn hàng - doanh số -->
            <div class="md-layout md-gutter md-layout-item md-size-100 md-alignment-space-between-center revenue-detail">
            <div class="md-layout-item">
                <div class="revenue-index__title">
                <span>Tổng đơn</span>
                </div>
                <div class="revenue-index__number">
                <span>
                    {{ formatCurrency(revenueOverView.total.total_order) }}
                </span>
                </div>
            </div>
            <div class="md-layout-item">
                <div class="revenue-index__title">
                <span>Đơn done</span>
                </div>
                <div class="revenue-index__number">
                    <span v-if="revenueOverView && revenueOverView.total && revenueOverView.total.total_order">
                        {{ formatCurrency(revenueOverView.total.done_order) }}
                    </span>
                    <span v-else>0</span>
                </div>
            </div>
            <div class="md-layout-item">
                <div class="revenue-index__title">
                    <span>Doanh số ước tính</span>
                </div>
                <div class="revenue-index__number">
                <span>
                    {{ formatCurrency(Math.ceil(data_detail.revenue)) }}
                </span>
                </div>
            </div>
            <div class="md-layout-item">
                <div class="revenue-index__title">
                    <span>CPQC</span>
                </div>
                <div class="revenue-index__number">
                    <span>
                        {{ formatCurrency(Math.ceil(data_detail.ads_cost)) }}
                    </span>
                </div>
            </div>
            <div class="md-layout-item">
                <div class="revenue-index__title">
                    <span>%CPQC</span>
                </div>
                <div class="revenue-index__number">
                    <span>
                        {{ formatCurrency(data_detail.revenue === 0 ? 0 : ((data_detail.ads_cost / data_detail.revenue) * 100).toFixed(2)) }}
                    </span>
                </div>
            </div>
            </div>
            <!-- Table -->
            <div class="table-detail-panel">
                <ve-table border-y
                    row-key-field-name="rowKey"
                    :cellSelectionOption="cellSelectionOption"
                    :cell-style-option="cellStyleOption"
                    :columns="columns"
                    :table-data="revenueOverView.daily"
                />
            </div>
        </div>
        <ConfirmDownLoadImageModal />
    </div>
  </template>
  
  <script>
  import vue2DatePicker from "@/mixins/config-vue2-datepicker";
  import DatePicker from 'vue2-datepicker';
  import {helpers} from "../../../../helper/helpers";
  import AdminService from "../../../../store/services/AdminService";
  import moment from "moment";
  import ConfirmDownLoadImageModal from "../../../Components/Modal/ConfirmDownLoadImageModal"
  export default {
    components: {
        DatePicker,
        ConfirmDownLoadImageModal
    },
    mixins: [vue2DatePicker],
    props: {
        data_detail: null
    },
    data() {
        const date = new Date();
        return {
            statusMode: 1,
            dateTimeRange: [
                new Date(date.getFullYear(), date.getMonth(), 1),// ngày tháng đầu tiên của tháng
                new Date() // giờ hiện tại
            ],
            cellSelectionOption: {
                enable: false,
            },
            cellStyleOption: {
                bodyCellClass: ({ row, column, rowIndex }) => {
                    if (column.field === "pecent_cpqc") {
                        return "table-body-pecent-cpqc";
                    }
                },
            },
            columns: [
                {
                field: "date",
                key: "a",
                title: "Ngày",
                align: "center",
                children: [
                    {
                    field: "date",
                    key: "aa",
                    title: "",
                    width: 50,
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        return (
                        <div>{moment(row.date).format('DD/MM')}</div>
                        );
                    }
                    }
                ],
                },
                {
                field: "number_order",
                key: "b",
                title: "Tổng đơn",
                align: "center",
                children: [
                    {
                    field: "number_order",
                    key: "bb",
                    title: "",
                    align: "right",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        return (
                        <div class="total-revenue">
                            {helpers.formatCurrency(row.number_order ?? 0)}
                        </div>
                        );
                    }
                    }
                ],
                },
                {
                field: "ads_cost",
                key: "d",
                title: "CPQC",
                align: "center",
                children: [
                    {
                    field: "ads_cost",
                    key: "dd",
                    title: "",
                    align: "right",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        return (
                        <div class="total-revenue">
                            <span>
                            {helpers.formatCurrency(row.ads_cost ?? 0)}
                            </span>
                        </div>
                        );
                    }
                    }
                ],
                },
                {
                field: "revenue",
                key: "c",
                title: "Doanh số",
                align: "center",
                children: [
                    {
                    field: "revenue",
                    key: "cc",
                    title: "",
                    align: "right",
                    renderHeaderCell: ({ row, column, rowIndex }, h) => {
                        return (
                        <div class="total-daily-label">
                            Tổng
                        </div>
                        );
                    },
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        return (
                        <div class="total-revenue">
                            {helpers.formatCurrency(Math.ceil(row.revenue) ?? 0)}
                        </div>
                        );
                    }
                    }
                ],
                },
                {
                field: "pecent_cpqc",
                title: "%CPQC",
                align: "center",
                children: [
                    {
                    field: "pecent_cpqc",
                    key: "pdl",
                    title: "",
                    align: "right",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        let percent_qc = (row.ads_cost / row.revenue) * 100;
                        percent_qc = Number.isFinite(percent_qc) ? percent_qc.toFixed(2) : 0;

                        let className = 'pecent-cpqc';
                        let cost_percent = 0;
                        if(row.daily_reward && row.daily_reward.pr_reward && row.daily_reward.pr_reward.cost_percent){
                        cost_percent = row.daily_reward.pr_reward.cost_percent;
                        }
                        if(percent_qc > 26){
                        className += ' pecent-cpqc-lg'
                        }
                        
                        return (
                        <div class={className}>
                            {helpers.formatCurrency(percent_qc)}
                        </div>
                        );
                    }
                    }
                ],
                },
                {
                field: "daily_reward", key: "f", title: "Thưởng Daily Deals", align: "center",
                children: [
                    {
                    field: "daily_reward",
                    key: "ff",
                    title: "",
                    align: "right",
                    renderHeaderCell: ({ row, column, rowIndex }, h) => {
                        return (
                        <div class="total-header-revenue">
                            {helpers.formatCurrency(this.totalHeaderTable.daily_reward_total)}
                        </div>
                        );
                    },
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        let classAmount = '';
                        // if(row.daily_reward && row.daily_reward.pr_reward){
                        //   if(row.daily_reward.pr_reward.type === 1){
                        //     classAmount = 'reward-blue';
                        //   } else {
                        //     classAmount = 'reward-red';
                        //   }
                        // }

                        let amount = 0;
                        if(row.daily_reward && row.daily_reward.pr_reward){
                        if(row.daily_reward.pr_reward.amount){
                            amount = helpers.formatCurrency(row.daily_reward.pr_reward.amount);
                        }

                        if(row.daily_reward_selected){
                            classAmount = 'achieved-amout';
                        } else if(row.daily_reward.pr_reward.amount > 0) {
                            classAmount = 'not-reached';
                        }
                        }

                        return (
                        <div class="total-reward">
                            <span class={classAmount}>
                            {amount}
                            </span>
                        </div>
                        );
                    }
                    }
                ],
                },
                {
                field: "product_reward", key: "g", title: "Thưởng SP mới", align: "center",
                children: [
                    {
                    field: "product_reward",
                    key: "gg",
                    align: "right",
                    renderHeaderCell: ({ row, column, rowIndex }, h) => {
                        return (
                        <div class="total-header-revenue">
                            {helpers.formatCurrency(this.totalHeaderTable.product_reward_total)}
                        </div>
                        );
                    },
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        let classAmount = '';

                        let amount = 0;
                        if(row.product_reward && row.product_reward.pr_reward){
                        if(row.product_reward.pr_reward.amount){
                            amount = helpers.formatCurrency(row.product_reward.pr_reward.amount);
                        }

                        if(row.product_reward.pr_reward.amount > 0 && row.product_reward_selected){
                            classAmount = 'achieved-amout';
                        } else if(row.product_reward.pr_reward.amount > 0) {
                            classAmount = 'not-reached';
                        }
                        }

                        return (
                        <div class="total-reward">
                            <span class={classAmount}>
                            {amount}
                            </span>
                        </div>
                        );
                    }
                    }
                ],
                },
                {
                field: "image_reward", key: "l", title: "Ảnh chào thưởng", align: "center",
                children: [
                    {
                    field: "image_reward",
                    key: "ll",
                    align: "center",
                    width: 100,
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        return (
                        <div>
                            {
                            row.daily_reward_selected || row.product_reward_selected ?
                                <div class="download_image cursor-pointer" onclick={() => this.exportImage(row)}>
                                <img src="/img/svg/Download.svg" />
                                </div>
                                : ''
                            }
                        </div>
                        );
                    }
                    }
                ],
                },
                {
                field: "name", key: "h", title: "Mô tả", width: 300, align: "left",
                children: [
                    {
                    field: "name",
                    key: "hh",
                    align: "left",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        let name = '';
                        
                        if(row.product_reward_selected){
                        name = row.product_reward?.pr_reward?.name ?? '';
                        } else {
                        name = row.daily_reward?.pr_reward?.name ?? '';
                        }

                        if(row.comment && name != ''){
                        name += ' ';
                        }
                        return (
                        <div class="reward-name">
                            { name }
                            {
                            row.comment !== ''
                            ? <span class="duplicate-new-product">{row.comment}</span> 
                            : ''
                            }
                        </div>
                        );
                    }
                    }
                ],
                }
            ],
            revenueOverView: {
                daily: [],
                salary: [],
                total: [],
            },
            totalHeaderTable: {
                daily_reward_total: 0,
                product_reward_total: 0,
                // percent_cost: 0
            },
            revenueOverviewOfUser: {
                params: {
                    start_date: moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    revenue_type: 1, // 1 - DSUT , 2 - DSTT
                    limit: 3,
                    good_ids: [],
                    country: [],
                    marketing_platform: [],
                    user_id : null
                }
            },
            dateSelection: '',
            isLoading: false
      };
    },

    mounted(){
        this.dateSelection = moment(this.data_detail.start_date).format('DD/MM/YYYY') + ' - ' + moment(this.data_detail.end_date).format('DD/MM/YYYY');
        this.statusMode = this.data_detail.revenue_type;
        this.revenueOverviewOfUser.params.start_date = this.data_detail.start_date;
        this.revenueOverviewOfUser.params.end_date = this.data_detail.end_date;
        this.revenueOverviewOfUser.params.revenue_type = this.data_detail.revenue_type;
        this.getRevenueOverviewOfUser();
    },
  
    methods: {
        changeDate(value){
            let start_date = moment(value).startOf('month');
            let end_date = moment(value).endOf('month');

            if(moment(value).isSame(moment(), 'month')){
                end_date = moment();
            }

            this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
            this.revenueOverviewOfUser.params.start_date = start_date.format('YYYY-MM-DD');
            this.revenueOverviewOfUser.params.end_date = end_date.format('YYYY-MM-DD');
            this.revenueOverviewOfUser.params.revenue_type = this.statusMode;
            this.getRevenueOverviewOfUser();
        },
        formatCurrency(currency){
            if(currency == null){
                currency = 0;
            }
            currency = Math.round(currency);
            return helpers.formatCurrency(currency);
        },
        
        changeMode(mode){
            if(this.statusMode === mode) return;
            
            this.statusMode = mode;
            this.revenueOverviewOfUser.params.revenue_type = mode;
            this.getRevenueOverviewOfUser();
        },

        async getRevenueOverviewOfUser(){
            this.revenueOverviewOfUser.params.user_id = this.data_detail.marketing.id;
            this.revenueOverView = {
                daily: [],
                salary: [],
                total: [],
            }
            this.isLoading = true;
            await AdminService.getRevenueOverviewOfUser(this.revenueOverviewOfUser.params)
            .then(res => {
                if(res.status){
                let revenueOverView = res.data;

                let daily_reward_total = 0;
                let product_reward_total = 0;

                for (const item of revenueOverView.daily) {
                    item.daily_reward_selected = false;
                    item.product_reward_selected = false;
                    item.comment = '';

                    const dailyRewardAmount = item.daily_reward && item.daily_reward.pr_reward ? item.daily_reward.pr_reward.amount : 0;
                    const productRewardAmount = item.product_reward && item.product_reward.pr_reward ? item.product_reward.pr_reward.amount : 0;

                    if (dailyRewardAmount > 0 && item.daily_reward.active) {
                        item.daily_reward_selected = true;
                        daily_reward_total += dailyRewardAmount;
                    } else if (productRewardAmount > 0 && item.product_reward.active) {
                        item.product_reward_selected = true;
                        product_reward_total += productRewardAmount;
                    }
                }

                this.totalHeaderTable = {
                    daily_reward_total : daily_reward_total,
                    product_reward_total : product_reward_total,
                };
                this.revenueOverView = revenueOverView;
                }
            });
            this.isLoading = false;
        },
        exportImage(data){

            let type = data.daily_reward_selected ? 'daily_reward' : 'product_reward';

            let percent_qc = (data.ads_cost / data.revenue) * 100;
            percent_qc = Number.isFinite(percent_qc) ? percent_qc.toFixed(2) : 0;

            const params = {
                name: this.data_detail.marketing.fullname,
                text: `Đã đạt mốc doanh số ${this.formatCurrency(data[type].pr_reward.revenue)} ₫ ${data[type].pr_reward.type === 2 ? 'Daily Deals' : 'Sản phẩm mới' } với %CPQC < ${data[type].pr_reward.cost_percent ?? 0}% ngày ${moment(data.date).format('DD-MM')}`,
                money: helpers.formatCurrency(data[type].pr_reward.amount) + ' ₫',
                sales: helpers.formatCurrency(Math.ceil(data.revenue)) + ' ₫',
                cpqc: helpers.formatCurrency(Math.ceil(data.ads_cost)) + ' ₫',
                per_cpqc: helpers.formatCurrency(percent_qc) + '%',
                user_id: this.data_detail.marketing.id
            }

            this.$modal.show('confirm-download-image-modal', {
                data: params,
                content: `Bạn có muốn xuất bản và tải ảnh thưởng doanh số ngày ${moment(data.date).format('DD-MM')} không?`
            });
        },
        backPage(){
            this.$emit('back-page', true);
        }
    }
  };
  </script>
<style lang="scss">
    .detail-panel{
        position: absolute !important;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background: #fff;
        .md-field{
            &:after{
                background-color: unset !important;
            }
        }
        .md-select{
            border: 0.5px solid rgba(211, 211, 211, 1);
            border-radius: 4px;
        }
        .employee-detail-infomation {
            float: left;
            display: flex;
            grid-gap: 10px;
        }
        .employee-fullname-department{
            .fullname{
                font-size: 14px;
                font-weight: 500;
                color: rgba(16, 24, 64, 1);
            }
            .department-name{
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
                color: rgba(82, 87, 111, 1);
            }
        }
        .detail-panel-header {
            height: 50px;
        }
        .filter-detail {
            float: right;
            display: flex;
            align-items: center;
            grid-gap: 10px;
            .filter-select{
                width: 200px;
                border: 0.5px solid rgba(211, 211, 211, 1);
                border-radius: 4px;
                height: 34px;
                .dropdown-toggle{
                    padding: 6px 0;
                    border-radius: 4px;
                    border: 0.5px;
                    gap: 8px;
                    text-align: center;
                    font-weight: 500;
                    font-size: 14px;
                    .md-icon{
                        margin-left: 10px;
                    }
                }
            }
        }
        .detail-panel-container{
            .revenue-detail {
                border-radius: 8px;
                max-height: 60px;
                text-align: center;
                margin: auto !important;
                min-height: max-content;
                .md-layout-item{
                min-height: 100%;
                margin: 5px;
                position: relative;
                padding: 0;
                &:not(:nth-last-child(5n+1)):after{
                    position: absolute;
                    content: "";
                    width: 0.5px;
                    height: 40px;
                    background: #D8DAE5;
                    top: 50%;
                    right: -10px;
                    transform: translateY(-50%);
                }
                .revenue-index__title{
                    font-size: 13px;
                    font-weight: 400;
                }
                .revenue-index__number{
                    color: rgba(209, 67, 67, 1);
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
                }
            }
            .revenue-layout {
                padding-top: 34px;
                padding-bottom: 20px;
                border-top: 0.5px solid rgba(211, 211, 211, 1);
                .md-layout-item {
                    min-height: 60px;
                    padding: 0;
                    border: none;
                    max-width: 180px;
                    padding-left: 0 !important;
                    margin-right: 10px;
                    .income {
                    background: rgba(255, 138, 0, 1);
                    }

                    .total-reward {
                    background: rgba(164, 82, 228, 1);
                    }

                    .revenue {
                    background: rgba(228, 94, 82, 1);
                    }

                    .cpqc {
                    background: rgba(0, 203, 106, 1);
                    }

                    .salary{
                    background: rgba(14, 173, 135, 1);
                    }

                    .layout-money {
                    height: 60px;
                    text-align: center;
                    padding-top: 5px;
                    color: #fff;
                    border-radius: 8px;
                    }

                    &:after {
                    width: 100%;
                    height: 100%;
                    display: block;
                    background: md-get-palette-color(red, 200);
                    content: " ";
                    }
                }
            }
            .table-detail-panel {
                margin-top: 20px;
                padding: 0 !important;
                .statistical-header {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    .statistical-title {
                        font-weight: 600;
                        text-transform: uppercase;
                        color: rgba(16, 24, 64, 1);
                    }
                    .statistical-see-detail {
                        a{
                            color: rgba(23, 101, 252, 1) !important;
                        font-size: 14px;
                        }
                        .md-icon{
                            color: rgba(23, 101, 252, 1) !important;
                            font-size: 18px !important;
                        }
                    }
                }
                .vue-table-root{
                    width: 100%;
                    // .ve-table-container{
                    //     &::-webkit-scrollbar {
                    //         width: 6px;
                    //         height: 6px;
                    //     }
                    //     &::-webkit-scrollbar-track {
                    //         background: #f1f1f1;
                    //     }
                    //     &::-webkit-scrollbar-thumb {
                    //         background: #8888887a;
                    //         border-radius: 5px;
                    //         &:hover {
                    //         background: #555;
                    //         }
                    //     }
                    // }
                    .ve-table-header-tr {
                        &:nth-child(2){
                            th {
                            &:nth-child(5), &:nth-child(6), &:nth-child(7){
                                    border-right: 1px solid #eee;
                                }
                                background-color: #fff !important;
                                border-right: none;
                            }
                        }
                    }
                    .achieved-amout{
                        color: rgba(92, 162, 34, 1);
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: -0.05000000074505806px;
                        text-align: right;
                    }
                    .not-reached{
                        color: rgba(16, 24, 64, 1);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: -0.05000000074505806px;
                        text-align: right;
                        text-decoration: line-through;
                    }
                    .total-header-revenue{
                        color: rgba(36, 0, 255, 1);
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: -0.05000000074505806px;
                    }
                    .total-daily-label{
                        color: rgba(16, 24, 64, 1);
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: -0.05000000074505806px;
                        text-align: right;

                    }
                    .duplicate-new-product{
                        color: #ab3000;
                        font-weight: 400;
                    }
                    .pecent-cpqc-lg{
                        color: #d14343;
                        height: 100%;
                        display: flex;
                        justify-content: end;
                        align-items: center;
                    }
                }
                .reward-name {
                    /*word-break: break-all;*/
                }
                .total-reward{
                    .reward-blue{
                        color: rgba(36, 0, 255, 1);
                        font-weight: 600;
                    }
                    .reward-red{
                        color: rgba(255, 79, 79, 1);
                        font-weight: 600;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
</style>
  