import BaseService from "./BaseService";

export default class ShopManagementService extends BaseService {
    static async getShops(params = {}) {
        const res = await this.get(`/shops`, params);
        return res.data;
    }

    static async updateShop(params = {}) {
        const res = await this.post(`/shops/edit`, params);
        return res.data;
    }

    static async getUpdatedShopHistories(params = {}) {
        const res = await this.get(`/shops/histories`, params);
        return res.data;
    }

    static async exportShop(params = {}) {
        const res = await this.get(`/shops/export-excel`, params);
        return res.data;
    }

    static async syncNameExcelShop(params = {}) {
        const res = await this.post(`/shops/sync-excel`, params);
        return res.data;
    }
}
