<template>
    <md-toolbar
            md-elevation="0"
            id="menu-toolbar"
            class="md-transparent p-0px"
            :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
    >
        <md-card class="p-0px m-0px border-radius-0px toolbar-header">
            <div class="md-toolbar-row toolbar-left">
                <md-list-item>
                    <md-button
                            class="md-just-icon md-round md-simple md-toolbar-toggle"
                            :class="{ toggled: $sidebar.showSidebar }"
                            @click="toggleSidebar"
                    >
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </md-button>
                    <div class="md-list-item-text">
                        <span class="item-text">{{ titlePage }}</span>
                    </div>
                </md-list-item>
                <div class="md-toolbar-section-end">
                    <div class="avatar-badge">
                        <md-list-item class="cursor-pointer" @click="tooglePopup" v-click-outside="closePopup">
                            <div class="md-list-item-text text-right mr-5px">
                                <p><span class="text-400-medium fullname mb-2px">{{ user.fullname }}</span></p>
                                <p class="user-team">
                                    {{
                                    user.team && user.team.team ? user.team.team :
                                    user.team && user.team.agency ? user.team.agency :
                                    user.team && user.team.department ? user.team.department : 'Aza'
                                    }}
                                </p>
                            </div>
                            <md-avatar class="logo-toolbar">
                                <img class="logo"
                                     :src="user.avatar ? changeLinkAvatar(user.avatar) : '/img/toolbar/logo_toolbar.svg'">
                            </md-avatar>
                        </md-list-item>
                        <div class="popupItem" v-if="showPopup">
                            <div class="list-item cursor-pointer" v-on:click="logout()">
                                <md-icon>logout</md-icon>
                                <span class="md-list-item-text">Logout</span>
                            </div>
                        </div>
                    </div>
                    <md-button
                            class="md-just-icon md-just-icon-mobile md-round md-simple md-toolbar-toggle"
                            :class="{ toggled: $sidebar.showSidebar }"
                            @click="toggleSidebar"
                    >
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </md-button>
                </div>
            </div>
            <div class="md-toolbar-row  toolbar-row-menu menu-card" id="menu-card">
                <!-- <div class="menu-card" id="menu-card"> -->
                <!-- Content Using Teleport -->
                <!-- </div> -->
            </div>
        </md-card>
    </md-toolbar>
</template>

<script>
    import {helpers} from "../../helper/helpers";

    export default {
        data() {
            return {
                user: this.$store.state.auth.user,
                titlePage: document.title,
                showPopup: false
            };
        },
        mounted() {

        },
        watch: {
            '$route'() {
                this.titlePage = document.title;
            }
        },
        methods: {
            changeLinkAvatar(url) {
                return helpers.changeLinkAvatar(url)
            },
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
            },
            minimizeSidebar() {
                // if (this.$sidebar) {
                //   this.$sidebar.toggleMinimize();
                // }
            },
            goToNotifications() {
                this.$router.push({name: "Notifications"});
            },
            goToUsers() {
                this.$router.push({name: "User Profile"});
            },
            tooglePopup() {
                this.showPopup = !this.showPopup;
            },
            closePopup() {
                this.showPopup = false;
            },
            async logout() {
                this.closePopup();
                await this.$store.dispatch("logout");
            },
        },
    };
</script>
<style lang="scss">
    .md-toolbar {
        height: max-content;
        padding-left: 2px;
        box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
    }

    .logo-toolbar {
        width: 40px;
        height: 40px;
        left: 0px;
        top: 0px;
        /* background: #EDEFF5; */
        border: 1px solid #FCAF17;
        border-radius: 100%;
    }

    .logo-toolbar .logo {
        width: 75%;
    }

    .toolbar-header {
        .toolbar-left {
            padding: 10px 0;
            border-bottom: 0.5px solid #CBCBCB;
            padding-right: 20px;

            .md-list-item-content {
                padding: 0 !important;

                .md-list-item-text {
                    .item-text {
                        color: #101840;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.056px;
                    }
                }
            }

            .avatar-badge {
                position: relative;

                .popupItem {
                    position: absolute;
                    bottom: -37px;
                    right: 0;
                    width: max-content;
                    padding: 0.25rem 0;
                    background: #ffffff;
                    border: 0 none;
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 28%);
                    border-radius: 5px;

                    .list-item {
                        display: flex;
                        align-items: center;
                        padding: 0 40px 0 10px;

                        .md-icon {
                            font-size: 20px !important;
                        }
                    }
                }

                .md-icon {
                    margin-right: 12px;
                    font-size: 30px !important;
                }

                .md-badge {
                    right: 5px !important;
                }

                .fullname {
                    color: rgba(55, 107, 251, 1);
                    text-align: right;
                    float: right;
                }

                .user-team {
                    font-size: 12px !important;
                }
            }
        }

        .menu-card {
            display: flex;
            width: max-content;
            /* padding: 0px 20px; */
            align-items: center;
            gap: 4px;

            .menu-item {
                padding: 0 15px 10px;
                gap: 10px;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                margin-top: 10px;

                &:hover {
                    cursor: pointer;
                }
            }

            .menu-item-active {
                border-bottom: 3px solid #FF7B00;
                color: #FF7B00;
            }
        }

    }
</style>
