<template>
  <div>
    <modal name="sync-shop-excel-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom modal-excel-sync-name"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <md-card class="modal-header-custom">
        <md-dialog-title>
            <span class="neutral_1 heading-500">Chỉnh sửa tên shop hàng loạt</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="closeModal">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content style="height: 300px">
        <div class="md-gutter">
          <div class="holiday-name">
            <div class="label mb-5px">
              <label class="required">Tải file</label>
            </div>
            <input ref="fileUpload" type="file" class="mx-input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="validateFile($event)">
            <div class="error">
              {{ error }}
            </div>
            <div class="mt-20px">Xem và tải file mẫu <a target="_blank" href="https://docs.google.com/spreadsheets/d/1Y3bOOAFnr0cDnO86JXuh4wUJUCkc75vRVYKsvEUxmbM/edit?usp=sharing">tại đây!</a></div>
          </div>
        </div>
        <div class="error-container" v-if="error_message && error_message.length > 0">
          <div class="label">Danh sách lỗi theo tên.</div>
          <div class="error">*Chỉnh sửa file và upload lại file Excel</div>
          <table cellspacing="0" cellpadding="0">
            <tr>
              <td style="padding: 0;border: 0;">
                <table cellspacing="0" cellpadding="1">
                  <tr>
                    <th style="width: 100px;">Tên shop</th>
                    <th>Lỗi</th>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style="padding: 0;border: 0;">
                <div style="height:380px; overflow:auto;">
                  <table cellspacing="0" cellpadding="1" >
                    <tr v-for="(item, index) in error_message" :key="index">
                      <td style="width: 100px">{{ item.name }}</td>
                      <td>{{ item.message }}</td>
                    </tr>
                  </table>  
                </div>
              </td>
            </tr>
          </table>
          <!-- <table>
            <tr style="height: 30px;">
              <th style="width: 100px;">Tên shop</th>
              <th>Lỗi</th>
            </tr>
            <tr>
              <td>
                <div class="table-scroll">
                  <tr v-for="(item, index) in error_message" :key="index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.message }}</td>
                  </tr>
                </div>
              </td>
            </tr>
          </table> -->
          <!-- <div class="error-message" v-for="(message, index) in error_message" :key="index">
            {{ message }}
          </div> -->
        </div>
      </md-card-content>

      <md-dialog-actions>
        <button type="submit" class="btn-active md-button" @click="syncExcel">Câp nhật</button>
        <button class="btn-stroke md-button" @click="closeModal">Huỷ</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import ShopManagementService from "../../../store/services/ShopManagementService";
import moment from "moment";

export default {
  name: 'sync-shop-excel-modal',
  components: {
  },
  mounted() {
  },
  data: () => ({
    error: '',
    file: '',
    error_message: []
  }),
  computed: {
  },
  methods: {
    beforeOpened(event) {
      this.resetData();
    },
    beforeClosed(event) {
      this.resetData();
    },

    closeModal(){
      this.resetData();
      this.$modal.hide('sync-shop-excel-modal');
    },

    validateFile(e){
      this.error = '';
      this.error_message = [];
      if (e.target.files[0] && !e.target.files[0].name.endsWith('.xlsx')){
        this.error = 'File tải lên không đúng địng (.Xlsx)';
        this.$refs.fileUpload.value = '';
      } else {
        this.file = e.target.files[0];
      }
    },

    async syncExcel(){
      if(!this.file || this.error !== ''){
        return;
      }
      const formFile = new FormData();
      formFile.append("file", this.file);
      this.error_message = [];
      await ShopManagementService.syncNameExcelShop(formFile).then(res => {
        if(res.status === undefined){
          return;
        }

        if(res.status){
            this.$emit('reloadData');
            this.$store.dispatch("alerts/success", 'Cập nhật thành công');
            this.closeModal();
          } else if(res.status == false && res.data !== null){
            this.error_message = res.data;
            this.$refs.fileUpload.value = '';
            this.file = '';
          }else{
            this.error = res.message;
          }
      })
    },
    resetData(){
      this.error = '';
      this.error_message = [];
      this.file = '';
    }
  }
}
</script>

<style lang="scss">
.modal-excel-sync-name{
  .mx-datepicker{
    width: 100% !important;
    margin-bottom: 10px;
  }
  .holiday-name{
    margin-bottom: 10px;
  }
  .error{
    color: red;
    font-size: 15px;
    margin: 10px 0;
  }
  .error-container{
    .label{
        font-size: 15px;
    }
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }
}
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
  .loading-ex{
    position: unset !important;
  }
}
.mx-datepicker-popup{
  z-index: 99999 !important; 
}
</style>
