<template>
  <div class="ve-table-layout aza-table">
    <ve-table 
              row-key-field-name="rowKey"
              :columns="columns"
              :table-data="tableData"
              :event-custom-option="eventCustomOption"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
              :checkbox-option="checkboxOption"
              :cell-span-option="cellSpanOption"
              :virtual-scroll-option="virtualScroll"
              :row-style-option="rowStyleOption"
              :scroll-width="scrollWidth"
              :border-x="borderX"
              :border-y="borderY"
              :border-around="borderAround"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    eventCustomOption: {
      type: Object,
      default: () => {
      },
    },
    sortOption: {
      type: Object,
      default: () => {
      },
    },
    cellStyleOption: {
      type: Object,
      default: () => {
      },
    },
    checkboxOption: {
      type: Object,
      default: () => {
      },
    },
    cellSpanOption: {
      type: Object,
      default: () => {
      },
    },
    rowStyleOption: {
      type: Object,
      default: () => {
      },
    },
    virtualScrollOption: {
      type: Boolean,
      default: () => false,
    },
    borderX: {
      type: Boolean,
      default: () => true,
    },
    borderY: {
      type: Boolean,
      default: () => true,
    },
    borderAround: {
      type: Boolean,
      default: () => true,
    },
    scrollWidth: {
      type: Number,
      default: () => 1600,
    }
  },
  data() {
    return {
      virtualScroll: {
        enable: false,
      },
    };
  },
  created() {
    this.virtualScroll.enable = this.virtualScrollOption
  },
  methods: {}
}
</script>

<style lang="scss">
</style>