import BaseService from "./BaseService";

export default class SettingService extends BaseService {

  static async getCompanyInfo(params = {}) {
    // const res = await this.get(`/settings/company-info`, params);
    const res = {
      "status": true,
      "message": "Thành công",
      "data": {
        "company" :{
          "headquarters": {
            "logo" : "/img/toolbar/logo_toolbar.svg",
            "name" : "AZA GROUP",
            "address" : "18 Tô Vĩnh Diện",
            "slogan" : "String",
            "phone" : "String",
            "founding_date" : "Date"
          },
          "branch" : [
            {
              "logo" : "/img/group/group_image_low.jpg",
              "name" : "CHI NHÁNH TIKTOK",
              "address" : "Tầng 4, tòa nhà An Cường",
              "slogan" : "String",
              "phone" : "String",
              "founding_date" : "Date"
            },
            {
              "logo" : "/img/group/group_image_low.jpg",
              "name" : "CHI NHÁNH TIKTOK",
              "address" : "Tầng 4, tòa nhà An Cường",
              "slogan" : "String",
              "phone" : "String",
              "founding_date" : "Date"
            },
            {
              "logo" : "/img/group/group_image_low.jpg",
              "name" : "CHI NHÁNH TIKTOK",
              "address" : "Tầng 4, tòa nhà An Cường",
              "slogan" : "String",
              "phone" : "String",
              "founding_date" : "Date"
            },{
              "logo" : "/img/group/group_image_low.jpg",
              "name" : "CHI NHÁNH TIKTOK",
              "address" : "Tầng 4, tòa nhà An Cường",
              "slogan" : "String",
              "phone" : "String",
              "founding_date" : "Date"
            }
          ]
        },
        "core_values" : [
          {
            "value" : "Hoàn thành mọi nhiệm vụ",
            "description" : "String"
          },
          {
            "value" : "Hoàn thành mọi nhiệm vụ",
            "description" : "String"
          },
          {
            "value" : "Hoàn thành mọi nhiệm vụ",
            "description" : "String"
          },{
            "value" : "Hoàn thành mọi nhiệm vụ",
            "description" : "String"
          },
          {
            "value" : "Hoàn thành mọi nhiệm vụ",
            "description" : "String"
          }
        ]
      }
    };
    return res.data;
  }

  static async getCompanyRewardList(params = {}) {
    const res = await this.get(`/getCompanyRewardList`, params, 'aza-work');
    return res.data;
  }
}

