<template>
  <div class="md-layout text-center login-fix-page">
    <div class="md-layout-item md-size-100">
    </div>

    <div
        class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100 login"
    >
      <form @submit.prevent="login">
        <login-card header-color="white">
          <img slot="image-logo" src="https://azagroup.asia/images/logo2.svg" style="height: 80px">
          <md-field
              class="form-group md-invalid"
              slot="inputs"
              style="margin-bottom: 28px"
          >
            <md-icon>email</md-icon>
            <label>Tài khoản</label>
            <md-input v-model="username" type="text"/>
            <validation-error :errors="apiValidationErrors.username"/>
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Mật khẩu</label>
            <md-input v-model="password" type="password"/>
            <validation-error :errors="apiValidationErrors.password"/>
          </md-field>
          <div slot="inputs">
            <div class="g-recaptcha mt-20px" data-sitekey="6LdkmHkmAAAAAHmzzpGhAWSuxienaBSKGNFFxDeK"></div>
          </div>
          <md-button
              slot="footer"
              type="submit"
              class="md-success login-button"
          >
            Đăng nhập
          </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
  import {LoginCard, ValidationError} from "@/components";
  import formMixin from "@/mixins/form-mixin";

  export default {
    components: {
      LoginCard, ValidationError
    },

    mixins: [formMixin],

    data: () => ({
      username: "",
      password: "",
    }),

    methods: {
      async login() {
        const dataForm = {
          username: this.username,
          password: this.password,
          type: "admin"  // trang quản trị cần thêm type admin riêng mới đăng nhập được
        };

        const data = await this.$store.dispatch("login", dataForm);
        
        if (!data.status) {
          this.$store.dispatch("alerts/error", data.message);
          this.setApiValidation(data.data);
        }
        
      }
    }
  };
</script>
<style scoped>
  .login-fix-page {
    padding-bottom: 7em;
    padding-top: 4em;
  }
</style>

