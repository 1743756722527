<template>
  <div class="employees-container">
    <div class="header-label">
      <div class="label">
        Tổng quan
      </div>
      <div class="synchronization-excel-btn ml-10px"
           @click="$modal.show('sync-file-modal', {title: 'Đồng bộ dữ liệu nhân viên'})">
        <md-icon>sync</md-icon>
        <span class="ml-5px">Đồng bộ dữ liệu nhân viên</span>
      </div>
    </div>

    <div class="filter-container mt-20px">
      <div class="d-flex-center search-container">
        <multiselect v-model="customer.value"
                     deselect-label="Can't remove this value"
                     track-by="id"
                     placeholder="Chọn nhân viên"
                     :options="customer.list"
                     :allow-empty="true"
                     :selectLabel="''"
                     :deselectLabel="''"
                     :selectedLabel="''"
                     :searchable="true"
                     :custom-label="helpers.getNameForUser"
                     :multiple="true"
                     @search-change="getEmployeeForDropdown($event)"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
                      <span class="option__title">
                         <md-avatar>
                          <img :src="props.option.avatar ? props.option.avatar : '/img/default-avatar.png'" alt="avatar">
                        </md-avatar>
                        <span class="ml-5px">{{ helpers.getNameForUser(props.option) }}</span>
                      </span>
            </div>
          </template>
        </multiselect>
        <v-select class="keyword-type" :options="keywordTypeList" label="name" placeholder="Chọn loại nhóm"
                  :reduce="(option) => option.type"
                  :searchable="false"
                  v-model="employeeFilter.keyword_type">
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                v-bind="attributes"
                v-on="events"
            />
          </template>
          <template slot="option" slot-scope="origin">
            <div class="flex">
              <div class="col text-overflow-ellipsis">
                <span class="text-400-medium neutral-1">{{ origin.name }}</span>
              </div>
            </div>
          </template>
        </v-select>
      </div>
      <div class="mt-10px filter-sale-date-picker d-flex-center-between">
        <div class="filter">
          <!--           filter-->
          <FilterCondition 
            @onLoadPage="onLoadPage" 
            @teamIds="changeTeamIds" 
            @status="changeStatus"
            :permission="PERMISSION_CODE.ADMIN_HRM">
          </FilterCondition>
          <!--           filter-->
        </div>
        <div class="filter-datepicker d-flex-center-between">
          <div class="filter-button">
            <button class="btn filter-report" @click="getEmployeeReward">
              Tạo báo cáo
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-20px filter-selectbox-primevue mb-10px d-flex-center-between">
      <v-select :options="options" label="name" placeholder="Chọn loại nhóm"
                :reduce="(option) => option.type"
                :clearable="false"
                :searchable="false"
                @option:selected="selectValue"
                v-model="valueSelect">
        <template #search="{attributes, events}">
          <input
              class="vs__search"
              v-bind="attributes"
              v-on="events"
          />
        </template>
        <template slot="option" slot-scope="origin">
          <div class="flex">
            <div class="col text-overflow-ellipsis">
              <span class="text-400-medium neutral-1">{{ origin.name }}</span>
            </div>
          </div>
        </template>
      </v-select>
      <pagination-template :params="pagingParams" @change-params="changePagingParam"/>
    </div>

    <TableEasy
        :columns="columns"
        :tableData="tableData"
        :scrollWidth="0"
        :borderAround="false"
        :borderX="true"
        :borderY="true"
    />
    <Teleport to="#menu-card">
      <div class="menu-item menu-item-active">Danh sách nhân sự</div>
<!--      <div class="menu-item">Cơ cấu tổ chức</div>-->
<!--      <div class="menu-item">Ứng dụng mở rộng</div>-->
    </Teleport>
    <sync-file-modal/>
  </div>
</template>

<script>

import TableEasy from './TableEasy.vue';
import moment from "moment";
import SyncFileModal from "@/pages/Components/Modal/SyncFileModal";
import PaginationMixin from "@/mixins/pagination-mixin";
import PaginationTemplate from "@/components/PaginationTemplate";
import {helpers} from "@/helper/helpers";
import HRMService from "@/store/services/HRMService";
import AdminService from "@/store/services/AdminService";
import EmployeeService from "@/store/services/EmployeeService";
import {keywordTypeList, PERMISSION_CODE} from "@/const/const";
import FilterCondition from "@/pages/Workplace/Pages/ManagementSale/FilterCondition";
import multiselect from "vue-multiselect";

export default {
  components: {
    TableEasy,
    SyncFileModal,
    PaginationTemplate,
    FilterCondition,
    multiselect,
  },

  mixins: [PaginationMixin],

  data() {
    return {
      helpers,
      closeOnClick: true,
      columns: [],
      employeeFilter: {
        team_ids: [],
        keyword: '',
        keyword_type: 'fullname',
        status: ''
      },
      columnsDefault: [
        {
          field: "stt",
          key: "a",
          title: "STT",
          align: "center",
          fixed: "left",
          width: 50,
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div>{rowIndex + this.pagingParams.perPage * (this.pagingParams.page - 1) + 1}</div>
            );
          }
        },
        {
          field: "user_code",
          key: "b",
          title: "Mã nhân viên",
          fixed: "left",
          align: "left",
          width: 120,
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
              <div class="table-team">
                <div class="team-name">{row.username}</div>
                <div class="team-detail">ID: {row.id}</div>
              </div>
            );
          }
        },
        {
          field: "fullname",
          key: "c1",
          title: "Nhân viên",
          fixed: "left",
          align: "left",
          width: 250,
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div class="table-fullname">
                  <div class="full-name-avatar">
                    <img src={row?.avatar ?? '/img/new_logo.png'}/>
                  </div>
                  <div class="name-with-team">
                    <div class="fullname">{row.fullname}</div>
                    <div class="team">{row.role.display_name}</div>
                  </div>
                </div>
            );
          }
        },
        {
          field: "status",
          key: "c",
          title: "Trạng thái",
          align: "center",
          width: 70,
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div class={`status-employee ${row.status === 1 ? 'active' : 'non-active'}`}>
                </div>
            );
          }
        },
      ],
      tableData: [],
      checkboxOption: {
        // row select change event
        selectedRowChange: ({row, isSelected, selectedRowKeys}) => {
          // console.log(row, isSelected, selectedRowKeys);
        },
        // selected all change event
        selectedAllChange: ({isSelected, selectedRowKeys}) => {
          // console.log(isSelected, selectedRowKeys);
        },
      },
      valueSelect: 1,
      options: [
        {type: 1, name: 'TT cá nhân liên lạc'},
        {type: 2, name: 'Bảng lương & phúc lợi'},
        {type: 3, name: 'Khác'},
      ],
      keywordTypeList,
      customer: {
        value: null,
        list: []
      },
      PERMISSION_CODE
    };
  },
  created() {
    this.selectValue(this.options[0]);
    this.getEmployeeReward();
    this.getEmployeeForDropdown();
  },
  mounted() {
  },
  methods: {
    changeTeamIds(event){
      this.employeeFilter.team_ids = event;
      this.getEmployeeForDropdown();
    },
    onLoadPage(event){
      if(event){
        this.getEmployeeReward();
      }
    },
    changePagingParam(value) {
      this.pagingParams = value;
      this.getEmployeeReward();
    },
    selectValue(val) {
      switch (val.type) {
        case 1:
          this.columns = this.columnsDefault.concat([
            {
              field: "agency",
              key: "e",
              title: "Phòng ban",
              align: "left",
              width: 220,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                  <div class="table-team">
                      <div class="team-name">{row.team.team}</div>
                      <div class="team-detail">{row.team.agency}</div>
                  </div>
              );
              }
            },
            {
              field: "timekeeper_user_id",
              key: "d1",
              title: "Mã chấm công",
              align: "center",
              width: 90,
            },
            {
              field: "email",
              key: "d2",
              title: "Email",
              align: "left",
              width: 240,
            },
            {
              field: "phone",
              key: "d3",
              title: "Điện thoại",
              align: "right",
              width: 150,
            },
            {
              field: "address",
              key: "d4",
              title: "Địa chỉ",
              align: "left",
              width: 300,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                  <div class="address">
                    {row.address}
                  </div>
                );
              }
            },
            {
              field: "probationary_first_date",
              key: "d9",
              title: "Ngày thử việc",
              align: "right",
              width: 120,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                const date = row.probationary_first_date ? moment(row.probationary_first_date).format("DD-MM-YYYY") : "";
                return (
                  <div class="address">
                  {date}
                  </div>
                );
              }
            },
            {
              field: "seniority",
              key: "d6",
              title: "Ngày làm việc chính thức",
              align: "right",
              width: 160,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                let seniority = '';
                let first_date = '';
                if (row.offical_first_date) {
                  const date = moment(row.offical_first_date);
                  const currentYear = moment().year();

                  const yearsAgo = currentYear - date.year();
                  const daysAgo = moment().dayOfYear() - date.dayOfYear();
                  if (row.offical_first_date) console.log(row.username, row.offical_first_date, yearsAgo, daysAgo);

                  seniority = yearsAgo === 0 ? `${daysAgo} ngày` : `${yearsAgo} năm ${daysAgo} ngày`;
                  first_date =  moment(row.offical_first_date).format("DD-MM-YYYY")
                }
                return (
                  <div>
                    <div class="">{first_date}</div>
                    <div class="">[{seniority}]</div>
                  </div>
                );
              }
            },
            {
              field: "leave_job_date",
              key: "d7",
              title: "Ngày nghỉ việc",
              align: "right",
              width: 120,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                const date = row.leave_job_date ? moment(row.leave_job_date).format("DD-MM-YYYY") : "";
                return (
                  <div class="address">
                  {date}
                  </div>
                );
              }
            },

          ]);
          break;
        case 2:
          this.columns = this.columnsDefault.concat([
            {
              field: "real_salary",
              key: "real_salary2",
              title: "Mức lương hiện tại",
              align: "right",
              width: 125,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                      <div class="money">
                        {helpers.formatCurrency(row.real_salary)}
                      </div>
              );
              }
            },
            {
              field: "old_salary",
              key: "old_salary1",
              title: "Mức lương cũ",
              align: "right",
              width: 125,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <div class="money">
                      {helpers.formatCurrency(row.old_salary)}
                    </div>
                );
              }
            },
            {
              field: "new_salary_date",
              key: "new_salary_date",
              title: "Ngày lên lương",
              align: "left",
              width: 200,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <div class="date">
                      {row.new_salary_date}
                    </div>
                );
              }
            },
            {
              field: "position_allowance",
              key: "position_allowance",
              title: "Phụ cấp chức vụ",
              align: "right",
              width: 125,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <div class="money">
                      {helpers.formatCurrency(row.position_allowance)}
                    </div>
                );
              }
            },
            {
              field: "other_allowance",
              key: "other_allowance",
              title: "Phụ cấp khác",
              align: "right",
              width: 125,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <div class="money">
                      {helpers.formatCurrency(row.other_allowance)}
                    </div>
                );
              }
            },
            {
              field: "vehicle_allowance",
              key: "vehicle_allowance",
              title: "Phụ cấp xe cộ",
              align: "right",
              width: 125,
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <div class="money">
                      {helpers.formatCurrency(row.vehicle_allowance)}
                    </div>
                );
              }
            },
          ]);
          break;
        case 3:
          this.columns = this.columnsDefault.concat([{
            field: "insurancy_salary",
            key: "insurancy_salary",
            title: "Bảo hiểm",
            align: "right",
            renderBodyCell: ({row, column, rowIndex}, h) => {
              return (
                  <div class="money">
                    {helpers.formatCurrency(row.insurancy_salary)}
                  </div>
              );
            }
          },
          {
            field: "leave_day_number",
            key: "leave_day_number",
            title: "Phép tháng",
            align: "right",
            width: 125,
          }]);
          break;
        default:
          break;
      }
    },
    async getEmployeeReward(){
      this.isLoading = true;
      let paging = {
        page: this.pagingParams.page,
        per_page: this.pagingParams.perPage
      };
      let params = {...paging, ...this.employeeFilter};
      if (this.customer.value) {
        params.user_ids = this.customer.value.map(item => item.id);
      }
      try {
        await EmployeeService.getEmployees(params).then(res => {
          this.pagingParams.lastPage = res.meta.last_page;
          this.pagingParams.total = res.meta.total;
          this.tableData = res.data;
        });
      } catch (error) {
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async getEmployeeForDropdown(keyword = ''){
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        let params = {
          page: 1,
          per_page: 20,
          keyword: keyword,
          keyword_type: this.employeeFilter.keyword_type,
          team_ids: this.employeeFilter.team_ids,
        }
        let dataEmployee = new Set();
        EmployeeService.getEmployees(params).then(res => {
          if (res && res.data) {
            res.data.forEach(item => dataEmployee.add(item));
            this.customer.list = Array.from(dataEmployee);
          }
        });
      }, 800);
    },
    
    changeStatus(event){
      this.employeeFilter.status = event ?? '';
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.md-menu-content {
  z-index: 9999;
}

.employees-container {
  background: #fff;
  padding: 10px;

  .search-container {
    .multiselect {
      width: 250px;
    }
    .keyword {
      height: 40px;
      border: 1px solid #EDEFF5;
    }
    .keyword-type {
      width: 165px !important;
      border-radius: initial !important;
      .vs__dropdown-toggle {
        cursor: pointer;
      }
      .vs__actions {
        .vs__clear {
          display: none;
        }
      }
    }
  }

  .header-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F3F4;
    font-size: 14px;

    .actions {
      display: flex;
      grid-gap: 20px;
      padding: 10px;

      .tab-name {
        font-weight: 500;
        color: #898989;
        position: relative;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }

        .tab-active {
          color: #1200ff;
          margin-left: 15px;

          &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            top: 50%;
            left: 0;
            background: #1200ff;
            transform: translateY(-50%);
          }
        }
      }
    }

    .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem;
      align-items: center;
      grid-gap: 10px;

      .total-records-pagination {
        font-size: 13px;

        .total {
          color: #000;
          font-weight: 500;
        }
      }

      .md-menu {
        align-items: center;
        display: flex;
      }

      .md-button {
        background-color: unset !important;
        color: #000 !important;
        height: max-content;
        box-shadow: unset;

        .md-ripple {
          padding: 0 !important;

          .md-button-content {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .ve-table-layout {
    .ve-table-container {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #8888887a;
        border-radius: 5px;

        &:hover {
          background: #555;
        }
      }
    }

    .ve-table-body {
      .address {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .money {
        font-weight: 500;
      }

      .employee-avatar {
        .md-avatar {
          border-radius: 100%;
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          min-width: 30px;
          background: #f3f3f3;
          margin-right: 5px;
          z-index: 1;
        }
      }

      .number-employee {
        color: #1fb800;
        font-weight: 500;
      }

      .status-employee {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        margin: auto;
      }

      .non-active {
        border: 1px solid #1fb800;
        background: #1fb8001a;
      }

      .active {
        background: #1fb800;
      }
    }
  }
}
</style>
