// import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
import ContentLayout from "@/pages/Layout/ContentLayout.vue";

// Components pages


//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

// // Dashboard pages
// // Profile
import UserProfile from "@/pages/Dashboard/Examples/UserProfile.vue";
// // User Management
import ListUserPage from "@/pages/Dashboard/Examples/UserManagement/ListUserPage.vue";

//Auth
import Login from "@/pages/Dashboard/Pages/Login.vue";

// Workplace Pages
// import NewFeeds from "@/pages/Workplace/Pages/NewFeeds.vue";
import RequestApproval from "@/pages/Workplace/Pages/RequestApproval.vue";
import ManagementSale from "@/pages/Workplace/Pages/ManagementSale/ManagementSale.vue";
import Employees from "@/pages/Workplace/Pages/Employees.vue";
import Setting from "@/pages/Workplace/Pages/Settings/Setting.vue";
// import Category from "@/pages/Workplace/Pages/Category.vue";
// import News from "@/pages/Workplace/Pages/News.vue";
// Admin Pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
import TimeSheet from "@/pages/Workplace/Pages/TimeSheet";
import WorkSchedule from "@/pages/Workplace/Pages/WorkSchedule/WorkSchedule";
import Holiday from "@/pages/Workplace/Pages/Holiday";
import UpdateWorkSchedule from "@/pages/Workplace/Pages/WorkSchedule/UpdateWorkSchedule";
import ManagerRateContent from "@/pages/Workplace/Pages/ManagementRate/ManagerRateContent";
import ShopManagement from "@/pages/Workplace/Pages/ShopManagement/ShopManagement";

// TableList pages
// import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";

let componentsMenu = {
  // path: "/components",
  // component: DashboardLayout,
  // redirect: "/components/notification",
  // name: "Components",
  // children: [
  //   {
  //     path: "table",
  //     name: "Table",
  //     components: { default: RegularTables },
  //     meta: { middleware: auth }
  //   },
  //   {
  //     path: "typography",
  //     name: "Typography",
  //     components: { default: Typography },
  //     meta: { middleware: auth }
  //   },
  //   {
  //     path: "icons",
  //     name: "Icons",
  //     components: { default: Icons },
  //     meta: { middleware: auth }
  //   },
  //   {
  //     path: "notifications",
  //     name: "Notifications",
  //     components: { default: Notifications },
  //     meta: { middleware: auth }
  //   },
  // ]
};
let examplesMenu = {
  path: "/examples",
  component: ContentLayout,
  name: "Examples",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: {default: UserProfile},
      meta: {middleware: auth}
    },
    {
      path: "user-management/list-users",
      name: "List Users",
      components: {default: ListUserPage},
      meta: {middleware: auth}
    }
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {middleware: guest}
    },
  ]
};

let workplacePages = {
  path: "/",
  component: ContentLayout,
  meta: {middleware: auth},
  children: [
    {
      path: "yeu-cau-phe-duyet",
      name: "Yêu cầu & Phê duyệt",
      components: {default: RequestApproval},
      meta: {middleware: auth, tab: 'tab-work', id: 1}
    },
    {
      path: "quan-ly-thuong-doanh-so/:sale_tab",
      name: "Quản lý thưởng doanh số",
      components: {default: ManagementSale},
      meta: {middleware: auth, tab: 'tab-work', id: 2}
    },
    {
      path: "danh-sach-nhan-vien",
      name: "Danh sách nhân viên",
      components: {default: Employees},
      meta: {middleware: auth, tab: 'tab-work', id: 3}
    },
    {
      path: "bang-cong",
      name: "Bảng công",
      components: {default: TimeSheet},
      meta: {middleware: auth, tab: 'tab-work', id: 4}
    },
    {
      path: "bang-cong/:tab",
      name: "Bảng công",
      components: {default: TimeSheet},
      meta: {middleware: auth, tab: 'tab-work', id: 4}
    },
    {
      path: "cai-dat/chinh-sach-thuong",
      name: "Chính sách thưởng",
      components: {default: Setting},
      meta: {middleware: auth, tab: 'setting-bonus', id: 5}
    },
    {
      path: "cai-dat/khu-vuc-chuyen-mon",
      name: "Khu vực / chuyên môn",
      components: {default: Employees},
      meta: {middleware: auth, tab: 'setting-area', id: 6}
    },
    {
      path: "lich-lam-viec",
      name: "Lịch làm việc",
      components: {default: WorkSchedule},
      meta: {middleware: auth, tab: 'tab-work', id: 7}
    },
    {
      path: "holiday",
      name: "Ngày nghỉ lễ",
      components: {default: Holiday},
      meta: {middleware: auth, tab: 'tab-work', id: 8}
    },
    {
      path: "lich-lam-viec/cap-nhat",
      name: "Cập nhật lịch làm việc",
      components: {default: UpdateWorkSchedule},
      meta: {middleware: auth, tab: 'tab-work', id: 7}
    },
    {
      path: "quan-ly-ty-gia",
      name: "Quản lý tỷ giá",
      components: {default: ManagerRateContent},
      meta: {middleware: auth, tab: 'tab-work', id: 9}
    },
    {  
      path: "quan-ly-shop/:tab",
      name: "Quản lý shop",
      components: {default: ShopManagement},
      meta: {middleware: auth, tab: 'tab-work', id: 10}
    }
  ]
};

let adminPages = {
  path: "/dashboard",
  component: ContentLayout,
  meta: {middleware: auth},
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      components: {default: Dashboard},
      meta: {middleware: auth}
    }
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/quan-ly-thuong-doanh-so/tong-quan",
    name: "Quản lý thưởng doanh số",
    // components: {default: Dashboard},
    // meta: {middleware: auth}
  },
  authPages,
  workplacePages,
  adminPages,
];

export default routes;
