import axios from 'axios';
// import Api from '../config/Api';
import Cookies from 'js-cookie';
const domainBase = process.env.VUE_APP_API_BASE_URL + '/v1';
const domain = process.env.VUE_APP_API_BASE_URL_WORK + '/v1';
const domainLogin = process.env.VUE_APP_API_BASE_URL + '/v1';
const domainTimeSheet = process.env.VUE_APP_API_TIMESHEET_URL + '/v1';
const timeSheetManagementDomain = process.env.VUE_APP_API_BASE_URL_TIMESHEET_MANAGEMENT + '/v1';

export default class BaseService {
    /*constructor() {
        this.setAuth();
    }

    setAuth() {
        axios.interceptors.request.use(function (config) {
            console.log('axios.interceptors');
            const access_token = localStorage.getItem("access_token");
            const XsrfToken = localStorage.getItem("X_XSRF_TOKEN");

            if (access_token) {
                config.headers.Authorization = `Bearer ${access_token}`;
                config.headers.X_XSRF_TOKEN = XsrfToken;
            }

            return config;
        });
    }*/

    static async get(uri, params = {}, domainType = null) {
        try {
            return await axios.get(this.getUrl(uri, domainType), {params: params});
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async post(uri, params = {}, domainType = null) {
        try {
            return await axios.post(this.getUrl(uri, domainType), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async put(uri, params = {}, domainType = null) {
        try {
            return await axios.put(this.getUrl(uri, domainType), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async patch(uri, params = {}, domainType = null) {
        try {
            return await axios.patch(this.getUrl(uri, domainType), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async show(uri, domainType = null) {
        try {
            return await axios.get(this.getUrl(uri, domainType));
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async delete(uri, domainType = null) {
        try {
            return await axios.delete(this.getUrl(uri, domainType));
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static getUrl(uri, domainType) {
        if(uri === '/login'){
            return domainLogin + uri;
        } else if (domainType === 'timesheet-management') {
            return timeSheetManagementDomain + uri;
        } else if (domainType === 'aza-work') {
            return domain.replace('api/admin', 'api/aza-work') + uri;
        } else if(domainType === 'AZAGROUP-BANNER'){
            return domainLogin.replace("/v1", "") + uri;
        } else if(domainType === 'TIMESHEET'){
            return domainTimeSheet + uri;
        } else if (domainType === 'BASE') {
            return domainBase + uri;
        }
        return domain + uri;
    }

    static errorMsg(e) {
       
        if (!e.response && Cookies.get('access_token') && Cookies.get('user_id')) {
            throw e;
        }
        
        if (e.response.status === 401) {
            window.app.$store.dispatch("logout");
            return;
        } else if(e.response.status === 500){
            window.app.$store.dispatch("alerts/error", "Đã có lỗi xảy ra. Vui lòng thử lại!");
        }
        return {data: e.response.data};
    }
}