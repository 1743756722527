<template>
  <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }">
    <div class="text-center mt-20px">
      <slot name="image-logo"></slot>
    </div>
    <div class="heading-600 heading-text">AZA ADMIN</div>
    <md-card-content>
      <slot name="description"></slot>
      <slot name="inputs"></slot>
    </md-card-content>

    <md-card-actions>
      <slot name="footer"></slot>
    </md-card-actions>
  </md-card>
</template>

<script>
export default {
  name: "login-card",
  props: {
    headerColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      cardHidden: true
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  methods: {
    showCard: function() {
      this.cardHidden = false;
    },
    getClass: function(headerColor) {
      return "md-card-header-" + headerColor + "";
    }
  }
};
</script>

<style lang="css">
.heading-text {
  color: #e99b00;
  padding: 10px 0 15px;
}
</style>