<template>
  <div class="statistical-layout aza-table">
    <!--        <div class="statistical-header">-->
    <!--            <div class="statistical-title">-->
    <!--                <span>CHI TIẾT THƯỞNG CÁ NHÂN </span>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; <div class="statistical-see-detail">-->
    <!--                <a href="#">Xem chi tiết <md-icon>chevron_right</md-icon></a>-->
    <!--            </div> &ndash;&gt;-->
    <!--        </div>-->
    <ve-table border-y
              row-key-field-name="rowKey"
              :cellSelectionOption="cellSelectionOption"
              :cell-style-option="cellStyleOption"
              :columns="columns"
              :table-data="tableData"
              :max-height="maxHeight"
              :scroll-width="scrollWidth"
              :sort-option="sortOption"
              :cell-span-option="cellSpanOption"
    />
  </div>
</template>

<script>
import PaginationTemplate from "../../../components/PaginationTemplate";

export default {
  name: "StatisticalTable",
  components: {
    // PaginationTemplate
  },
  props: {
    maxHeight: {
      type: String,
      default: 'calc(100vh - 120px)'
    },
    tableData: {},
    columns: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 1
    },
    scrollWidth: {
      type: Number,
    },
    sortOption: {
      type: Object,
      default: () => {
      },
    },
    cellSpanOption: {
      type: Object,
      default: () => {
      },
    },
    cellStyleOption: {
      type: Object,
      default: () => {
        bodyCellClass: ({row, column, rowIndex}) => {
          if (column.field === "pecent_cpqc") {
            return "table-body-pecent-cpqc";
          }
        }
      }
    }
  },
  data() {
    return {
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
    };
  },
  mounted() {
  },
  created() {
  },
  methods: {}
}
</script>

<style lang="scss">
.statistical-layout {
  padding: 0 !important;

  .statistical-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;

    .statistical-title {
      font-weight: 600;
      text-transform: uppercase;
      color: rgba(16, 24, 64, 1);
    }

    .statistical-see-detail {
      a {
        color: rgba(23, 101, 252, 1) !important;
        font-size: 14px;
      }

      .md-icon {
        color: rgba(23, 101, 252, 1) !important;
        font-size: 18px !important;
      }
    }
  }

  .vue-table-root {
    .ve-table-header-tr {
      &:nth-child(2) {
        th {
          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            border-right: none !important;
          }

          border-right: 1px solid #eee !important;
          background-color: #fff !important;
          border-right: none;
        }
      }
    }

    .achieved-amout {
      color: rgba(92, 162, 34, 1);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.05000000074505806px;
      text-align: right;
    }

    .not-reached {
      color: rgba(16, 24, 64, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.05000000074505806px;
      text-align: right;
      text-decoration: line-through;
    }

    .total-header-revenue {
      color: rgb(255 0 0);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.05000000074505806px;
    }

    .total-daily-label {
      color: rgba(16, 24, 64, 1);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.05000000074505806px;
      text-align: right;

    }

    .duplicate-new-product {
      color: #ab3000;
      font-weight: 400;
    }

    .pecent-cpqc-lg {
      color: #d14343;
      height: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }

  .reward-name {
    /*word-break: break-all;*/
  }

  .total-reward {
    .reward-blue {
      color: rgba(36, 0, 255, 1);
      font-weight: 600;
    }

    .reward-red {
      color: rgba(255, 79, 79, 1);
      font-weight: 600;
      text-decoration: line-through;
    }
  }
}
</style>