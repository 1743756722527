import moment from 'moment';
moment.locale('vi');
import TimeSheetManagementService from "@/store/services/TimeSheetManagementService";
import {helpers} from "../helper/helpers";
import {
  EARLY_COUNT_PENALTY,
  LATE_COUNT_PENALTY, LEAVE_INVALID_COUNT_PENALTY, MISS_CHECKIN_COUNT_PENALTY,
} from "../pages/Workplace/Pages/Timekeeping/penalty";
import {LEAVE_TYPE} from "../const/const";

const icons = {
  dot: '/img/svg/dot.png',
  leaveValid: '/img/svg/dot2.png',
  full: '/img/svg/Check.png',
  leaveEarly: '/img/svg/leave&early.svg',
  leaveInvalid: '/img/svg/shiff_off.svg',
  holiday: '/img/svg/umbrella.png',
  missCheckout: '/img/svg/Check1.png',
};

export default {
  data() {
    return {
      currentDate: moment(),
      calendar: [],
      userId: null,
      employeeTimesheet : {
        holiday: [],
        user_leave: [],
        workdays: []
      },
      timesheetByUser: {},
      timekeep_total: {
        work_day: 0,
        late_early_day: 0,
        late: 0,
        early: 0,
        forgot_ckout: 0,
        forgot_ckin_out: 0,
        leave_accepted: 0,
        half_day: 0,
        leave_has_salary: 0,
        leave_without_pay: 0,
        total_holiday: 0,
        monthly_leave_days: 0
      },
      timekeep_rule: {
        late_total : { count: 0, shift: 0, money: 0, date : [] },
        late_30m : { count: 0, shift: 0, money: 0, date : [] },
        late_1h : { count: 0, shift: 0, money: 0, date : []},
        early_total : { count: 0, shift: 0, money: 0, date : [] },
        early_1h: { count: 0, shift: 0, money: 0, date : [] },
        miss_checkout : {count: 0, shift: 0, money: 0, date : [] },
        leave_invalid: { count: 0, pen_day: 0,  shift: 0, money: 0, date : [] },
      }
    };
  },
  computed: {
    currentMonth() {
      return this.currentDate.format('MMMM YYYY');
    },
    weekDays() {
      return moment.weekdays(true);
    },
    firstDayOfMonth: function () {
      return firstDay.weekday();
    }
  },
  mounted(){
      this.getEmployeeTimesheet();
  },
  methods: {
    async prevMonth() {
      this.currentDate = this.currentDate.subtract(1, 'month');
      await this.getEmployeeTimesheet();
    },
    async nextMonth() {
      let maxMonth = moment().subtract(1, 'month');
      if (maxMonth > this.currentDate) {
        this.currentDate = this.currentDate.add(1, 'month');
        await this.getEmployeeTimesheet();
      }
    },
    isToday(date) {
      return moment().isSame(date, 'day');
    },
    selectDate(date) {
      // Handle date selection here
    },
    generateCalendar() {
      // reset
      this.clearTimeKeepingTotal();

      this.timekeep_total.monthly_leave_days = this.employeeTimesheet?.schedule?.monthly_leave_days ?? 0;

      const daysInMonth = this.currentDate.daysInMonth();
      const firstDay = moment(this.currentDate).date(1).day();

      const lastDayMonthPrev = parseInt(moment(this.currentDate).subtract(1,'months').endOf('month').format('DD'));
      const startMonthByDay = moment(this.currentDate).startOf('month').day() - 1;

      const maxDay = 31;
      let calendar = [];
      let day = 1;
      let dayNextMonth = 1;
      let dayMonthPrev = lastDayMonthPrev - startMonthByDay + 1;
      let currentDataDate = moment(this.employeeTimesheet?.schedule?.ts_function?.day_point);

      for (let i = 1; i < 6; i++) {
        const week = [];

        for (let j = 1; j < 8; j++) {
          let data = {
            day: '',
            dayLabel: '',
            date: null,
            prevMonth: day <= firstDay,
            nextMonth: day > daysInMonth,
            work_day: null,
            is_sunday: false,
            holiday: null,
            s1_leave_valid: false,
            s2_leave_valid: false,
            s1_leave_valuable_vacation: false,
            s2_leave_valuable_vacation: false,
            early_valid: false,
            no_data: false,
            is_checkonline: false
          };

          if (i === 1 && j < firstDay) {
            data.dayLabel = dayMonthPrev + '/' + moment(this.currentDate).subtract(1,'months').format('MM');
            dayMonthPrev++;
          } else if (day <= daysInMonth) {
            let currentDate = moment(this.currentDate)
                .date(day)
                .hour(0)
                .minute(0)
                .second(0)
                .millisecond(0);
              let dayLabel = day <= maxDay ? currentDate.format('DD/MM') : '';
              // data.no_data = currentDataDate < data.date;
              // data.day = day;
              data.dayLabel = dayLabel;

            this.timesheetByUser.collect_work.find((item) => {
              if(currentDate.format('YYYY-MM-DD') === item.date){
                data = item;
                console.log(item.date, item.day_type, item.s1.icon_type, item.s2.icon_type);
                item.date = moment(item.date);
                data.class = this.setClassWork(item.day_type);
                data.s1.icon = this.setIcon(item.s1.icon_type);
                data.s2.icon = this.setIcon(item.s2.icon_type);
              }
            });
            day++;
          } else { // Tháng tiếp theo
            dayNextMonth = dayNextMonth < 10 ? '0' + dayNextMonth : dayNextMonth;
            data.dayLabel = `${dayNextMonth}/${moment(this.currentDate).add(1,'months').format('MM')}`;
            dayNextMonth++;
          }
          week.push(data);
        }
        calendar.push(week);
      }

      // this.sumUpPenalty();
      this.calendar = calendar;
    },

    getEmployeeTimesheet(month){
      TimeSheetManagementService.employeeTimesheet({
        month: this.currentDate.format("YYYY/MM"),
      },
      this.userId
      ).then(res => {
        this.employeeTimesheet = res;
        this.getTimesheetByUser();
      })
    },

    getTimesheetByUser(){
      TimeSheetManagementService.getTimesheetByUser({
        month: this.currentDate.format("YYYY/MM"),
      },
      this.userId
    ).then(res => {
      res = res.data;
      // this.calendar = res.collect_work;
      this.timesheetByUser = res;
      this.$nextTick(() => {
        this.timekeep_total = {
          work_day: res.final_work_data.total_workday,
          late_early_day: res.final_work_data.late_early_day,
          late: res.final_work_data.late_count,
          early: res.final_work_data.early_count,
          forgot_ckout: res.final_work_data.forgot_ckout,
          forgot_ckin_out: 0,
          leave_accepted: res.final_work_data.leave_accepted,
          half_day: res.final_work_data.half_day,
          leave_has_salary: res.final_work_data.leave_accepted,
          leave_without_pay: res.final_work_data.leave_without_pay,
          total_holiday: res.final_work_data.total_holiday,
          monthly_leave_days: res.final_work_data.monthly_leave_days
        }

        this.timekeep_rule = {
          late_total : { 
            count: res.final_work_data.late_count,
            shift: 0,
            money: 0,
            date : res.working_rule.late.days
          },
          late_30m : { count: res.final_work_data.late_30m, shift: 0, money: 0, date : res.working_rule.late['30m'] },
          late_1h : { count: res.final_work_data.late_60m, shift: 0, money: 0, date : res.working_rule.late['60m']},
          early_total : { count: res.final_work_data.early_count, shift: 0, money: 0, date : res.working_rule.late.days },
          early_1h: { count: res.final_work_data.early_60m, shift: 0, money: 0, date : res.working_rule.late['60m'] },
          miss_checkout : {count: res.working_rule.miss_checkout.count, shift: 0, money: 0, date : res.working_rule.days },
          leave_invalid: { count: res.working_rule.leave_invalid.count, pen_day: res.working_rule.leave_invalid.pen_day, shift: 0, money: 0, date : res.working_rule.leave_invalid.days },
        }
        this.sumUpPenalty();
      });

      this.generateCalendar();
    })
    },

    clearTimeKeepingTotal(){
      this.timekeep_total = {
        work_day: 0,
        late_early_day: 0,
        late: 0,
        early: 0,
        forgot_ckout: 0,
        forgot_ckin_out: 0,
        leave_accepted: 0,
        half_day: 0,
        leave_has_salary: 0,
        leave_without_pay: 0,
        total_holiday: 0
      };
      this.timekeep_rule = {
        late_total : { count: 0, shift: 0, money: 0, date : [] },
        late_30m : { count: 0, shift: 0, money: 0, date : [] },
        late_1h : { count: 0, shift: 0, money: 0, date : []},
        early_total : { count: 0, shift: 0, money: 0, date : [] },
        early_1h: { count: 0, shift: 0, money: 0, date : [] },
        leave_invalid: { count: 0, pen_day: 0, shift: 0, money: 0, date : [] },
        miss_checkout : {count: 0, shift: 0, money: 0, date : [] }
      };
    },

    calcWorkByTimeRule(key, value, day, isEarlyAccepted){
      // đi muộn
      if (key === 'late') {
        this.timekeep_total.late++;
        this.timekeep_rule.late_total.count++;
        this.timekeep_rule.late_total.date.push(day);
        // đi muộn từ 1 tiếng
        if (value > 60) {
          this.timekeep_rule.late_1h.count++;
          this.timekeep_rule.late_1h.shift += 1;
          this.timekeep_rule.late_1h.date.push(day);
        }

        // muộn sau 30p phạt nửa công
        if (value >= 30 && value < 60) {
          this.timekeep_rule.late_30m.count++;
          this.timekeep_rule.late_30m.shift += 0.5;
          this.timekeep_rule.late_30m.date.push(day);
        }
      }

      if(key === 'early'){
        this.timekeep_total.early++;
        this.timekeep_rule.early_total.count++;
        this.timekeep_rule.early_total.date.push(day);
        // về sớm hơn 60p
        if (!isEarlyAccepted || value > 60){
          this.timekeep_rule.early_1h.money += 100000;
          this.timekeep_rule.early_1h.count++;
          this.timekeep_rule.early_1h.date.push(day);
        }
      }

      if (key === 'miss') {
        this.timekeep_rule.miss_checkout.count++;
        this.timekeep_rule.miss_checkout.date.push(day);
      }

      if (key === 'leave_invalid') {
        this.timekeep_total.leave_without_pay++;
        this.timekeep_rule.leave_invalid.count ++;
        this.timekeep_rule.leave_invalid.pen_day += value;
        this.timekeep_rule.leave_invalid.date.push(day);
      }

    },

    sumUpPenalty() {
      this.timekeep_rule.early_total.shift = EARLY_COUNT_PENALTY[this.timekeep_rule.early_total.count][0];
      this.timekeep_rule.early_total.money = EARLY_COUNT_PENALTY[this.timekeep_rule.early_total.count][1];
      this.timekeep_rule.late_total.shift = LATE_COUNT_PENALTY[this.timekeep_rule.late_total.count][0];
      this.timekeep_rule.late_total.money = LATE_COUNT_PENALTY[this.timekeep_rule.late_total.count][1];
      this.timekeep_rule.miss_checkout.shift = MISS_CHECKIN_COUNT_PENALTY[this.timekeep_rule.miss_checkout.count][0];
      this.timekeep_rule.miss_checkout.money = MISS_CHECKIN_COUNT_PENALTY[this.timekeep_rule.miss_checkout.count][1];
      this.timekeep_rule.leave_invalid.shift = this.timekeep_rule.leave_invalid.pen_day * 2;
      this.timekeep_rule.leave_invalid.money = LEAVE_INVALID_COUNT_PENALTY[this.timekeep_rule.leave_invalid.count][1];
    },

    formatCurrency(amount){
      return helpers.formatCurrency(amount);
    },

    showDetailTimekeeping() {
      this.sumUpPenalty()
      this.$modal.show('show-detail-timekeeping-modal', {
        data: this.timekeep_rule
      });
    },

    finalWorkInMonth(){
      // return this.totalWorkInMonth() - this.minusShiftInMonth();
      return this.timesheetByUser?.final_work_data?.final_work_in_month ?? 0;
    },

    totalWorkInMonth(){
      return this.timesheetByUser?.final_work_data?.total_work ?? 0;
    },

    minusShiftInMonth(){
      return this.timesheetByUser?.final_work_data?.minus_in_month ?? 0;
    },

    minusMoneyInMonth(){
      return helpers.formatCurrency(this.timesheetByUser?.final_work_data?.money_penalty ?? 0);
    },

    setClassWork(type){
      return {
        1: 'calendar-full-work',
        2: 'calendar-day-leave',//calendar-early',
        3: 'calendar-miss-checkin',
        4: 'calendar-miss-checkin',
        5: 'calendar-leave-without-pay',
        6: 'calendar-half-day',
        7: 'calendar-holiday',
      }[type] ?? '';
    },

    setIcon(type){
      return {
        1: icons.full,
        2: icons.leaveEarly,
        3: icons.missCheckout,
        4: icons.leaveInvalid,
        5: icons.leaveValid,
        6: icons.holiday,
      }[type] ?? '';
    }
  }
};