<template>
    <div class="panel-detail-user">
      <template v-if="tab === 1">
        <div class="timesheet-detail-member">
          <div class="md-layout mb-20px">
            <div class="md-layout-item contact-infomation">
              <md-avatar>
                  <img :src="v_data.avatar ? v_data.avatar : '/img/default-avatar.png'" alt="avatar">
              </md-avatar>
              <div class="">
                <div class="text-500-semibold text-center">
                  {{ v_data.fullname }}
                </div>
                <div class="text-300-default text-center">
                  {{ v_data.role.display_name }}
                </div>
              </div>
              <div class="position neutral_2">
                <div class="d-flex-center-start">
                  <span class="material-icons">schema</span>
                  <div class="text-300-default">
                    {{ v_data.team.team }}
                  </div>
                </div>
                <div class="d-flex-center-start" v-if="v_data.team.agency || v_data.team.department">
                  <span class="material-icons">group</span>
                  <div class="text-300-default">
                    {{ v_data.team.agency }} > {{ v_data.team.department }}
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-65 work-infomation">
              <div class="md-layout work-count">
                  <div class="md-layout-item">
                    <div class="layout-money income">
                      <div class="title-money">
                          <span class="text-1-line">Ngày công tính lương</span>
                      </div>
                      <div class="total-money">
                        <span>{{ finalWorkInMonth() }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="md-layout-item">
                    <div class="layout-money income">
                      <div class="title-money">
                          <span>Tổng công</span>
                      </div>
                      <div class="total-money">
                          <span>{{ totalWorkInMonth() }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="md-layout-item">
                    <div class="layout-money income">
                      <div class="title-money">
                          <span>Phạt công</span>
                      </div>
                      <div class="total-money">
                          <span>{{ minusShiftInMonth() }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="md-layout-item">
                    <div class="layout-money income">
                      <div class="title-money">
                          <span>Khấu trừ</span>
                      </div>
                      <div class="total-money">
                          <span>{{ minusMoneyInMonth() }}</span>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="detail-calculation"> 
                <div class="text-400-semibold mb-10px header-detail-timesheet">
                  <div class="header-label m-0px">
                    CHI TIẾT TÍNH CÔNG
                  </div>
                  <div class="button-create-final-timesheet" @click="showConfirmFinalTimesheet">
                    <span>Chốt công</span>
                  </div>
                </div>
                <div class="calc-list-work">
                  <div class="work-item-total">
                    <div class="item-title">Đủ công</div>
                    <div class="item-total">{{ timekeep_total.work_day}}</div>
                  </div>
                  <div class="work-item-total">
                    <div class="item-title">Nửa công</div>
                    <div class="item-total">{{ timekeep_total.half_day }}</div>
                  </div>
                  <div class="work-item-total">
                    <div class="item-title">Nghỉ lễ</div>
                    <div class="item-total">{{ timekeep_total.total_holiday }}</div>
                  </div>
                  <div class="work-item-total">
                    <div class="item-title">Phép tháng</div>
                    <div class="item-total">{{ timekeep_total.monthly_leave_days }}</div>
                  </div>
                  <div class="work-item-total">
                    <div class="item-title">Nghỉ có công</div>
                    <div class="item-total">{{ timekeep_total.leave_has_salary }}</div>
                  </div>
                  <div class="work-item-total">
                    <div class="item-title">Quên chấm công</div>
                    <div class="item-total money">{{ timekeep_total.forgot_ckout }}</div>
                  </div>
                  <div class="work-item-total">
                    <div class="item-title">Nghỉ phép</div>
                    <div class="item-total money">{{ timekeep_total.leave_accepted }}</div>
                  </div>
                  <div class="work-item-total">
                    <div class="item-title">Nghỉ không phép</div>
                    <div class="item-total money">{{ timekeep_total.leave_without_pay }}</div>
                  </div>
                  <div class="work-item-total">
                    <div class="item-title">Đi muộn</div>
                    <div class="item-total money">{{ timekeep_total.late }}</div>
                  </div>
                  <div class="work-item-total">
                    <div class="item-title">Về sớm</div>
                    <div class="item-total money">{{ timekeep_total.early }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="work-detail-late-early mb-20px">
            <!-- label -->
            <div class="text-400-semibold mb-10px">
              CHI TIẾT KHẤU TRỪ
            </div>
            <div class="md-layout">
              <!-- Chi tiết đi muộn / về sơm -->
              <div class="md-layout-item pd-0px">
                <TableEasy 
                  :columns="columsDetailLateEarly"
                  :tableData="tableDataLate"
                  :scrollWidth="0"
                  :borderAround="false"
                  :borderX="true"
                  :borderY="false"
                  />
              </div>
            </div>
          </div>
          <div class="application-leave">
            <div class="d-flex-center-between mb-10px">
              <div class="text-400-semibold">
                DANH SÁCH ĐỀ XUẤT
              </div>
              <div class="filter-header-table">
                <Dropdown v-model="statusDropDown" @change="changeLeaveStatus" :options="leaveStatus" optionLabel="name" optionValue="code" />
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item pd-0px">
                <!-- <time-off-content /> -->
                <!-- <TableEasy 
                  :columns="columsDetailLeave"
                  :tableData="tableDataApplicationLeave"
                  :scrollWidth="0"
                  :borderAround="false"
                  :borderX="true"
                  :borderY="false"
                  /> -->
                  <div class="main-layout">
                      <div class="table-wrapper">
                        <table class="time-off__table">
                          <thead class="">
                          <tr>
                            <td class = "stt">
                              <span> #</span>
                            </td>
                            <td class = "">
                              <span>Tiêu đề</span>
                            </td>
                            <td class = "" style="width: 180px">
                              <span>Loại đề xuất</span>
                            </td>
                            <td class = "" style="width: 120px">
                              <span>Ngày tạo phiếu</span>
                            </td>
                            <td class = "employee" style="width: 150px">
                              <span>Người đề xuất</span>
                            </td>
                            <td class = "right" style="text-align: right; min-width: 250px">
                              <span>Thời gian</span>
                            </td>
                            <td class = "" style="width: 100px">
                              <span class=""> Trạng thái</span>
                            </td>
                            <td class = "">
                              <span class="">Mô tả phê duyệt</span>
                            </td>
                          </tr>
                          </thead>
                          <tbody>
                          <tr class="" v-for="(item, index) in tableDataApplicationLeave" :key="'leave-request-' + item.id"  @click="openLeaveRequestDetail(item, $event)">
                            <td class="stt">{{++index }}</td>
                            <td class="">
                              <div class="">
                                <div class="text-400-medium mb-2px hover-link" >{{ item.name }}</div>
                                <div class="text-300-default neutral_2" >{{ item.reason }}</div>
                              </div>
                            </td>
                            <td class="">
                              <div class="">{{ item.ts_leave_category.name }}</div>
                            </td>
                            <td>
                              <div class="">{{ formatMoment(item.created_at, 'HH:mm') }}</div>
                              <div class="">{{ formatMoment(item.created_at, 'DD/MM/YYYY') }}</div>
                            </td>
                            <td class="">
                              <div class="employee">
                                <img class="avatar" :src="item.created_by.avatar">
                                <div class="user-text">
                                  <div class="text-400-medium">{{ item.created_by.fullname }}</div>
                                  <div class="text-300-default neutral_2">{{ item.created_by.role.display_name }}</div>
                                </div>
                              </div>
                            </td>
                            <td class="right datetime-show">
                              <template v-if="statusDropDown === LEAVE_TYPE.LEAVE_EARLY || item.type === LEAVE_TYPE.LEAVE_EARLY">
                                <div class="text-400-medium">{{ formatMoment(item.request_data.time, 'HH:mm') }}</div>
                                <div class="text-400-medium">{{ formatMoment(item.request_data.time, 'dddd, DD/MM/YYYY') }}</div>
                              </template>
                              <template v-else-if="statusDropDown === LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE || item.type === LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE">
                              </template>
                              <template v-else>
                                <div class="mb-2px">Từ <span class="text-400-medium">{{ renderRequestDateTitle(item.request_data.shift_from, item.request_data.date_from) }}</span></div>
                                <div class="">đến <span class="text-400-medium">{{ renderRequestDateTitle(item.request_data.shift_to, item.request_data.date_to) }}</span></div>
                              </template>
                            </td>
                            <td class="">
                              <div class="badge" :class="'status-background-' + item.status" style="width: 100%;">{{ getStatusName(item.status) }} </div>
                            </td>
                            <td style="max-width: 300px">
                              <div style="color: red">{{ getApproveDescription(item.ts_user_leave_decisions) }}</div>
                            </td>
                          </tr>
                          </tbody>

                        </table>
                      </div>
                      <div class="w_100 mt-15px">
                        <pagination-template :params="pagingParams" @change-params="changePagingParam" />
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <TimeKeepingContent 
          :calendar="calendar" 
          :currentDate="v_data.current_date" 
          :timekeep_total="timekeep_total" 
          :timekeep_rule="timekeep_rule"
          :timesheetByUser="timesheetByUser"
          :user="v_data"
        />
      </template>
    </div>
  </template>
  
  <script>
  import { helpers } from '../../../../helper/helpers';
  import TimeSheetManagementService from '../../../../store/services/TimeSheetManagementService';
  import TableEasy from '../TableEasy.vue';
  import Dropdown from 'primevue/dropdown';
  import calculationWork from "@/mixins/calculation-work"
  import TimeKeepingContent from "../Timekeeping/TimeKeepingContent"
  import PaginationTemplate from "@/components/PaginationTemplate";
  import moment from 'moment';
  import {LEAVE_TYPE} from "../../../../const/const";

  export default {
    mixins: [calculationWork],
    components: {
      TableEasy,
      Dropdown,
      TimeKeepingContent,
      PaginationTemplate
    },
    props:{
      v_data: {},
      tab: null
    },
    data() {
      return {
        tableDataLate: [],
        tableDataApplicationLeave: [],
        columsDetailLateEarly: [
          { field: "label", key: "a", title: "Thời gian", align: "left"},
          { field: "count",  key: "b", title: "Số lần", align: "right", width: 100},
          { field: "shift",  key: "c", title: "Phạt công", align: "right", width: 100},
          { field: "money",  key: "d", title: "Khấu trừ", align: "right", width: 100},
          { field: "date",  key: "e", title: "Ngày phát sinh", align: "right", width: 150,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                      <div class="date">
                        {row.date}
                      </div>
                  );
              }
        },
        ],
        columsDetailLeave: [
          { field: "stt", key: "a", title: "STT", align: "center", width: 50,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                return ++rowIndex;
            }
          },
          { field: "date_from", key: "b", title: "Thời gian bắt đầu", align: "left",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                  return (
                    <div class="date-from-leave">
                      { this.renderRequestDateTitle(row.shift_from, row.date_from) }
                    </div>
                  );
              }
          },
          { field: "date_to", key: "c", title: "Thời gian kết thúc", align: "left",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="date-from-leave">
                    { this.renderRequestDateTitle(row.shift_to, row.date_to) }
                  </div>
                );
            }
          },
          { field: "count", key: "d", title: "Số công", align: "right", width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                return 2;
            }
          },
          { field: "has_salary", key: "e", title: "Tính lương", align: "center", width: 110,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                let label = 'Không';
                let className = 'leave-without-salary';
                if(row.ts_leave_category.have_salary === 1){
                  label = 'Có';
                  className = 'leave-has-salary';
                }
                return (
                  <div class={className}>
                    {label}
                  </div>
                );
            }
          },
          { field: "type_leave", key: "f", title: "Loại phép", align: "center",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    { row.ts_leave_category.name }
                  </div>
                );
            }
          },
          { field: "status", key: "g", title: "Trạng thái", align: "center",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              const leave = {
                  1: {label: 'Đã duyệt', class: 'status status-success'},
                  2: {label: 'Đã từ chối', class: 'status status-error'},
                  3: {label: 'Chờ duyệt', class: 'status status-default'},
                  4: {label: 'Chờ điều chỉnh', class: 'status status-default'},
                }[row.status];

                return (
                  <div class={leave.class}>
                    { leave.label }
                  </div>
                );
            }
          },
        ],
        leaveStatus: [
          {name: 'Đã duyệt', code: '1'},
          {name: 'Đã  từ chối', code: '2'},
          {name: 'Chờ duyệt', code: '3'},
          {name: 'Chờ điều chỉnh', code: '4'},
        ],
        statusDropDown: '1',
        paramLeave: {
          status: 1,
          decision_mode: 3, // 1: all, 2: request gửi đến, 3: request của mình tạo,
          user_id: this.userId,
        },
        pagingParams: {
          page: 1,
          perPage: 50,
          lastPage: 1,
          total: 0,
        },
        LEAVE_TYPE: LEAVE_TYPE,
      };
    },
    created(){
      // set Data cho mixins chấm công
      this.userId = this.v_data.id;
      this.currentDate = this.v_data.current_date;
      this.getLeaves();
      this.updateDetailWork();
    },
    watch: {
      'calendar'(value){
        this.updateDetailWork();
      },
      'tab'(value){
        // console.log('tab', value);
      }
    },
    methods: {
      updateDetailWork(){
        let labels = {
          late_total: 'Số lần đi muộn',
          late_30m: 'Đi muộn > 30p',
          late_1h: 'Đi muộn > 60p',
          early_total: 'Số lần về sớm',
          early_1h: 'Về sớm > 60p hoặc không xin phép',
          miss_checkout: 'Quên chấm công',
          leave_invalid: 'Nghỉ không phép',
        };
        this.tableDataLate = [];
        this.$nextTick(() => {
          for (const key in this.timekeep_rule) {
            let element = this.timekeep_rule[key];
            // clone để dữ liệu gốc được nguyên vẹn không bị thay đổi
            let el_clone = {
              ...element,
              label: labels[key],
              money: helpers.formatCurrency(element.money)
            }
            this.tableDataLate.push(el_clone)
          }
        });
      },
      getLeaves(refreshPaging = false){
        if (refreshPaging) {
          this.refreshPagingParams();
        }
        this.paramLeave.user_id = this.v_data.id;
        let paging = {
          page: this.pagingParams.page,
          per_page: this.pagingParams.perPage
        };
        TimeSheetManagementService.getLeaves({...this.paramLeave, ...paging}).then(res => {
        if (res && res.data) {
          this.tableDataApplicationLeave = res.data;
          this.pagingParams.perPage = res.meta.per_page;
          this.pagingParams.total = res.meta.total;
        }
      });
      },

      renderRequestDateTitle(shift, date) {
        if(!date){
          return '-----';
        }

        const [year, month, day] = date.split('-');
        const mDate = new Date(year, month - 1, day);
        const options = {
          weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'
        };
        let dateStr = mDate.toLocaleDateString('vi-VN', options);
        return `Ca ${shift}, ${dateStr}`;
      },

      changeLeaveStatus(e){
        this.paramLeave.status = this.statusDropDown
        this.getLeaves(true)
      },

      showConfirmFinalTimesheet() {
            this.$modal.show('dialog', {
                title: 'Chốt Công',
                text: 'Bạn có chắc muốn chốt công làm việc của nhân viên?',
                buttons: [
                    {
                        title: 'Không',
                        handler: () => {
                            this.$modal.hide('dialog')
                        }
                    },
                    {
                        title: 'Đồng ý',
                        handler: async () => {
                          let params = {
                            user_ids: [this.v_data.id],
                            year_month: this.v_data.current_date.format('YYYY-MM')
                          }

                          await TimeSheetManagementService.finalizeWorkTime(params).then(res => {
                            if (res && res.status) {
                              this.$modal.hide('dialog');
                            } else {
                              this.$store.dispatch(
                                "alerts/error",
                                "Có lỗi xảy ra vui lòng thử lại."
                              );
                            }
                          });
                        }
                    }
                ]
            })
        },

      changePagingParam(value) {
        this.pagingParams = value;
        this.getLeaves();
      },
      formatMoment(valueFormat, format = 'YYYY-MM-DD'){
        moment.locale('vi');
        return moment(valueFormat).format(format);
      },
      getStatusName(status) {
        let name = '';
        switch (status) {
          case 1:
            name = 'Đã duyệt';
            break;
          case 2:
              name = 'Đã từ chối';
              break
          case 3:
            name = 'Chờ duyệt';
            break
          case 4:
            name = 'Chờ điều chỉnh';
            break
        }
        return name;
      },
      getApproveDescription(decisions) {
        let decision = decisions.find((item) => (item.status === 1 || item.status === 2) && item.description);
        return decision ? `[${decision.user.fullname}] ${decision.description}` : '';
      },
    }
  };
  </script>
<style lang="scss">
  .timesheet-detail-member{
    .header-detail-timesheet{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button-create-final-timesheet {
        &:hover{
          cursor: pointer;
        }
        font-weight: 600;
        border: 1px solid rgb(211, 211, 211);
        padding: 6px 15px;
        border-radius: 4px;
      }
    }
    .p-dropdown-label{
      padding: 3px 10px !important;
      font-size: 14px;
    }
    .contact-infomation{
      display: flex;
      padding: 20px 10px;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid #C2C2C2;
      background: #F5F5F5;
      max-width: 280px;
      .md-avatar{
        width: 100px;
        height: 100px;
        border-radius: 100px;
        border: 3px solid #FFF;
        background:  lightgray 50% / cover no-repeat;
        margin: 0 auto;
      }
      .position{
        width: 100%;
        > div {
          gap: 10px;
        }
        .material-icons {
          font-size: 14px;
        }
      }
    }
    .work-infomation{
      
      .md-layout{
        grid-gap: 10px;
      }
      .work-count{
        margin-bottom: 20px;
        .md-layout-item{
          height: 60px;
          padding: 6px 0;
          border-radius: 8px;
          border: 1px solid rgba(252, 175, 23, 1);
          background: #FFFDF2;
          width: 200px;
        }
      }
      
      .detail-calculation{
        .calc-list-work {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(3, 1fr);
          gap: 0px 20px;
          .work-item-total {
            border-bottom: 1px solid #D3D3D3;
            padding: 10px 0;
            text-align: center;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item-title{
              font-size: 14px;
            }
            .item-total{
              font-size: 14px;
              font-weight: 600;
              color: #376BFB;
              padding: 0 4px;
            }
            .money {
              color: red;
            }
          }
        }
      }
    }
  }
</style>
  