export default {
    data() {
        return {
            pagingParams: {
                page: 1,
                perPage: 50,
                lastPage: 1,
                total: 0,
            },
        };
    },
    methods: {
        refreshPagingParams() {
            this.pagingParams = {
                page: 1,
                perPage: 50,
                lastPage: 1,
                total: 0,
            };
        },
    }
};