<template>
  <div class="time-keeping-content-container">
    <div class="header-switch-date">
      <div class="header-right-layout">
        <div class="md-layout md-gutter md-alignment-space-between-center">
          <div class="md-layout-item">
            <div class="layout-money income">
              <div class="title-money">
                <span class="text-1-line">Ngày công tính lương</span>
              </div>
              <div class="total-money">
                <span>{{finalWorkInMonth()}}</span>
              </div>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="layout-money income">
              <div class="title-money">
                <span>Tổng công</span>
              </div>
              <div class="total-money">
                <span>{{ totalWorkInMonth() }}</span>
              </div>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="layout-money income">
              <div class="title-money">
                <span>Phạt công</span>
              </div>
              <div class="total-money">
                <span>{{minusShiftInMonth()}}</span>
              </div>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="layout-money income">
              <div class="title-money">
                <span>Khấu trừ</span>
              </div>
              <div class="total-money">
                <span>{{ minusMoneyInMonth() }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="md-layout md-gutter md-alignment-space-between-center revenue-timekeeping-layout">
      <div class="md-layout-item">
        <div class="layout-money income">
          <div class="title-money">
            <span>Thu nhập</span>
          </div>
          <div class="total-money">
            <span>17.700.000</span>
          </div>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="layout-money salary">
          <div class="title-money">
            <span>Lương</span>
          </div>
          <div class="total-money">
            <span>16.500.000</span>
          </div>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="layout-money total-reward">
          <div class="title-money">
            <span>Tổng thưởng</span>
          </div>
          <div class="total-money">
            <span>1.200.000</span>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="md-layout md-gutter md-layout-item md-size-100 md-alignment-space-between-space-between layout-index-container">
      <div class="md-layout-item">
        <div class="revenue-index__title">
          <span>Ngày công</span>
        </div>
        <div class="revenue-index__number paid">
          <span>{{ timekeep_total.work_day}}</span>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="revenue-index__title">
          <span>Nửa công</span>
        </div>
        <div class="revenue-index__number paid">
          <span>{{ timekeep_total.half_day }}</span>
        </div>
      </div>
      <div class="md-layout-item paid">
        <div class="revenue-index__title">
          <span>Nghỉ phép</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_total.leave_accepted }}</span>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="revenue-index__title">
          <span>Nghỉ không phép</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_total.leave_without_pay }}</span>
        </div>
      </div>
      <div class="md-layout-item paid">
        <div class="revenue-index__title">
          <span>Đi muộn</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_rule.late_total.count }}</span>
        </div>
      </div>
      <div class="md-layout-item paid">
        <div class="revenue-index__title">
          <span>Về sớm</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_rule.early_total.count }}</span>
        </div>
      </div>
      <div class="md-layout-item paid">
        <div class="revenue-index__title">
          <span>Quên chấm công</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_total.forgot_ckout}}</span>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="revenue-index__title">
          <span>Phép tháng</span>
        </div>
        <div class="revenue-index__number paid">
          <span>{{ timekeep_total.monthly_leave_days }}</span>
        </div>
      </div>
    </div>

    <!--  -->
    <!-- <p>Số ngày muộn {{ timekeep_rule.late_30m }}</p>
    <p>tổng số ngày muộn bị phạt thêm {{ timekeep_rule.late_30m }}</p> -->
    <!-- <p>Số ngày về sớm {{ timekeep_rule.early }}</p>
    <p>Số tiền bị trừ về sớm {{ formatCurrency(timekeep_rule.early_penalty_amount) }}đ</p> -->
    <!--  -->
    <div class="md-layout md-gutter label-container">
      <div class="md-layout-item label-container_left">
        <div class="label-title day-full-work">
          <span class="__box-icon"></span>
          <span class="__title">Ngày đủ công</span>
        </div>
        <div class="label-title day-leave">
          <span class="__box-icon"></span>
          <span class="__title">Nghỉ không phép</span>
        </div>
        <div class="label-title half-day">
          <span class="__box-icon"></span>
          <span class="__title">Ngày nửa công</span>
        </div>
      </div>
      <div class="md-layout-item label-container_right">
        <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/Check.png" alt="aza" />
                  </span>
          <span class="__title">Ca đủ công</span>
        </div>
        <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/leave&early.svg" alt="aza" />
                  </span>
          <span class="__title">Ca đi sớm / về muộn</span>
        </div>
        <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/shiff_off.svg" alt="aza" />
                  </span>
          <span class="__title">Ca nghỉ</span>
        </div>
        <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/umbrella.png" alt="aza" />
                  </span>
          <span class="__title">Ca nghỉ lễ</span>
        </div>
        <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/Check1.png" alt="aza" />
                  </span>
          <span class="__title">Ca quên chấm công</span>
        </div>
      </div>
    </div>
    <!-- Calendar -->
    <div class="container-calendar">
      <div class="container-calendar-table">
        <div class="week-days">
          <div v-for="day in weekDays" :key="day" class="day text-300-medium">{{ day }}</div>
        </div>
        <div class="calendar-grid">
          <div class="week" v-for="(week, weekIndex) in calendar" :key="weekIndex">
            <div v-for="(day, dayIndex) in week"
                 :key="dayIndex"
                 :class="{ 'today': isToday(day.date), 'prev-month': day.prevMonth, 'next-month': day.nextMonth }" class="day">
              <template v-if="day.is_sunday || day.date == null">
                <div class="empty-calendar text-300-medium">
                                  <span>
                                      {{ day.dayLabel }}
                                  </span>
                </div>
              </template>
              <template v-else>
                <div class="event-calendar cursor-pointer" :class="day.class" @click="showDetail(day)">
                  <div class="event-calendar-header d-flex-center-between">
                    <div class="event-header_day">
                      <template v-if="day.s1.is_checkin_online || day.s2.is_checkin_online"><md-icon class="check-online">wifi</md-icon></template>
                      {{ day.date.format('DD / MM') }}
                    </div>
                    <div class="event-header_time">
                      <template v-if="day.daily_timesheet.checkin_online_time || day.daily_timesheet.checkout_online_time">
                        {{ day.daily_timesheet.checkin_online_time ? day.daily_timesheet.checkin_online_time : '--:--' }}
                          -
                        {{ day.daily_timesheet.checkout_online_time ? day.daily_timesheet.checkout_online_time : '--:--' }}
                      </template>
                      <template v-else>
                        {{ day.daily_timesheet.checkin_time ? day.daily_timesheet.checkin_time : '--:--' }}
                          -
                        {{ day.daily_timesheet.checkout_time ? day.daily_timesheet.checkout_time : '--:--' }}
                      </template>
                    </div>
                  </div>
                  <div class="event-calendar-body d-flex-center-between">
                    <div class="event-body_left">
                      {{ day.day_point }}
                    </div>
                    <div class="event-body_right">
                      <div class="left_time" v-if="day.s1.start_time && day.s1.end_time">
                        <img :src="day.s1.icon" alt="aza" />
                        <span>
                            S: {{ day.s1.start_time + ' - ' + day.s1.end_time }}
                        </span>
                      </div>
                      <div class="right_time" v-if="day.s2.start_time && day.s2.end_time">
                        <img :src="day.s2.icon" alt="aza" />
                        <span>
                            C: {{ day.s2.start_time + ' - ' + day.s2.end_time }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="event-calendar-footer">
                    <span>
                        {{ day.title }}
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DetailWorkTimeModal @edit-worktime-modal="editDailyTimesheet" />
    <EditUserDailyTimesheetModal  @reload-data="reloadTimesheet($event)"/>
  </div>
</template>

  <script>
  // import calculationWork from "@/mixins/calculation-work";
  import moment from 'moment';
  import {helpers} from "../../../../helper/helpers";
  import DetailWorkTimeModal from '../../../Components/Modal/DetailWorkTimeModal';
  import EditUserDailyTimesheetModal from '../../../Components/Modal/EditUserDailyTimesheetModal';
  export default {
    name: 'time-keeping',
    // mixins: [calculationWork],
    components: {
      DetailWorkTimeModal,
      EditUserDailyTimesheetModal
    },
    props: {
      // user_id: null,
      // current_date: null,
      calendar: {},
      timekeep_total: {},
      timekeep_rule: {},
      timesheetByUser: {},
      currentDate: null,
      user: {},
    },
    data(){
      return {
        detail: {
          day: null,
          user: this.user
        }
      }
    },
    computed: {
      currentMonth() {
        return this.currentDate.format('MMMM YYYY');
      },
      weekDays() {
        return moment.weekdays(true);
      },
      firstDayOfMonth: function () {
        return firstDay.weekday();
      }
    },
    methods: {
      isToday(date) {
        return moment().isSame(date, 'day');
      },
      finalWorkInMonth(){
        return this.timesheetByUser?.final_work_data?.final_work_in_month ?? 0;
      },

      totalWorkInMonth(){
        return this.timesheetByUser?.final_work_data?.total_work ?? 0;
      },

      minusShiftInMonth(){
        return this.timesheetByUser?.final_work_data?.minus_in_month ?? 0;
      },

      minusMoneyInMonth(){
        return helpers.formatCurrency(this.timesheetByUser?.final_work_data?.money_penalty ?? 0);
      },

      showDetail(day){
        this.detail.day = day;
        this.$modal.show('detail-worktime-modal', {
          data: day,
          user: this.user
        });
      },
      
      editDailyTimesheet(){
        this.$modal.hide('detail-worktime-modal');
        this.$modal.show('edit-user-daily-timesheet-modal', this.detail);
      },

      reloadTimesheet(data){console.log(data);
        this.calendar.map((item) => {
          return item.map((day) => {
            if(day.date && day.date.isSame(data.date)){
              day.work_day = {
                  "id": day.id,
                  "date": data.date,
                  "checkin_time": data.checkin_time,
                  "checkout_time": data.checkout_time
              }
            }
            return day;
          })
        });
        this.$modal.hide('edit-user-daily-timesheet-modal');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .time-keeping-content-container {
    .header-switch-date {
      text-align: center;
      position: relative;
      width: 100%;
      padding: 0px 0px 10px;
      display: inline-block;
      .header-left-layout{
        float: left;
      }
      .header-right-layout{
        float: right;
        .md-layout{
          grid-gap: 10px;
          display: inline-flex;
        }
        .md-layout-item{
          height: 56px;
          padding: 6px 0;
          border-radius: 8px;
          border: 1px solid rgba(252, 175, 23, 1);
          background: #FFFDF2;
          width: 180px;
        }
      }
      .icon-switch-date .md-icon {
        &:hover {
          cursor: pointer;
        }
        color: #1f1f1f;
        height: 28px;
        width: 28px;
      }
      .timekeeping-penalty{
        &:hover{
          cursor: pointer;
        }
        font-size: 12px;
        font-weight: 500;
        color: rgba(55, 107, 251, 1);
        text-decoration: underline;
      }
    }
    //
    .revenue-timekeeping-layout {
      grid-gap: 15px;
      margin: 10px 0;
      display: none;
      .md-layout-item {
        min-height: 60px;
        padding: 0;
        max-width: 180px;
        border-radius: 8px;
        border: 1px solid #5975d9;
        background: rgba(89, 117, 217, 0.05);

        .layout-money {
          height: 60px;
          text-align: center;
          padding-top: 6px;
          color: #fff;
          border-radius: 8px;
          line-height: 35px;
        }

        &:after {
          width: 100%;
          height: 100%;
          display: block;
          background: md-get-palette-color(red, 200);
          content: " ";
        }
      }
    }
    .layout-index-container {
      border: 0.5px solid #c1c1c1;
      border-radius: 8px;
      max-height: 60px;
      text-align: center;
      min-height: max-content;
      .md-layout-item {
        min-height: 100%;
        margin: 2px 5px;
        padding: 0 !important;
        position: relative;
        &:not(:last-child):after {
          position: absolute;
          content: "";
          width: 0.5px;
          height: 40px;
          background: #d8dae5;
          top: 50%;
          right: -10px;
          transform: translateY(-50%);
        }
        .revenue-index__title {
          color: rgba(16, 24, 64, 1);
          font-size: 13px;
        }
        .revenue-index__number {
          color: rgb(219, 71, 71);
          font-size: 16px;
          font-weight: 600;
        }
        .paid {
          color: #c140d5 !important;
        }
      }
    }

    //
    .label-container {
      margin-top: 20px;
      margin-bottom: 20px;
      .md-layout-item {
        display: flex;
        padding: 2px 15px;
        justify-content: start;
        align-items: center;
        gap: 10px;
      }
      .label-container_left {
        .__box-icon {
          width: 12px;
          height: 12px;
        }
        .day-full-work {
          background: rgba(46, 136, 220, 0.1);
          .__box-icon {
            background: rgb(46, 136, 220);
          }
        }
        .day-leave {
          background: rgba(243, 83, 83, 0.1);
          .__box-icon {
            background: rgba(243, 83, 83, 1);
          }
        }
        .half-day {
          background: rgba(145, 84, 170, 0.1);
          .__box-icon {
            background: rgba(145, 84, 170, 1);
          }
        }
      }
      .label-container_right {
        .label-title {
          padding: 0px 5px;
        }
        .__box-icon img {
          width: 14px;
          height: 14px;
        }
      }
      .label-title {
        width: max-content;
        display: flex;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        .__title {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .container-calendar {
      width: 100%;
      text-align: center;
      .calendar-day {
        border-bottom: 0.5px solid #d8dae5;
        background: rgba(223, 230, 255, 0.33);
        display: flex;
        width: 100%;
        height: 31px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
      }
      .week-days {
        display: flex;
        justify-content: space-between;
        display: flex;
        width: 100%;
        height: 31px;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        grid-gap: 5px;
        margin-bottom: 8px;
        .day {
          flex: 1;
          text-align: center;
          border-bottom: 0.5px solid #d8dae5;
          background: rgba(223, 230, 255, 0.33);
        }
      }

      .calendar-grid {
        .empty-calendar {
          padding: 2px 5px;
        }
        .day {
          display: flex;
          width: 100%;
          align-items: start;
          padding: 0;
          min-height: 100px;
        }
        .event-calendar {
          width: 100%;
          height: 100%;
        }
        .event-calendar-header {
          padding: 0 6px;
          background: #9154aa;
          color: #fff;
          font-size: 12px;
          .event-header_day .md-icon {
            font-size: 14px !important;
            color: #8cff00;
            margin-right: 4px;
            width: auto;
            min-width: auto;
          }
        }
        .calendar-future {
          background: #f0f0f0;
          .event-calendar-header {
            background: #9c9c9c;
          }
        }
        .calendar-half-day {
          background: rgba(145, 84, 170, 0.05);
          .event-calendar-header {
            background: rgba(145, 84, 170, 1);
          }
          .event-body_right {
            border-left: 0.5px solid rgba(145, 84, 170, 0.40) !important;
          }
          .event-calendar-footer {
            border-top: 0.5px solid rgba(145, 84, 170, 0.40);
          }
        }
        .calendar-holiday,
        .calendar-full-work {
          background: #ebf4fc;
          .event-calendar-header {
            background: rgb(46, 136, 220);
          }
        }
        .calendar-shift-times {
          background: #fff5eb;
          .event-calendar-header {
            background: #f69336;
          }
        }
        .calendar-leave-without-pay {
          background: rgb(243 83 83 / 5%);
          .event-calendar-header {
            background: rgb(243 83 83 / 100%);
          }
          .event-body_right {
            border-left: 0.5px solid rgb(243 83 83 / 40%) !important;
          }
          .event-calendar-footer {
            border-top: 0.5px solid rgb(243 83 83 / 40%);
          }
        }
        .calendar-day-leave {
          background: #f0f0f0;
          .event-calendar-header {
            background: #9c9c9c;
          }
        }
        .calendar-early,
        .calendar-late {
          background: rgba(246, 147, 54, 0.05);
          .event-body_right {
            border-left: 0.5px solid rgba(246, 147, 54, 0.4) !important;
          }
          .event-calendar-footer {
            border-top: 0.5px solid rgba(246, 147, 54, 0.4);
          }
          .event-calendar-header {
            background: rgb(46, 136, 220);
          }
        }

        .calendar-miss-checkin {
          background: rgb(255 0 0 / 5%);
          .event-body_right {
            border-left: 0.5px solid rgb(255 0 0 / 10%) !important;
          }
          .event-calendar-footer {
            border-top: 0.5px solid rgb(255 0 0 / 10%);
          }
          .event-calendar-header {
            background: rgb(46, 136, 220);
          }
        }
        .event-calendar-body {
          .event-body_left {
            display: flex;
            width: 45px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 17px;
            font-weight: 500;
          }
          .event-body_right {
            font-size: 11px;
            padding: 0 0 0 6px;
            width: 100%;
            text-align: start;
            border-left: 0.5px solid rgba(46, 136, 220, 0.4);
            min-height: 48px;
            .left_time, .right_time {
              display: flex;
              gap: 4px;
              align-items: center;
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
        .event-calendar-footer {
          padding: 3px 5px;
          border-top: 0.5px solid rgba(46, 136, 220, 0.4);
          font-size: 11px;
          font-weight: 500;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .week {
        display: flex;
        justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 5px;
        margin: 5px 0;
      }

      .day {
        flex: 1;
        padding: 3px 5px;
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        text-transform: uppercase;
      }

      // .today {
      //   background-color: #007BFF;
      //   color: #fff;
      // }

      // .today:hover {
      //   background-color: #0056b3;
      // }

    }
  }
</style>
  