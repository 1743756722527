<template>
  <div class="management-sale-container">
    <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
    <div class="header-label">
      <div class="label">
        {{ labelPage }}
      </div>
      <!--  -->
      <div class="button-export">
        <div class="export-excel-btn" v-if="tabTeleport === headerTeleport.THEO_NHAN_VIEN" @click="downloadExcel">
          <md-icon>file_download</md-icon>
          <span>
          Xuất Excel
        </span>
        </div>
      </div>
    </div>
    <div class="filter-container">
      <div class="filter-sale-date-picker d-flex-center-between">
        <div class="filter">
          <div class="filter-mode-sale d-flex-center-between">
            <div class="estimated-sales filter-label" 
            :class="{ 'active-mode': tabActive === 1 }"
            @click="switchButton(1)">
              <span>Doanh số ước tính</span>
            </div>
            <div class="actual-sales filter-label" 
            :class="{ 'active-mode': tabActive === 2 }"
            @click="switchButton(2)">
              <span>Doanh số thực tế</span>
            </div>
          </div>
          <!-- filter -->
          <div class="d-flex-center-start">
            <FilterCondition @onLoadPage="onLoadPage" @teamIds="changeTeamIds" :notUse="['status']" class="mr-10px"></FilterCondition>
            <FilterCommon @filter="fitlerEmitCommon" :useForm="[2]"/>
          </div>
          <!-- filter -->
        </div>
        <div class="filter-datepicker d-flex-center-between">
          <div class="datepicker">
            <date-picker 
              class="datetime-picked" 
              placeholder="Select date range" 
              v-model="timeRange" 
              range-separator=" - " 
              format="DD/MM/YYYY"
              type="month"
              :clearable="false"
              :confirm="false"
              :confirm-text="'Xác nhận thời gian tìm kiếm.'"
              :lang="lang"
              @change="changeDate"
              :range="false"
            >
            <template v-slot:input>
              <input v-model="dateSelection" type="text" name="date" 
              readonly 
              autocomplete="off" 
              placeholder="Chọn thời gian" 
              class="mx-input" />
              </template>
              <template v-slot:icon-calendar>
                <md-icon>arrow_drop_down</md-icon>
              </template>
            </date-picker>
          </div>
          <div class="filter-button">
            <button class="btn filter-report" @click="createReport">
              Tạo báo cáo
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-container">
      <!-- Tab Tổng quan -->
      <template v-if="tabTeleport === headerTeleport.TONG_QUAN">
        <div class="layout-container">
          <!-- thu nhập / lương / Tổng thưởng -->
          <div class="md-layout md-alignment-space-between-center revenue-layout">
            <div class="md-layout-item">
              <div class="layout-money income">
                <div class="title-money">
                  <span>Tổng thưởng</span>
                </div>
                <div class="total-money">
                  <span>
                   {{ formatCurrency(detailAmount.total_day_reward_amount) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="md-layout-item">
              <div class="layout-money total-reward">
                <div class="title-money">
                  <span>Thưởng Daily Deals</span>
                </div>
                <div class="total-money">
                  <span>
                    {{ formatCurrency(detailAmount.daily_reward_amount) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="md-layout-item">
              <div class="layout-money salary">
                <div class="title-money">
                  <span>Thưởng sản phẩm mới</span>
                </div>
                <div class="total-money">
                  <span>{{ formatCurrency(detailAmount.product_reward_amount) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout">
            <div class="layout-left layout-item md-layout-item md-size-63 md-xsmall-size-100 md-medium-size-100 md-large-size-70" style="padding-left: 0">
              <div>
                <!-- chart -->
                <div class="md-layout chart-layout">
                  <LineChartData :chartData="lineChart.chartData" 
                  :chartOptions="lineChart.chartOptions" 
                  :styles="{ height: '250px' }"
                  />
                </div>
                <!--  -->
                <div class="statistical-header">
                  <div class="statistical-title">
                    <span>CHI TIẾT THƯỞNG THEO NGÀY </span>
                    <div class="export-excel-btn"
                         @click="exportToExcel">
                      <md-icon>file_download</md-icon>
                      <span>Xuất Excel</span>
                    </div>
                  </div>
                  <!-- <div class="statistical-see-detail">
                      <a href="#">Xem chi tiết <md-icon>chevron_right</md-icon></a>
                  </div> -->
                </div>
                <StatisticalTable :tableData="tableData" :columns="columnsTable" :max-height="'auto'" :tab="1" />
              </div>
            </div>
            <!-- Right Layout -->
            <div class="layout-right md-layout-item md-size-30  md-medium-size-40 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
              <!-- TOP THƯỞNG THEO NHÂN VIÊN -->
              <div class="layout-right-child top-rank-member">
                <div class="right-title d-flex-center-between">
                  <div class="head-text">TOP THƯỞNG THEO NHÂN VIÊN</div>
                  <div class="cursor-pointer" style="color: #376BFB; text-decoration: underline;" @click="$router.push(headerTeleport.THEO_NHAN_VIEN)">Chi tiết</div>
                </div>
                <div class="content-right">
                  <md-list class="md-double-line member-top-list">
                    <!-- <div class="loading-top-rank" v-if="!isLoadingRank && topRevenue.list.length === 0">
                    Không có dữ liệu
                  </div> -->
                    <md-list-item class="rank-top-list" v-for="(employee, index) in topMarketingEmployees" :key="index">
                      <md-avatar>
                        <img :src="employee.marketing.avatar ? employee.marketing.avatar : '/logo_128.png'" alt="achievements">
                      </md-avatar>
                      <div class="md-list-item-text">
                        <span class="text-400-medium mb-2px">{{ employee.marketing && employee.marketing.fullname ? employee.marketing.fullname : '' }}</span>
                        <span class="text-200-default neutral_2">{{ employee.marketing && employee.marketing.team.agency ? employee.marketing.team.agency : '' }}</span>
                        <span class="text-200-default neutral_2">{{ employee.marketing && employee.marketing.team.department ? employee.marketing.team.department : '' }}</span>
                        <span class="text-200-default neutral_2">{{ employee.marketing && employee.marketing.team.team ? employee.marketing.team.team : '' }}</span>
                      </div>
                      <div class="md-list-action">
                        <span class="member-rank-money">{{ formatCurrency(employee.reward_amount) }}</span>
                      </div>
                    </md-list-item>
                  </md-list>
                </div>
              </div>
              <!-- TOP THƯỞNG THEO SẢN PHẨM -->
              <div class="layout-right-child top-rank-member top-bonus-by-product" style="display: none;">
                <div class="right-title">
                  <div class="head-text">TOP THƯỞNG THEO SẢN PHẨM</div>
                </div>
                <div class="content-right">
                  <md-list class="md-double-line member-top-list">
                    <!-- <div class="loading-top-rank" v-if="!isLoadingRank && topRevenue.list.length === 0">
                    Không có dữ liệu
                  </div> -->
                    <md-list-item class="rank-top-list" v-for="(item, index) in [1, 2, 3, 4]" :key="index">
                      <md-avatar>
                        <img :src="'/logo_128.png'" alt="achievements">
                      </md-avatar>
                      <div class="md-list-item-text">
                        <span class="rank-member-title title-popular">NGUYỄN MINH TRUNG {{ item }}</span>
                        <span class="text-300-default neutral_2" style="margin-bottom: 2px">CHI NHÁNH AZA > PHÒNG MẶC Định</span>
                        <span class="text-300-default neutral_2">DOKU TEAM</span>
                      </div>
                      <div class="md-list-action">
                        <span class="member-rank-money">3.400.000 đ</span>
                      </div>
                    </md-list-item>
                  </md-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <!-- End - Tab Tổng quan -->
        <div class="statistical-header">
        </div>
        <div class="float-right">
          <pagination-template :class="'mb-10px float-right'" :params="pagingParams" @change-params="changePagingParam" />
        </div>
          <StatisticalTable
              :tableData="rewardByUserData"
              :columns="columnsTable"
              :tab="tabActive"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
              :cell-span-option="cellSpanOption" />
        <!-- =================Tab/Theo Nhân Viên/Theo Sản Phẩm================= -->
      </template>
    </div>
    <!-- teleport -->
    <Teleport to="#menu-card">
      <div class="menu-item" :class="{'menu-item-active': tabTeleport === headerTeleport.TONG_QUAN}" @click="$router.push(headerTeleport.TONG_QUAN)">Tổng quan</div>
      <div class="menu-item" :class="{'menu-item-active': tabTeleport === headerTeleport.THEO_NHAN_VIEN}" @click="$router.push(headerTeleport.THEO_NHAN_VIEN)">Theo nhân viên</div>
<!--      <div class="menu-item" :class="{'menu-item-active': tabTeleport === headerTeleport.THEO_SAN_PHAM}" @click="$router.push('quan-ly-thuong-doanh-so/' + headerTeleport.THEO_SAN_PHAM)">Theo sản phẩm</div>-->
    </Teleport>
    <!-- Panel Detail -->
    <PanelDetail 
      v-if="detailPanel.show" 
      @back-page="backPage"
      :data_detail="detailPanel.data" />
  </div>
</template>

<script>
import LineChartData from "../Chart/LineChartData"
import StatisticalTable from "../StatisticalTable"
import {helpers} from "../../../../helper/helpers";
import DatePicker from 'vue2-datepicker';
import moment from "moment";
import vue2DatePicker from "@/mixins/config-vue2-datepicker";
import FilterCondition from './FilterCondition'
import AdminService from "../../../../store/services/AdminService";
import PaginationTemplate from "../../../../components/PaginationTemplate";
import PanelDetail from "./PanelDetail";
import {SaleHeaderTeleport} from "../../../../const/const";
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery; // Assign to window object if needed
import TableToExcel from "@linways/table-to-excel";
import FilterCommon from "../FilterCommon.vue";

export default {
  components: {
    LineChartData,
    StatisticalTable,
    FilterCondition,
    DatePicker,
    PaginationTemplate,
    PanelDetail,
    FilterCommon,
  },
  mixins: [vue2DatePicker],
  data() {
    const date = new Date();
    return {
      json_fields: {
        'Ngày': 'name',
        'Tổng đơn': 'city',
        'Tổng thưởng': 'phone.mobile',
        'Thưởng Daily' : {},
        'Thưởng SP mới': ''
      },
      json_data: [
        {
          'name': 'Tony Peña',
          'city': 'New York',
          'country': 'United States',
          'birthdate': '1978-03-15',
          'phone': {
            'mobile': '1-541-754-3010',
            'landline': '(541) 754-3010'
          }
        },
        {
          'name': 'Thessaloniki',
          'city': 'Athens',
          'country': 'Greece',
          'birthdate': '1987-11-23',
          'phone': {
            'mobile': '+1 855 275 5071',
            'landline': '(2741) 2621-244'
          }
        }
      ],
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      search: null,
      searched: [],
      toogleList: [],
      radio: false,
      radio2: false,
      lineChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Tổng thưởng',
              data: [],
              borderColor: '#F765A3',
              backgroundColor: '#F765A3',
              borderWidth: 2,
              lineTension: 0.5,
              fill: 1,
              tension: 0.5,
              yAxisID: 'y',
            }
          ]
        },
        chartOptions: {
          legend: {
            display: true
          },
          plugins: {
            legend: {
              align : 'end',
              paddingBottom: 30
            },
            datalabels: {
              display: false, 
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
          stacked: false,
          responsive: true,
          maintainAspectRatio: false,
        },
      },
      switchTopRank: true,
      columnsTable: [
        {
          field: "date",
          key: "a",
          title: "Ngày",
          align: "center",
          children: [
            {
              field: "date",
              key: "aa",
              title: "",
              width: 100,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>{moment(row.date).format('DD/MM')}</div>
                );
              }
            }
          ],
        },
        {
          field: "number_order",
          key: "b",
          title: "Tổng đơn",
          align: "right",
          children: [
            {
              field: "number_order",
              key: "bb",
              title: "",
              align: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-revenue">
                    {helpers.formatCurrency(row.number_order ?? 0)}
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "daily_reward", key: "f", title: "Thưởng Daily", align: "right",
          children: [
            {
              field: "daily_reward",
              key: "ff",
              title: "",
              align: "right",
              renderHeaderCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-header-revenue">
                    {helpers.formatCurrency(20000)}
                  </div>
                );
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let classAmount = '';
                // if(row.daily_reward && row.daily_reward.pr_reward){
                //   if(row.daily_reward.pr_reward.type === 1){
                //     classAmount = 'reward-blue';
                //   } else {
                //     classAmount = 'reward-red';
                //   }
                // }

                let amount = 10000;
                // if(row.daily_reward && row.daily_reward.pr_reward){
                //   if(row.daily_reward.pr_reward.amount){
                //     amount = helpers.formatCurrency(row.daily_reward.pr_reward.amount);
                //   }

                //   if(row.daily_reward_selected){
                //     classAmount = 'achieved-amout';
                //   } else if(row.daily_reward.pr_reward.amount > 0) {
                //     classAmount = 'not-reached';
                //   }
                // }

                return (
                  <div class="total-reward">
                    <span class={classAmount}>
                      {amount}
                    </span>
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "product_reward", key: "g", title: "Thưởng SP mới", align: "right",
          children: [
            {
              field: "product_reward",
              key: "gg",
              align: "right",
              renderHeaderCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-header-revenue">
                     {helpers.formatCurrency(20000)}
                  </div>
                );
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let classAmount = '';

                let amount = 0;
                if(row.product_reward && row.product_reward.pr_reward){
                  if(row.product_reward.pr_reward.amount){
                    amount = helpers.formatCurrency(row.product_reward.pr_reward.amount);
                  }

                  if(row.product_reward.pr_reward.amount > 0 && row.product_reward_selected){
                    classAmount = 'achieved-amout';
                  } else if(row.product_reward.pr_reward.amount > 0) {
                    classAmount = 'not-reached';
                  }
                }

                return (
                  <div class="total-reward">
                    <span class={classAmount}>
                      {amount}
                    </span>
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "total", key: "g1", title: "Tổng thưởng", align: "right",
          children: [
            {
              field: "total",
              key: "gg1",
              align: "right",
              renderHeaderCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-header-revenue">
                     {helpers.formatCurrency(40000)}
                  </div>
                );
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let classAmount = '';

                let amount = 20000;
                // if(row.product_reward && row.product_reward.pr_reward){
                //   if(row.product_reward.pr_reward.amount){
                //     amount = helpers.formatCurrency(row.product_reward.pr_reward.amount);
                //   }

                //   if(row.product_reward.pr_reward.amount > 0 && row.product_reward_selected){
                //     classAmount = 'achieved-amout';
                //   } else if(row.product_reward.pr_reward.amount > 0) {
                //     classAmount = 'not-reached';
                //   }
                // }

                return (
                  <div class="money-reward">
                    <span class={classAmount}>
                      {amount}
                    </span>
                  </div>
                );
              }
            }
          ],
        },
      ],
      tabTeleport: SaleHeaderTeleport.TONG_QUAN,
      dateSelection: '',
      timeRange: [
        new Date(date.getFullYear(), date.getMonth(), 1),// ngày tháng đầu tiên của tháng
        new Date() // giờ hiện tại
      ],
      topMarketingEmployees: [],
      tableData: [],
      detailAmount: {
        daily_reward_amount: 0,
        product_reward_amount: 0,
        total_day_reward_amount: 0,
      },
      employeeRewardOverView: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        revenue_type: 1,
      },
      rewardByUserParams: {
          start_date: moment().startOf('month').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          revenue_type: 1,
          sort_type : "desc",
          sort_by : "total_reward_amount"
      },
      rewardByUserData: [],
      rewardByUserDataTotal: {
          "total_reward_amount": 0,
          "product_reward_amount": 0,
          "daily_reward_amount": 0,
          "revenue": 0,
          "ads_cost": 0,
          "ecommerce_cost": 0,
          "other_cost": 0,
      },
      tabActive: 1,
      isLoading: false,
      labelPage: 'Tổng quan',
      detailPanel: {
        show: false,
        data: null
      },
      headerTeleport: SaleHeaderTeleport,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      cellSpanOption: {
        bodyCellSpan: ({row, column, rowIndex}) => {
          if (rowIndex === 0) {
            if (column.field === "user_code") {
              return {
                colspan: 4,
                rowspan: 1,
              };
            } else if (column.field === "stt" || column.field === "fullname"
                || column.field === "agency") {
              return {
                colspan: 0,
                rowspan: 0,
              };
            }
          }
        }
      },
      cellStyleOption: {
        bodyCellClass: ({row, column, rowIndex}) => {
          if (column.field === "pecent_cpqc") {
            return "table-body-pecent-cpqc";
          } else if (rowIndex === 0) {
            return "tr-header-colum";
          }
        },
      },
      sortParams: {
        shouldSort: true,
        sortType: 'desc',
        sortBy: "total_reward_amount"
      }
    }
  },
  created () {
    // this.$store.dispatch("logout");
    this.dateSelection = moment().startOf('month').format('DD/MM/YYYY') + ' - ' + moment().format('DD/MM/YYYY');
  
  },
  watch: {
    '$route.params.sale_tab'(newV) {
      this.changeTabHeader(newV);
    }
  },
  methods: {
    onLoadPage(event){
      // console.log('event-loading', event);
      if(event){
        this.changeTabHeader(this.$route.params.sale_tab);
      }
    },
    // ==================CALL API==============
    async getEmployeeRewardOverView(){
      this.isLoading = true;
      // console.log(this.employeeRewardOverView);
      await AdminService.getEmployeeRewardOverView(this.employeeRewardOverView).then(res => {
        this.topMarketingEmployees = res.data.top_marketing;

        // table
        this.tableData = res.data.daily;
        // this.changeTabHeader(1);
        this.lineChart.chartData.labels = [];
        this.lineChart.chartData.datasets[0].data = [];

        this.detailAmount = {
          daily_reward_amount: 0,
          product_reward_amount: 0,
          total_day_reward_amount: 0,
        };

        for (const item of res.data.daily) {
          // chart set data
          this.lineChart.chartData.labels.push(moment(item.data).format('DD-MM'));
          this.lineChart.chartData.datasets[0].data.push(item.daily_reward_amount + item.product_reward_amount) // Tổng thưởng ngày
console.log(this.lineChart.chartData);
          // table
          this.detailAmount.daily_reward_amount += item.daily_reward_amount;
          this.detailAmount.product_reward_amount += item.product_reward_amount;
          this.detailAmount.total_day_reward_amount += item.daily_reward_amount + item.product_reward_amount; 
        }
      });
      this.isLoading = false;
    },
    async getEmployeeRewardByUser(){
      this.isLoading = true;
      let paging = {
        page: this.pagingParams.page,
        per_page: this.pagingParams.perPage
      };
      let params = {...this.rewardByUserParams, ...paging};
      if (this.sortParams.shouldSort) {
        params.sort_type = this.sortParams.sortType;
        params.sort_by = this.sortParams.sortBy;
      }
      // console.log(params);
      try {
        await AdminService.getEmployeeRewardByUser(params).then(res => {
          this.pagingParams.lastPage = res.last_page;
          this.pagingParams.total = res?.totalData ?? 0;
          // this.rewardByUserDataTotal = res.total;
          let firstRow = res.total;
          firstRow.marketing = {username: 'Tổng'};
          // console.log(res.data);
          this.rewardByUserData = [firstRow, ...res.data];
        });
      } catch (error) {
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    // ==================END CALL API==============
    toogleItem(item){
      if(this.toogleList.indexOf(item) === -1) {
        this.toogleList.push(item);
      }else{
        this.toogleList.splice(this.toogleList.findIndex((i) => i === item), 1)
      }
    },

    formatCurrency(currency){
      return helpers.formatCurrency(currency)
    },
    changeTabHeader(tab){
      this.tabTeleport = tab;
      // khi chuyển tab ẩn panel detail nếu có
      this.backPage();
      this.isLoading = true;
      switch (tab) {
        case this.headerTeleport.TONG_QUAN:
        this.labelPage = 'Tổng quan';
          this.columnsTable = [
            {
              field: "data",
              key: "a",
              title: "Ngày",
              align: "center",
              children: [
                {
                  field: "data",
                  key: "aa",
                  title: "",
                  width: 50,
                  renderBodyCell: ({ row, column, rowIndex }, h) => {
                    return (
                      <div>{moment(row.data).format('DD/MM')}</div>
                    );
                  }
                }
              ],
            },
            {
              field: "total_order",
              key: "b",
              title: "Tổng đơn",
              align: "center",
              children: [
                {
                  field: "total_order",
                  key: "bb",
                  title: "",
                  align: "right",
                  renderBodyCell: ({ row, column, rowIndex }, h) => {
                    return (
                      <div class="total-revenue">
                        {helpers.formatCurrency(row.total_order ?? 0)}
                      </div>
                    );
                  }
                }
              ],
            },
            {
              field: "total", key: "g1", title: "Tổng thưởng", align: "right",
              children: [
                {
                  field: "total",
                  key: "gg1",
                  align: "right",
                  renderHeaderCell: ({ row, column, rowIndex }, h) => {
                    return (
                      <div class="total-header-revenue">
                      {helpers.formatCurrency(this.detailAmount.total_day_reward_amount)}
                      </div>
                  );
                  },
                  renderBodyCell: ({ row, column, rowIndex }, h) => {
                    let classAmount = '';

                    let amount = row.product_reward_amount + row.daily_reward_amount;
                    // if(row.product_reward && row.product_reward.pr_reward){
                    //   if(row.product_reward.pr_reward.amount){
                    //     amount = helpers.formatCurrency(row.product_reward.pr_reward.amount);
                    //   }

                    //   if(row.product_reward.pr_reward.amount > 0 && row.product_reward_selected){
                    //     classAmount = 'achieved-amout';
                    //   } else if(row.product_reward.pr_reward.amount > 0) {
                    //     classAmount = 'not-reached';
                    //   }
                    // }

                    return (
                      <div class="money-reward">
                      <span class={classAmount}>
                      {helpers.formatCurrency(amount)}
                      </span>
                      </div>
                  );
                  }
                }
              ],
            },
            {
              field: "daily_reward_amount", key: "f", title: "Thưởng Daily", align: "center",
              children: [
                {
                  field: "daily_reward_amount",
                  key: "ff",
                  title: "",
                  align: "right",
                  renderHeaderCell: ({ row, column, rowIndex }, h) => {
                    return (
                      <div class="total-header-revenue">
                        {helpers.formatCurrency(this.detailAmount.daily_reward_amount)}
                      </div>
                    );
                  },
                  renderBodyCell: ({ row, column, rowIndex }, h) => {
                    let classAmount = '';
                    // if(row.daily_reward && row.daily_reward.pr_reward){
                    //   if(row.daily_reward.pr_reward.type === 1){
                    //     classAmount = 'reward-blue';
                    //   } else {
                    //     classAmount = 'reward-red';
                    //   }
                    // }

                    let amount = row.daily_reward_amount;
                    // if(row.daily_reward && row.daily_reward.pr_reward){
                    //   if(row.daily_reward.pr_reward.amount){
                    //     amount = helpers.formatCurrency(row.daily_reward.pr_reward.amount);
                    //   }

                    //   if(row.daily_reward_selected){
                    //     classAmount = 'achieved-amout';
                    //   } else if(row.daily_reward.pr_reward.amount > 0) {
                    //     classAmount = 'not-reached';
                    //   }
                    // }

                    return (
                      <div class="money-reward">
                        <span class={classAmount}>
                            {helpers.formatCurrency(amount)}
                        </span>
                      </div>
                    );
                  }
                }
              ],
            },
            {
              field: "product_reward_amount", key: "g", title: "Thưởng SP mới", align: "center",
              children: [
                {
                  field: "product_reward_amount",
                  key: "gg",
                  align: "right",
                  renderHeaderCell: ({ row, column, rowIndex }, h) => {
                    return (
                      <div class="total-header-revenue">
                        {helpers.formatCurrency(this.detailAmount.product_reward_amount)}
                      </div>
                    );
                  },
                  renderBodyCell: ({ row, column, rowIndex }, h) => {
                    let classAmount = '';

                    let amount = row.product_reward_amount;
                    // if (row.product_reward && row.product_reward.pr_reward) {
                    //   if (row.product_reward.pr_reward.amount) {
                    //     amount = helpers.formatCurrency(row.product_reward.pr_reward.amount);
                    //   }

                    //   if (row.product_reward.pr_reward.amount > 0 && row.product_reward_selected) {
                    //     classAmount = 'achieved-amout';
                    //   } else if (row.product_reward.pr_reward.amount > 0) {
                    //     classAmount = 'not-reached';
                    //   }
                    // }

                    return (
                      <div class="money-reward">
                        <span class={classAmount}>
                            {helpers.formatCurrency(amount)}
                        </span>
                      </div>
                    );
                  }
                }
              ],
            },
          ];
          // console.log(this.employeeRewardOverView);
          this.getEmployeeRewardOverView();
          break;
        case this.headerTeleport.THEO_NHAN_VIEN:
          this.labelPage = 'Theo nhân viên';
          this.columnsTable = [
            {
              field: "stt",
              key: "a",
              title: "STT",
              align: "center",
              width: 50,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <div>{rowIndex + this.pagingParams.perPage * (this.pagingParams.page - 1)}</div>
                );
              }
            },
            {
              field: "user_code",
              key: "b",
              title: "Mã nhân viên",
              align: "left",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="table-team">
                    <div class="team-name">{row.marketing.username}</div>
                    <div class="team-detail">ID: {row.marketing.id}</div>
                  </div>
                );
              }
            },
            {
              field: "fullname",
              key: "c",
              title: "Nhân viên",
              align: "left",
              width: 250,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <div class="table-fullname">
                      <div class="full-name-avatar">
                        <img src={row.marketing && row.marketing.avatar ? row.marketing.avatar : '/img/new_logo.png'} />
                      </div>
                      <div class="name-with-team">
                        <div class="fullname">{row.marketing.fullname}</div>
                        <div class="team">{row.marketing.role.display_name}</div>
                        <div class="leave-work">{row.marketing.status && row.marketing.status === 1 ? '' : ('Đã nghỉ việc từ ' + row.marketing.leave_job_date)}</div>
                      </div>
                    </div>
                );
              }
            },
            {
              field: "agency",
              key: "e",
              title: "Phòng ban",
              align: "left",
              width: 220,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <div class="table-team">
                      <div class="team-name">{row.marketing.team.team}</div>
                      <div class="team-detail">{row.marketing.team.agency}</div>
                    </div>
                );
              }
            },
            {
              field: "total_reward_amount", key: "l1", title: "Tổng thưởng", align: "right", sortBy: "desc",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let className = rowIndex === 0 ? '' : 'total-reward';
                return (
                    <div class={className}>
                      {helpers.formatCurrency(row.total_reward_amount)}
                    </div>
                );
              }
            },
            {
              field: "daily_reward_amount", key: "l2", title: "Thưởng Daily", align: "right", sortBy: "",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let className = rowIndex === 0 ? '' : 'money-reward';
                return (
                    <div class={className}>
                      {helpers.formatCurrency(row.daily_reward_amount)}
                    </div>
                );
              }
            },
            {
              field: "product_reward_amount", key: "o", title: "Thưởng SP mới", align: "right", sortBy: "",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let className = rowIndex === 0 ? '' : 'money-reward';
                return (
                    <div class={className}>
                      {helpers.formatCurrency(row.product_reward_amount)}
                    </div>
                );
              }
            },
            {
              field: "revenue",
              key: "h",
              title: `Doanh số`,
              align: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <div class="revenue-type-total">
                      { helpers.formatCurrency(Math.ceil(row.revenue))}
                    </div>
                );
              }
            },
            {
              field: "ads_cost",
              key: "i",
              title: "Chi phí Ads",
              align: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <div class="ads_cost">
                      {helpers.formatCurrency(Math.ceil(row.ads_cost))}
                    </div>
                );
              }
            },
            {
              field: "ads_cost_percent",
              key: "k",
              title: "% CP Ads",
              align: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let percent_qc = (row.ads_cost / row.revenue) * 100;
                percent_qc = Number.isFinite(percent_qc) ? percent_qc.toFixed(2) : 0;
                return (
                    <div class="ads_cost">
                      {helpers.formatCurrency(percent_qc)}
                    </div>
                );
              }
            },
            {
              field: "ecommerce_cost",
              key: "l",
              title: "Chi phí TMĐT",
              align: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <div class="ads_cost">
                      {helpers.formatCurrency(Math.ceil(row.ecommerce_cost))}
                    </div>
                );
              }
            },
            {
              field: "other_cost",
              key: "m",
              title: "Chi phí khác",
              align: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <div class="ads_cost">
                      {helpers.formatCurrency(Math.ceil(row.other_cost))}
                    </div>
                );
              }
            },
            {
              field: "action", key: "paction", title: "Thao tác", align: "center", fixed: "right", width: 100,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                if (rowIndex !== 0) {
                  return (
                      <div class="action-detail" on-click={() => this.openDetail(row)}>
                        Chi tiết
                      </div>
                  );
                }
              }
            },
          ];
          this.refreshPagingParams();
          this.getEmployeeRewardByUser();
          break;
        case this.headerTeleport.THEO_SAN_PHAM:
        this.labelPage = 'Theo sản phẩm';
        this.columnsTable = [
            {
              field: "stt",
              key: "a",
              title: "STT",
              align: "center",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>{++rowIndex}</div>
                );
              }
            },
            {
              field: "product_name",
              key: "b",
              title: "Nhân viên",
              align: "left",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="table-product">
                      <div class="product-name-avatar">
                        <img src="" />
                        <div class="name">T102 Smart Watch</div>
                      </div>
                  </div>
                );
              }
            },
            {
              field: "tt",
              key: "c",
              title: "Thị trường",
              align: "center",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>Philippines</div>
                );
              }
            },
            {
              field: "new_product_date",
              key: "d",
              title: "Ngày tính bắt đầu tính SP mới",
              align: "center",
              width: 150,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>20-09-2023</div>
                );
              }
            },
            {
              field: "days_left", width: 150, key: "e", title: "Số lượng ngày SP mới còn lại", align: "center",
            },
            {
              field: "total_reward",
              key: "f",
              title: "Tổng thưởng",
              align: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-reward">1.000.000</div>
                );
              }
            },
            {
              field: "product_reward", key: "g", title: "Thưởng Daily", align: "center",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="money-reward">
                    1.000.000
                  </div>
                );
              }
            },
            {
              field: "product_reward", key: "h", title: "Thưởng SP mới", align: "center",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="money-reward">
                    1.000.000
                  </div>
                );
              }
            },
            {
              field: "action", key: "i", title: "Thao tác", align: "center",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="action-detail">
                    Chi tiết
                  </div>
                );
              }
            },
          ];
          break;
      }
    },

    changeDate(value){

      let start_date = moment(value).startOf('month');
      let end_date = moment(value).endOf('month');


      if(moment(value).isSame(moment(), 'month')){
        end_date = moment();
      }

      this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
      this.employeeRewardOverView = {
        start_date: start_date.format('YYYY-MM-DD'),
        end_date: end_date.format('YYYY-MM-DD'),
        revenue_type: this.tabActive,
      }
      this.rewardByUserParams = {
          start_date: start_date.format('YYYY-MM-DD'),
          end_date: end_date.format('YYYY-MM-DD'),
          revenue_type: this.tabActive
      }
      // =====================getTopRevenue================ //
    },
  
    switchButton(tabActive) {
      if (tabActive === this.tabActive) return;
      this.tabActive = tabActive;

      if(this.tabTeleport === this.headerTeleport.TONG_QUAN){
        this.employeeRewardOverView.revenue_type = tabActive;
        this.getEmployeeRewardOverView();
      } else if(this.tabTeleport === this.headerTeleport.THEO_NHAN_VIEN){
        this.rewardByUserParams.revenue_type = tabActive;
        this.refreshPagingParams();
        this.getEmployeeRewardByUser();
      }
      
    },
    // 
    convertToText(amount){
      return helpers.convertToText(amount);
    },

    async downloadExcel(){
      const params = {
        start_date: this.rewardByUserParams.start_date,
        end_date: this.rewardByUserParams.end_date,
        revenue_type: this.tabActive,
      };
      if (this.employeeRewardOverView.team_ids) {
        params.team_ids = this.employeeRewardOverView.team_ids;
      }
      if (this.sortParams.shouldSort) {
        params.sort_type = this.sortParams.sortType;
        params.sort_by = this.sortParams.sortBy;
      }
      let baseUrl = process.env.VUE_APP_API_BASE_URL_WORK + '/v1';
      let url = `${baseUrl}/reward/rewardByUser/export?${helpers.convertObjToQueryUrl(
          params
      )}`;
      window.open(url, '_blank');
    },

    createReport(){
      if(this.tabTeleport === this.headerTeleport.TONG_QUAN){
        this.employeeRewardOverView.revenue_type = this.tabActive;
        this.getEmployeeRewardOverView();
      } else if(this.tabTeleport === this.headerTeleport.THEO_NHAN_VIEN){
        this.rewardByUserParams.revenue_type = this.tabActive;
        this.refreshPagingParams();
        this.getEmployeeRewardByUser();
      }
    },

    changeTeamIds(event){
      // console.log(event);
      this.employeeRewardOverView.team_ids = event;
      this.rewardByUserParams.team_ids = event;
    },

    fitlerEmitCommon(event){
      this.employeeRewardOverView.marketing_platform_ids = event.marketing_platform_ids;
      this.rewardByUserParams.marketing_platform_ids = event.marketing_platform_ids;
      console.log('fitlerEmitCommon', this.rewardByUserParams);
    },

    changePagingParam(value) {
      this.pagingParams = value;
      this.getEmployeeRewardByUser();
    },

    refreshPagingParams() {
      this.pagingParams = {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      };
    },

    openDetail(data){
      this.detailPanel.data = data;
      this.detailPanel.data.start_date = this.rewardByUserParams.start_date;
      this.detailPanel.data.end_date = this.rewardByUserParams.end_date;
      this.detailPanel.data.revenue_type = this.rewardByUserParams.revenue_type;
      this.detailPanel.show = true;
    },

    backPage(){
      this.detailPanel.show = false;
      this.detailPanel.data = null;
    },

    sortChange(params) {
      this.refreshPagingParams();
      let shouldSort  = false;
      for (const property in params) {
        if (params[property] === 'asc') {
          shouldSort = true;
          this.sortParams.sortBy = property;
          this.sortParams.sortType = 'asc';
          break;
        } else if (params[property] === 'desc'){
          shouldSort = true;
          this.sortParams.sortBy = property;
          this.sortParams.sortType = 'desc';
          break;
        }
      }
      this.sortParams.shouldSort = shouldSort;
      this.getEmployeeRewardByUser();
    },

    exportToExcel() {
      let table = document.getElementsByClassName("ve-table-content");
      TableToExcel.convert(table[0], {
        name: `CHI TIẾT THƯỞNG THEO NGÀY.xlsx`,
        sheet: {
          name: 'CHI TIẾT THƯỞNG THEO NGÀY'
        }
      });
    }
  }
};
</script>
<style lang="scss">
$color-text: rgba(16, 24, 64, 1);
.management-sale-container{
  .filter-container {
    border-radius: 4px;
    border: 0.5px solid #D3D3D3;
    background: #F9F9F9;
    padding: 17px 32px 19px 22px;
    .filter-mode-sale{
      &:hover{
        cursor: pointer;
      }
      overflow: hidden;
      border: 1px solid #D3D3D3;
      border-radius: 4px;
      width: max-content;
      margin-bottom: 10px;
      .estimated-sales.active-mode{
        background: #FFF9D9;
        border-right: 1px solid #dbdbdb;
      }
      .actual-sales.active-mode{
        background: #FFF9D9;
        border-right: 1px solid #dbdbdb;
      }
    }
    .filter-label{
      background: #FFFFFF;
      padding: 9px 15px;
      color: #101840;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
    .filter-sale-date-picker{
      position: relative;
      align-items: start;
      .filter-datepicker{
        position: absolute;
        top: 0px;
        right: 0px;
        input {
              display: flex;
              width: 200px;
              padding: 6px 8px;
              align-items: center;
              gap: 8px;
              border-radius: 4px;
              border: 1px solid #D3D3D3;
              background: #FFF;
        }
        .filter-button{
          .filter-report{
            &:hover{
              cursor: pointer;
            }
            border-radius: 4px;
            background: #4D77EC;
            display: flex;
            padding: 6px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #fff;
            border: none;
            height: 35px;
          }
        }
      }
    }
  }
  .content-container{
    height: 100%;
    background: #fff;
    // border: 1px solid #cfcfcf;
    border-radius: 4px;
    margin-top: 25px;
    .statistical-header {
      margin-bottom: 15px;
      .statistical-title {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        align-items: center;
        color: rgb(16, 24, 64);
      }
    }
    .chart-header{
      .chart-title{
        font-weight: 600;
        text-transform: uppercase;
        color: rgb(16, 24, 64);
      }
    }
    .revenue-layout {
      margin-bottom: 20px;
      grid-gap: 20px;

      .md-layout-item {
        min-height: 60px;
        padding: 0;
        border: none;
        max-width: 180px;
        padding-left: 0 !important;
        .income {
          background: rgba(255, 138, 0, 1);
        }

        .total-reward {
          background: rgba(164, 82, 228, 1);
        }

        .revenue {
          background: rgba(228, 94, 82, 1);
        }

        .cpqc {
          background: rgba(0, 203, 106, 1);
        }

        .salary{
          background: rgba(14, 173, 135, 1);
        }

        .layout-money {
          height: 60px;
          text-align: center;
          padding-top: 5px;
          color: #fff;
          border-radius: 8px;
          .total-money {
            color: #fff;
            font-size: 18px;
          }
        }

        &:after {
          width: 100%;
          height: 100%;
          display: block;
          background: md-get-palette-color(red, 200);
          content: " ";
        }
      }
    }
    .chart-layout{
      margin-bottom: 20px;
    }
    .layout-right {
      padding: 0 !important;

      .career-path{
        margin-top: 20px;
      }
      .top-rank-member{
        .right-title{
          padding: 0 15px !important;
          // background: linear-gradient(rgba(160, 232, 255, 1), rgba(138, 199, 243, 1));
          .tab-active{
            position: relative;
              &:after{
                background: #ff7b00;
                position: absolute;
                bottom: 8px;
                width: 100%;
                height: 2px;
                border-radius: 4px;
                content: "";
            }
          }
          .live-button {
          border: 1px solid rgba(255, 0, 0, 1);
          border-radius: 4px;
          padding: 2px 8px 2px 16px;
          margin-left: 10px;
          font-size: 11px;
          font-weight: 500;
          text-transform: capitalize;
          color: rgba(255, 0, 0, 1);
          background: white;
          position: relative;
          &:after{
            position: absolute;
                content: "";
                top: 50%;
                left: 8px;
                width: 6px;
                height: 6px;
                background: red;
                border-radius: 100%;
                transform: translate(-50%, -50%);
            }
        }
        }
          .rank-week{
          .button-switch-top{
            padding: 0 10px;
          }
        }
        .content-right{
          padding: 0px 10px !important;
          // background: linear-gradient(rgb(241 252 255), rgb(224 242 255));
          .member-top-list{
            background: transparent;
            counter-reset: item;
            list-style-type: none;
            position: relative;
            min-height: 250px;
            padding: 0;
            .loading-top-rank {
              font-size: 14px;
              margin: 10px;
            }
            .rank-top-list{
              &:not(:last-child){
                border-bottom: 1px solid #d8d8d8;
              }
              .md-avatar{
                border: 2px solid #fcaf17;
              }
            }
          }
          .member-rank-money{
            color: rgba(255, 0, 0, 1);
            font-weight: 600;
            font-size: 16px;
          }
        }
        .head-detail{
          grid-gap: 10px;
          height: 40px;
          &:hover{
            cursor: pointer;
          }
        }
      }
      .top-bonus-by-product{
        .rank-top-list{
          .md-avatar{
            border: 1px solid #dfdede !important;
            border-radius: 0;
          }
        }
      }

      .layout-right-child {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        margin-bottom: 20px;
        .right-title {
          padding: 10px 20px !important;
          .head-text{
            text-transform: uppercase;
            color: rgba(16, 24, 64, 1);
            font-weight: 600;
            font-size: 14px;
          }
          .head-detail{
            .link-href{
              color: #1765fc !important;
              font-size: 14px;
            }
            .md-icon{
              color: #1765fc !important;
              font-size: 18px !important;
            }
          }
        }

        .md-list {
          .md-subheader {
            min-height: min-content !important;
            padding: 5px 10px 0 30px;
            color: $color-text;
            font-weight: 500;
            font-size: 14px;
            text-transform: uppercase;
            position: relative;
            &:after{
              content: "";
              width: 10px;
              height: 10px;
              background: #0066ff;
              position: absolute;
              top: 50%;
              left: 10px;
              border-radius: 100%;
              z-index: 5;
              transform: translateY(-50%);
            }
          }

          .md-list-item {
            margin: 0;
          }

        }

        .content-right{
          padding: 10px;
          min-height: 300px;
          .layout-top-member{
            .bonus-pecent{
              .bonus-sale-total{
                background: rgba(240, 245, 255, 1) !important;
                color: rgba(55, 107, 251, 1) !important;
              }
              .bonus-sale-total-condition{
                background: rgba(55, 107, 251, 1) !important;
                  color: #fff;
              }
            }
            .bonus-total-money {
                width: max-content;
                border-radius: 4px;
                border: 1px solid rgba(92, 162, 34, 1);
                margin-bottom: 5px;
                .bonus-sale-total{
                  background: #edfad0;
                  color: #5ca222;
                  font-size: 13px;
                  font-weight: 600;
                  padding: 10px;
                }
                .bonus-sale-total-condition{
                  background: rgba(92, 162, 34, 1);
                  color: #fff;
                  font-size: 13px;
                  padding: 10px;
                }
            }
            .bonus-money-title{
              font-weight: 600;
              font-size: 14px;
              color: $color-text;
              margin-bottom: 5px;
            }
            .bonus_money{
              color: rgba(255, 123, 0, 1);
              font-weight: 600;
              font-size: 14px;
            }
          }
          .timeline-container{
            margin-left: 20px;
            .timeline__event{
              position: relative;
              padding-left: 20px;
              padding-bottom: 20px;
              width: 100%;
              &:after{
                content: "";
                width: 2px;
                height: 100%;
                background: rgba(138, 142, 160, 1);
                position: absolute;
                top: 10px;
                left: 0;
                z-index: -1;
              }
              &:before{
                content: "";
                width: 12px;
                height: 12px;
                position: absolute;
                background: rgba(138, 142, 160, 1);
                border-radius: 100%;
                left: -5px;
                top: 5px;
              }
              &:first-child:before{
                background: rgba(255, 0, 0, 1);
              }
              &:last-child:after{
                height: 0%;
              }
              // :not(:last-child)
              .timeline__event__date {
                font-weight: 500;
                font-size: 14px;
                color: rgba(82, 87, 111, 1);
              }
              .timeline__event__title {
                  font-weight: 600;
                  font-size: 15px;
                  color: rgba(16, 24, 64, 1);
              }
              .timeline__event__description {
                  font-weight: 400;
                  font-size: 12px;
              }
            }
            .timeline__event_first{
              .timeline__event__title {
                  color: rgba(255, 123, 0, 1);
              }
            }
          }
        }
      }
    }
  }
  // 
  .vue-table-root{
    }
    @media (max-width:1300px) {
      .layout-left{
        min-width: 70%;
        max-width: 70%;
        flex: 0 1 70%
      }
      .layout-right{
        min-width: 30%;
        max-width: 30%;
        flex: 0 1 30%
      }
    }
    @media (max-width:1200px) {
      .layout-left{
        min-width: 100%;
        max-width: 100%;
        flex: 0 1 100%
      }
      .layout-right{
        margin-top: 20px;
        min-width: 50%;
        max-width: 50%;
        flex: 0 1 50%
      }
    }
}


</style>
