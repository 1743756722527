<template>
  <div>
    <modal name="update-shop-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom update-shop-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <md-card class="modal-header-custom">
        <md-dialog-title>
            <span class="neutral_1 heading-500">Cập nhật thông tin shop</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="closeModal">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content>
        <div class="md-gutter">
          <div>
            <div class="label required">
              <label>Tên shop</label>
            </div>
            <input type="text" class="w_100 input" v-model="newName">
          </div>
          <div>
            <div class="label">
              <label>Url</label>
            </div>
            <div class="input input-fake">{{shopInfo ? shopInfo.url : ''}}</div>
          </div>
          <div>
            <div class="label">
              <label>Mô tả</label>
            </div>
            <textarea class="w_100" style="height: 100px" v-text="shopInfo ? shopInfo.description : ''" disabled></textarea>
          </div>
          <div>
            <div class="label">
              <label>Thị trường</label>
            </div>
            <div class="input input-fake">{{shopInfo ? shopInfo.country : ''}}</div>
          </div>
          <div>
            <div class="label">
              <label>NTQC</label>
            </div>
            <div class="input input-fake">{{(shopInfo && shopInfo.marketing_platform) ? shopInfo.marketing_platform.name : ''}}</div>
          </div>
        </div>
      </md-card-content>

      <md-dialog-actions>
        <button type="submit" class="btn-active md-button" @click="updateShop" :disabled="loading || newName === '' || newName === oldName">

          <vue-element-loading :active="loading" spinner="bar-fade-scale"/>
          Cập nhật
        </button>
        <button class="btn-stroke md-button" @click="closeModal">Huỷ</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import AdminService from "../../../store/services/AdminService";
import moment from "moment";
import ShopManagementService from "@/store/services/ShopManagementService";
export default {
  name: 'update-shop-modal',
  components: {
  },
  mounted() {
  },
  data: () => ({
    newName: '',
    oldName: '',
    shopInfo: null,
    loading: false
  }),
  computed: {
  },
  methods: {
    beforeOpened(event) {
      this.shopInfo = event.params.shopData;
      this.newName = this.shopInfo.name;
      this.oldName = this.shopInfo.name;
    },
    beforeClosed(event) {
    },

    closeModal(){
      this.$modal.hide('update-shop-modal')
    },

    async updateShop(){
      if(this.loading){
        return;
      }

      const params = {
        id: this.shopInfo.id,
        name: this.newName
      }

      this.loading = true;
      try {
        await ShopManagementService.updateShop(params).then(res => {
            if(res){
              this.$emit('reload-data');
              this.$store.dispatch("alerts/success", 'Chỉnh sửa thành công');
              this.$modal.hide('update-shop-modal');
            }
          });
      } catch (error) {
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
      }
      this.loading = false;
    },
  }
}
</script>

<style lang="scss">
.update-shop-modal{
  .mx-datepicker{
    width: 100% !important;
    margin-bottom: 10px;
  }
  .holiday-name{
    margin-bottom: 10px;
  }
  .input{
    height: 35px;
    display: block;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    padding: 6px 8px;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  .input-fake{
    background-color: #eee;
  }
  .date-in-month{
    .multiselect__tags{
      padding: 8px 40px 0 0;
      .multiselect__single{
        font-size: 13px;
      }
    }
  }
}
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
  .loading-ex{
    position: unset !important;
  }
}
.mx-datepicker-popup{
  z-index: 99999 !important; 
}
</style>
