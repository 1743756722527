import BaseService from "./BaseService";

export default class TimeSheetService extends BaseService {
    static async getTimeSheetByRole(params = {}) {
        const res = await this.get(`/schedule/by-role-and-month`, params, "TIMESHEET");
        return res.data;
    }

    static async getLeaves(params = {}) {
        const res = await this.get(`/leaves`, params, 'TIMESHEET');
        return res.data;
    }

    static async getLeaveDetail(params = {}) {
        const res = await this.get(`/leaves/${params.id}`, {}, 'TIMESHEET');
        return res.data;
    }

    static async approveLeaveRequest(params = {}) {
        const res = await this.post(`/leaves/approve-request`, params, 'TIMESHEET');
        return res.data;
    }

    static async updateCheckinOnlineSchedule(params = {}) {
        let id = params.id;
        delete params.id;
        const res = await this.post(`/checkin-online/${id}`, params, 'TIMESHEET');
        return res.data;
    }
}
