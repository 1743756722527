<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
    >
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <div :class="{ content: !$route.meta.hideContent }">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
<!--      <content-footer v-if="!$route.meta.hideFooter"></content-footer>-->
    </div>
    <import-checkin-file-modal />
    <import-checkin-online-file-modal />
    <v-dialog/>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import TopNavbar from "./TopNavbar.vue";
import ImportCheckinFileModal from "@/pages/Components/Modal/SyncCheckinModal";
import ImportCheckinOnlineFileModal from "@/pages/Components/Modal/SyncCheckinOnlineModal";


function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
      document.getElementsByClassName(className)[0].scrollTop = 0;
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  function reinitScrollbar() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");
      initScrollbar("main-panel");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  }

// import ContentFooter from "./ContentFooter.vue";
  // import MobileMenu from "./Extra/MobileMenu.vue";
  // import UserMenu from "./Extra/UserMenu.vue";

  export default {
    components: {
      ImportCheckinFileModal,
      TopNavbar,
      ImportCheckinOnlineFileModal
      // ContentFooter,
      // FixedPlugin,
      // MobileMenu,
      // UserMenu,
    },
    data() {
      return {
        sidebarBackgroundColor: "white",
        sidebarBackground: "orange",
        // sidebarBackgroundImage:
        //   process.env.VUE_APP_BASE_URL + "/img/sidebar-2.jpg",
        sidebarMini: true,
        sidebarImg: false,
        image: process.env.VUE_APP_BASE_URL + "/img/laravel-vue.svg",
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize();
        }
      },
      logout() {
        this.$store.dispatch("logout");
      }
    },
    updated() {
      // reinitScrollbar();
    },
    mounted() {
      // reinitScrollbar();
    },
    watch: {
      // sidebarMini() {
      //   this.minimizeSidebar();
      // },
    },
  };
</script>
<style lang="scss">
  $scaleSize: 0.95;
  @keyframes zoomIn95 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
      opacity: 1;
    }
  }
  .main-panel .zoomIn {
    animation-name: zoomIn95;
  }
  @keyframes zoomOut95 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }
  .main-panel .zoomOut {
    animation-name: zoomOut95;
  }
</style>
