<template>
  <div class="employees-container">
    <div class="header-label">
      <div class="label">
        DANH SÁCH SHOP
      </div>
      <div class="btn-download d-flex-center-between">
        <div class="synchronization-excel-btn" @click="exportExcel"><md-icon class="mr-10px">file_download</md-icon>Xuất Excel</div>
        <div class="synchronization-excel-btn" @click="$modal.show('sync-shop-excel-modal')"><md-icon class="mr-10px">sync</md-icon>Excel đổi tên</div>
      </div>
    </div>

    <div class="filter-shop">
      <div class="condition-filter">
        <div class="d-flex-center-between">
          <input type="text" v-model="shopFilterValue.name" class="shop-name" name="name" placeholder="Nhập từ khóa...">
          <date-picker
            class="datetime-picked"
            placeholder="Select date range"
            v-model="timeRange"
            range-separator=" - "
            defaultDate="01/05/2023 - 31/05/2023"
            format="DD/MM/YYYY"
            type="date"
            :clearable="true"
            :confirm="false"
            :confirm-text="'Xác nhận thời gian tìm kiếm.'"
            :lang="lang"
            @change="changeDate"
            :range="true"
        >
          <template v-slot:input>
            <input v-model="dateSelection" type="text" name="date"
                    readonly
                    autocomplete="off"
                    placeholder="Chọn thời gian"
                    class="mx-input"/>
          </template>
          <template v-slot:icon-calendar>
            <md-icon>arrow_drop_down</md-icon>
          </template>
        </date-picker>
        </div>
        <div class="filter-sale-date-picker d-flex-center-between">
          <FilterCommon @filter="fitlerEmitCommon" :useForm="[1,2]"/>
          <div class="filter-button"><button class="btn filter-report" @click="getDataShop"> Tạo báo cáo </button></div>
        </div>
      </div>
    </div>
    <div class="mt-20px filter-selectbox-primevue mb-10px d-flex-center-between">
      <div></div>
      <pagination-template :params="pagingParams" @change-params="changePagingParam"/>
    </div>

    <TableEasy
        :columns="columnsTable"
        :tableData="tableData"
        :sortOption="sortOption"
        :scrollWidth="0"
        :borderAround="false"
        :borderX="true"
        :borderY="true"
    />
    <update-shop-modal @reload-data="getShops" />
    <updated-shop-histories-modal />
    <sync-shop-excel-modal @reloadData="getShops" />
  </div>
</template>

<script>

import PaginationMixin from "@/mixins/pagination-mixin";
import PaginationTemplate from "@/components/PaginationTemplate";
import {helpers} from "@/helper/helpers";
import ShopManagementService from "@/store/services/ShopManagementService";
import TableEasy from "@/pages/Workplace/Pages/TableEasy";
import UpdateShopModal from "@/pages/Components/Modal/UpdateShopModal";
import UpdatedShopHistoriesModal from "@/pages/Components/Modal/UpdatedShopHistoriesModal";
import DatePicker from "vue2-datepicker";
import vue2DatePicker from "@/mixins/config-vue2-datepicker";
import moment from "moment";
import FilterCommon from "../FilterCommon.vue";
import SyncShopExcelModal from "../../../Components/Modal/SyncShopExcelModal"
export default {
  components: {
    TableEasy,
    PaginationTemplate,
    UpdateShopModal,
    UpdatedShopHistoriesModal,
    DatePicker,
    FilterCommon,
    SyncShopExcelModal
  },

  mixins: [PaginationMixin, vue2DatePicker],

  data() {
    const date = new Date();
    return {
      helpers,
      columnsTable: [
        {
          field: "stt",
          key: "a",
          title: "STT",
          align: "center",
          fixed: "left",
          width: 50,
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div>{rowIndex + this.pagingParams.perPage * (this.pagingParams.page - 1) + 1}</div>
            );
          }
        },
        {
          field: "name",
          key: "b",
          title: "Tên shop",
          fixed: "left",
          align: "left",
          width: 300,
          sortBy: "asc",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div class="shop-name" title="Click để copy" on-click={() => this.copyText(row.name)}>
                  {row.name}<md-icon>content_copy</md-icon>
                  </div>
            );
          }
        },
        {
          field: "url",
          key: "c1",
          title: "Url",
          fixed: "left",
          align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div>
                  {(row.url != null && row.url !== '') ? <a href={row.url}>row.url</a> : ''}
                </div>
            );
          }
        },
        {
          field: "description",
          key: "ca",
          title: "Mô tả",
          align: "left",
        },
        {
          field: "country",
          key: "cb",
          title: "Thị trường",
          align: "left",
          width: 200,
        },
        {
          field: "NTQC",
          key: "c1a",
          title: "NTQC",
          fixed: "left",
          align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div>
                  {row.marketing_platform ? row.marketing_platform.name : ''}
                </div>
            );
          }
        },
        {
          field: "paction",
          key: "paction",
          width: 150,
          title: "Thao tác",
          align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div class="d-flex-center">
                  <div class="action-detail" on-click={() => this.openModal('EDIT', row)}>
                    Edit
                  </div>
                  <div class="action-detail ml-10px" on-click={() => this.openModal('HISTORY', row)}>
                    History
                  </div>
                </div>

            );
          }
        }
      ],
      tableData: [],
      timeRange: [],//new Date(date.getFullYear(), date.getMonth(), 1),// ngày tháng đầu tiên của tháng
      shopFilterValue: {
        start_date: '',
        end_date: '',
        name: '',
        country: [],
        marketing_platform_ids: [],
        order_by: {
          field: 'name',
          option: 'asc'
        }
      },
      dateSelection: [],
      sortOption: {
          sortChange: (params) => {
              console.log("sortChange::", params);
              this.shopFilterValue.order_by.option = params.name || 'asc';
              this.getShops();
          },
      }
    };
  },
  created() {
    this.getShops();
  },
  mounted() {
  },
  methods: {
    changePagingParam(value) {
      this.pagingParams = value;
      this.getShops();
    },
    async getShops(){
      this.isLoading = true;
      let paging = {
        page: this.pagingParams.page,
        per_page: this.pagingParams.perPage
      };
      let params = {...paging, ...this.shopFilterValue};

      try {
        await ShopManagementService.getShops(params).then(res => {
          const resData = res.data;
          this.pagingParams.lastPage = resData.last_page;
          this.pagingParams.total = resData.total;
          this.tableData = resData.data;

        });
      } catch (error) {
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    openModal(modalType, shopData) {
      if (modalType === 'EDIT') {
        this.$modal.show("update-shop-modal", {shopData: shopData});
      } else {
        this.$modal.show("updated-shop-histories-modal", {shopData: shopData});
      }
    },
    changeDate(value) {

      this.dateSelection = '';
      this.shopFilterValue.start_date = '';
      this.shopFilterValue.end_date = '';

      if(value[0] == null && value[1] == null){
        return;
      }
      let start_date = moment(new Date(value[0]));
      let end_date = moment(new Date(value[1]));


      this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
      this.shopFilterValue.start_date = start_date.format('YYYY-MM-DD');
      this.shopFilterValue.end_date = end_date.format('YYYY-MM-DD');

      // =====================getTopRevenue================ //
    },
    getDataShop(){
      this.getShops();
    },
    copyText(name){
      let TempText = document.createElement("input");
      TempText.value = name;
      document.body.appendChild(TempText);
      TempText.select();
      
      document.execCommand("copy");
      document.body.removeChild(TempText);
    },
    fitlerEmitCommon(e){
      this.shopFilterValue.country = e.markets;
      this.shopFilterValue.marketing_platform_ids = e.marketing_platform_ids;
      console.log('fitlerEmitCommon', this.shopFilterValue);
    },

    exportExcel(){
      this.shopFilterValue.user_id = this.$store.state.auth.user.id;
      let params = helpers.convertObjToQueryUrl(this.shopFilterValue);

      window.open(process.env.VUE_APP_API_BASE_URL+'/admin/v1/exportShopExcel?'+params, '_blank');
      // ShopManagementService.exportShop().then(res => {
      //   console.log(res);
      // });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.md-menu-content {
  z-index: 9999;
}

.employees-container {
  background: #fff;
  padding: 10px;

  .search-container {
    .multiselect {
      width: 250px;
    }
    .keyword {
      height: 40px;
      border: 1px solid #EDEFF5;
    }
    .keyword-type {
      width: 165px !important;
      border-radius: initial !important;
      .vs__dropdown-toggle {
        cursor: pointer;
      }
      .vs__actions {
        .vs__clear {
          display: none;
        }
      }
    }
  }

  .header-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F3F4;
    font-size: 14px;

    .actions {
      display: flex;
      grid-gap: 20px;
      padding: 10px;

      .tab-name {
        font-weight: 500;
        color: #898989;
        position: relative;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }

        .tab-active {
          color: #1200ff;
          margin-left: 15px;

          &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            top: 50%;
            left: 0;
            background: #1200ff;
            transform: translateY(-50%);
          }
        }
      }
    }

    .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem;
      align-items: center;
      grid-gap: 10px;

      .total-records-pagination {
        font-size: 13px;

        .total {
          color: #000;
          font-weight: 500;
        }
      }

      .md-menu {
        align-items: center;
        display: flex;
      }

      .md-button {
        background-color: unset !important;
        color: #000 !important;
        height: max-content;
        box-shadow: unset;

        .md-ripple {
          padding: 0 !important;

          .md-button-content {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .ve-table-layout {
    .ve-table-container {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #8888887a;
        border-radius: 5px;

        &:hover {
          background: #555;
        }
      }
    }

    .ve-table-body {
      .address {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .money {
        font-weight: 500;
      }

      .employee-avatar {
        .md-avatar {
          border-radius: 100%;
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          min-width: 30px;
          background: #f3f3f3;
          margin-right: 5px;
          z-index: 1;
        }
      }

      .number-employee {
        color: #1fb800;
        font-weight: 500;
      }

      .status-employee {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        margin: auto;
      }

      .non-active {
        border: 1px solid #1fb800;
        background: #1fb8001a;
      }

      .active {
        background: #1fb800;
      }
      .shop-name{
        position: relative;
        .md-icon{
          &:hover{
            cursor: pointer;
          }
          position: absolute;
          right: 0;
          font-size: 20px !important;
        }
      }
    }
  }

  .filter-shop{
    .filter{
      display: flex;
      grid-gap: 10px;
    }
    border-radius: 4px;
    border: 0.5px solid #D3D3D3;
    padding: 17px 32px 19px 22px;
    background: #F9F9F9;
    .condition-filter{
      display: grid;
      grid-gap: 10px;
    }
    .mx-input-wrapper, .datetime-picked{
      width: max-content;
    }
    input{
      width: 200px;
      height: 40px;
      display: block;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      background: #fff;
      font-size: 14px;
      color: #101840;
    }
    .filter-button{
        .filter-report{
          &:hover{
            cursor: pointer;
          }
          border-radius: 4px;
          background: #4D77EC;
          display: flex;
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: #fff;
          border: none;
          height: 35px;
        }
      }
  }
}
</style>
