<template>
  <div class="update-checkin-online__container">
    <div class="p-fluid weekly-shift">

      <div class="work-schedule-content">
        <div class="checkin-online-wrapper">
          <div class="w_50">
            <div class="d-flex-center-between mt-40px">
              <div>Checkin buổi sáng: </div>
              <div>
                <date-picker type="time"
                             :confirm="true"
                             :confirm-text="'Lựa chọn'"
                             value-type="format" format="HH:mm"
                             :range="true" v-model="morningCheckin"></date-picker>
              </div>
            </div>
            <div class="d-flex-center-between mt-20px">
              <div>Checkin buổi chiều: </div>
              <div>
                <date-picker type="time"
                             :confirm="true"
                             :confirm-text="'Lựa chọn'"
                             value-type="format" format="HH:mm"
                             :range="true" v-model="afternoonCheckin"></date-picker>
              </div>
            </div>
            <div class="d-flex-center-between mt-20px add-date-wrapper">
              <div>Thời gian chấm công</div>
              <date-picker type="date"
                           value-type="YYYY-MM-DD"
                           :clearable="false"
                           :confirm="true"
                           :disabled-date="disabledByMonth"
                           :lang="lang"
                           :confirm-text="'Thêm'"
                           :range="false" v-model="checkinOnlineChoosing" @confirm="addDayForCheckinOnline"
                           :shortcuts="shortcuts"
                           @open="openDatePicker"
                           >
                <template v-slot:input>
                  <div class="primary-btn">Thêm ngày</div>
                </template>
              </date-picker>
            </div>
          </div>
          <div class="checkin-online-day__wrapper">
            <div class="mt-20px mr-20px day-item pr-10px" v-for="(checkinDay, stt) in checkinOnlineDay" :key="'checkin-day' + stt">
              <div class="d-flex-center-between mr-10px">
                <span>{{helpers.convertDateByFormat(checkinDay, 'dddd, DD/MM')}}</span>
                <span class="icon" @click="removeCheckinOnlineDay(stt)">
                  <md-icon >close</md-icon>
                </span>
              </div>
              <div class="mr-20px">Lịch chấm công: 
                <span v-html="renderTextShift(checkinDay)"></span> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="footer-button">
      <Button label="Cập nhật" class="p-button-success" @click="updateCheckinOnlineSchedule"/>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import DatePicker from "vue2-datepicker";
import TimeSheetService from "@/store/services/TimeSheetService";
import {helpers} from "@/helper/helpers";
import vue2DatePicker from "@/mixins/config-vue2-datepicker";
import moment from "moment";
import EmployeeService from "@/store/services/EmployeeService";
import {SHIFT_TYPE} from "../../../../const/const"

export default {
  components: {
    Button,
    DatePicker
  },

  mixins: [vue2DatePicker],

  data() {
    return {
      helpers,
      morningCheckin: [],
      afternoonCheckin: [],
      checkinOnlineDay: [],
      checkinOnlineChoosing: '',
      shortcuts: [
        {
          text: 'Cả ngày',
          onClick: () => {
            this.changeShiftType(SHIFT_TYPE.FULL_DAY)
          },
        },
        {
          text: 'Ca sáng',
          class:'active',
          onClick: () => {
            this.changeShiftType(SHIFT_TYPE.MORNING)
          },
        },
        {
          text: 'Ca chiều',
          onClick: () => {
            this.changeShiftType(SHIFT_TYPE.AFTERNOON)
          },
        },
      ],
      shiftType: SHIFT_TYPE.FULL_DAY
    };
  },

  props: {
    dateMonth: {
      type: String,
      default: '',
    },
    checkinOnlineData: {
      type: Object,
      default: () => null,
    }
  },

  watch: {
    checkinOnlineData: {
      handler(newData) {
        if (newData) {
          this.morningCheckin = [newData.morning_checkin_from, newData.morning_checkin_to];
          this.afternoonCheckin = [newData.afternoon_checkin_from, newData.afternoon_checkin_to];
          this.checkinOnlineDay = newData.active_days ? newData.active_days : [];
        }
      },
      immediate: true // Set immediate để chạy handler ngay từ lần đầu tiên
    }
  },

  mounted() {

  },

  methods: {

    addDayForCheckinOnline(date) {
      if (this.checkinOnlineDay.includes(date)) {
        return;
      }

      let date_morning = date + ' 00:00:00';
      let date_afternoon = date + ' 12:00:00';
      let date_cache = date;

      if(this.shiftType == SHIFT_TYPE.MORNING){
        date_cache = date_morning;
      } else if (this.shiftType == SHIFT_TYPE.AFTERNOON){
        date_cache = date_afternoon;
      }

      let indexOnlineDay = this.checkinOnlineDay.findIndex((item) => moment(item).format('YYYY-MM-DD') == date);

      if(this.checkinOnlineDay[indexOnlineDay] == date_cache){
        return;
      }

      if(
        this.checkinOnlineDay.includes(date_morning) && (this.shiftType == SHIFT_TYPE.AFTERNOON) || 
        this.checkinOnlineDay.includes(date_afternoon) && (this.shiftType == SHIFT_TYPE.MORNING) ||
        this.checkinOnlineDay.length > 0 && this.shiftType == SHIFT_TYPE.FULL_DAY
        ){
        this.shiftType = SHIFT_TYPE.FULL_DAY;
        this.$set(this.checkinOnlineDay, indexOnlineDay, date);
      } else {
        this.checkinOnlineDay.push(date_cache);
      }
    },
    removeCheckinOnlineDay(index) {
      this.checkinOnlineDay.splice(index, 1);
    },
    disabledByMonth(date) {
      let dateFormat = moment(date).format("YYYY-MM-DD");
      let dateMonth = moment(this.dateMonth, "YYYY/MM").toDate();
      return date.getMonth() !== dateMonth.getMonth() || date.getYear() !== dateMonth.getYear() || this.checkinOnlineDay.includes(dateFormat);
    },
    updateCheckinOnlineSchedule() {
      if (!this.isValidCheckinOnlineForm()) {
        return;
      }
      let params = {
        id: this.checkinOnlineData.id,
        active_days: this.checkinOnlineDay,
        morning_checkin_from: this.morningCheckin[0],
        morning_checkin_to: this.morningCheckin[1],
        afternoon_checkin_from: this.afternoonCheckin[0],
        afternoon_checkin_to: this.afternoonCheckin[1],
      };
      TimeSheetService.updateCheckinOnlineSchedule(params).then(res => {
        if (res.status && res.data) {
          this.$store.dispatch("alerts/success", 'Bạn đã cập nhật thành công');
          this.$emit("updated")
        } else {
          this.$store.dispatch("alerts/error", 'Có lỗi xảy ra vui lòng thử lại');
        }
      });
    },
    isValidCheckinOnlineForm() {
      let isValid = true;
      if (!(this.morningCheckin[0] && this.morningCheckin[1])) {
        this.$store.dispatch("alerts/error", 'Checkin buổi sáng không được để trống');
        return false;
      }
      if (!(this.afternoonCheckin[0] && this.afternoonCheckin[1])) {
        this.$store.dispatch("alerts/error", 'Checkin buổi chiều không được để trống');
        return false;
      }
      return isValid;
    },
    openDatePicker(){
      this.shiftType = null;
      this.$nextTick(() => {
        const buttons = document.querySelector('.mx-datepicker-sidebar button').classList.add("btn-shortcut-active");;
      });
    },
    changeShiftType(type){
      // remove
      const buttons = document.querySelectorAll('.mx-datepicker-sidebar > button');
      buttons.forEach((item) => {
        if(item.classList.contains('btn-shortcut-active')){
          item.classList.remove('btn-shortcut-active');
        }
      });
      buttons[type].classList.add('btn-shortcut-active')
      this.shiftType = type;
    },
    renderTextShift(item){
      const isValidDateTime = moment(item, 'YYYY-MM-DD', true).isValid();
      if(isValidDateTime){
        return 'Cả ngày';
      }
      const time = moment(item).format('HH');
        if(time == '12'){
          return 'Ca chiều';
        } else if(time == '00'){
          return 'Ca sáng';
        }
    }
  }
};
</script>
<style lang="scss">
.update-checkin-online__container{
  max-width: 800px;
  margin: auto;
  .mx-datepicker-range {
    width: 170px !important;
  }
  .p-fluid{
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) -1px -1px 12px -2px, rgba(0, 0, 0, 0.3) 0 0 1px -3px;
  }
  .work-schedule-content {
    //min-height: 500px;
    .checkin-online-wrapper {
      .add-date-wrapper {
        .mx-datepicker {
          width: 122px;
        }
        .mx-icon-calendar {
          display: none !important;
        }
      }
      .checkin-online-day__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .day-item {
          background-color: #ecf7e7;
          border-radius: 4px;
          padding-left: 10px;
          color: #30a960;
          .md-icon {
            cursor: pointer;
            font-size: 15px !important;
          }
        }
      }

    }
  }

  .p-dropdown-label{
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
  .p-inputtext{
    padding: 5px 10px !important;
    font-size: 14px !important;
    height: 30px;
  }
  .weekly-shift{
    min-height: 555px;
    table{
      width: 100%;
      text-align: left;
      border-collapse: collapse;
      thead{
        tr{
          th{
            // background: #fff;
            //border-top: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
            padding: 10px;
          }
        }
      }
      tbody tr {
        border-bottom: 1px solid #d9d9d9;
      }
      .day-timesheet {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 10px;
      }
      .time-work{
        margin-bottom: 5px;
      }
      .list-time {
        padding: 10px 0;
      }
    }

  }
}
.mx-datepicker-sidebar{
    padding: 0;
    .btn-shortcut-active{
      color: #ff7b00;
      font-weight: bold;
    }
    .mx-btn-shortcut{
      width: 100%;
      padding: 5px 10px;
    }
  }
</style>