<template>
  <div class="update-work-day-modal-container">
    <modal name="update-work-day-modal"
           :click-to-close="false"
           width='500px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Chỉnh sửa ngày làm việc</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('update-work-day-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content class="import-coupon-modal">
        <div class="d-flex-center-between mb-20px">
          <md-checkbox name="day" v-model="isDayOff">Ngày nghỉ</md-checkbox>
        </div>
        <div v-show="!isDayOff">
          <div class="d-flex-center-between mt-15px" v-for="(item, index) in shiftList" :key="'shift + ' + index">
            <div class="heading-400 neutral-neutral-2">
              Ca {{index + 1}}
            </div>
            <div class="d-flex-center">
              <date-picker type="time" placeholder="Bắt đầu"
                           :confirm="true"
                           :confirm-text="'Lựa chọn'"
                           value-type="format" format="HH:mm"
                           v-model="item.start_time"></date-picker>
              <date-picker class="ml-15px" type="time" placeholder="Kết thúc"
                           :confirm="true"
                           :confirm-text="'Lựa chọn'"
                           value-type="format" format="HH:mm"
                           v-model="item.end_time"></date-picker>
              <span class="material-symbols-outlined remove-btn" @click="removeShift(index)">cancel</span>
            </div>
          </div>
        </div>
        <div class="mt-15px add-shift-btn" v-if="shiftList.length < 3 && !isDayOff" @click="addShift">
          <md-icon>add_circle_outline</md-icon>
          <span>Thêm ca</span>
        </div>
      </md-card-content>

      <md-dialog-actions>
        <button type="submit" class="btn-active md-button cursor-pointer" @click="updateWorkDay">Đồng ý</button>
        <div class="btn-stroke md-button" @click="$modal.hide('update-work-day-modal')">Huỷ</div>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>

import TimeSheetManagementService from "@/store/services/TimeSheetManagementService";
import DatePicker from "vue2-datepicker";
import {helpers} from "@/helper/helpers";

export default {
  name: 'update-work-day-modal',
  components: {
    DatePicker
  },
  computed: {
  },
  created() {
  },
  data() {
    return {
      show: false,
      isDayOff: false,
      ts_schedule_id: '',
      dayData: {},
      shiftList: [],
    }
  },
  methods: {
    async updateWorkDay() {
      let params = {
        day: this.dayData.day,
        ts_schedule_id: this.ts_schedule_id,
        ts_shifts: []
      };

      if (!this.isDayOff) {
        for (let i = 0; i < this.shiftList.length; i++) {
          let ts_shift = {
            start_time: helpers.convertTimeStringToSeconds(this.shiftList[i].start_time),
            end_time: helpers.convertTimeStringToSeconds(this.shiftList[i].end_time),
            order: i + 1,
            ts_shift_category_id: 1
          };
          params.ts_shifts.push(ts_shift);
        }
      }

      await TimeSheetManagementService.updateTSDayMonth(params, this.dayData.id).then(res => {
        if (res) {
          this.show = false;
          if (res.data) {
            this.$store.dispatch("alerts/success", 'Chỉnh sửa ngày làm việc thành công');
            this.$modal.hide('update-work-day-modal');
            this.$emit("updated");
          } else {
            this.$store.dispatch("alerts/error", res.message);
          }
        }
      }).catch(err => {
        this.show = false;
        this.$store.dispatch("alerts/error", 'Đồng bộ file không thành công.');
      });
    },

    beforeOpened(event) {
      this.isDayOff = false;
      const dayDataClone = JSON.parse(JSON.stringify(event.params.dayData));
      this.ts_schedule_id = event.params.ts_schedule_id;
      this.dayData = dayDataClone;
      this.shiftList = [...this.dayData.ts_shift];
      this.shiftList.forEach(item => {
        item.start_time = helpers.convertSecondsToTime(item.start_time);
        item.end_time = helpers.convertSecondsToTime(item.end_time);
      })
    },

    addShift() {
      this.shiftList.push({
        start_time: '',
        end_time: '',
      })
    },

    removeShift(index) {
      this.shiftList.splice(index, 1);
    },

    beforeClosed(event) {

    },

  }
}
</script>

<style lang="scss">
.update-work-day-modal-container {
  .md-dialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.md-dialog {
  .md-dialog-container {
    width: 300px !important;
  }
}

.import-coupon-modal {
  min-height: 300px !important;
  .shift-option {
    width: 120px !important;
    border-radius: initial !important;
    .vs__dropdown-toggle {
      cursor: pointer;
    }
  }
  .mx-datepicker {
    width: 110px !important;
  }
  .add-shift-btn {
    width: 110px;
    border: 1px solid #D8DAE5;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    background: white;
    font-weight: 500;
    font-size: 14px;
    .md-icon {
      color: #656565 !important;
    }
  }
  .remove-btn {
    margin-left: 5px;
    cursor: pointer;
    color: red;
  }
}

.update-work-day-modal * {
  font-size: 14px;
}

</style>
