<template>
    <div class="overpanel" style="position: absolute !important;">
        <vue-element-loading :active="isLoading" is-full-screen spinner="bar-fade-scale" />
        <div class="detail-panel-header" v-if="hasHeaderSlot">
            <slot name="header"></slot>
        </div>
        <div class="detail-panel-container">
            <slot></slot>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    components: {
        // DatePicker
    },
    mixins: [],
    props: {
        
    },
    data() {
        return {
            isLoading: false
        }
    },

    computed: {
        hasHeaderSlot() {
            return !!this.$slots.header;
        }
    },

    mounted(){
        this.$parent.$el.style = 'position: relative;';
    },
  
    methods: {
        backPage(){
            this.$emit('back-page', true);
        }
    }
  };
  </script>
<style lang="scss">
    .overpanel{
        position: absolute !important;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background: #fff;
        .md-field{
            &:after{
                background-color: unset !important;
            }
        }
        .md-select{
            border: 0.5px solid rgba(211, 211, 211, 1);
            border-radius: 4px;
        }
        .detail-panel-header {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .detail-panel-container{
          max-width: 1260px;
          padding: 10px 20px;
          margin: 20px auto;
        }
    }
</style>
  