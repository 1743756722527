<template>
  <div>
    <modal name="change-rate-modal"
           :click-to-close="false"
           width="500px"
           height="auto"
           class="create-group-modal modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500">Thay đổi tỷ giá</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('change-rate-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content>
        <div class="md-gutter">
          <div class="form-input-maket market-name">
            <div class="label">
              <label class="required">Thị trường</label>
            </div>
            <select class="mx-input"  name="market" id="" required @change="selectMarkets">
              <option value="0">Chọn thị trường</option>
              <option v-for="(item, index) in this.country" :key="index" :value="item">{{ item }}</option>
            </select>
          </div>
          <div class="form-input-maket">
            <div class="label">
              <label class="required">Ngày điều chỉnh (tỷ giá sẽ thay đổi từ ngày này)</label>
            </div>
            <input type="date" v-model="expired_time" class="mx-input input-date" required>
          </div>
          <div class="form-input-maket">
            <div class="label">
              <label class="required">Loại tỷ giá</label>
            </div>
            <select class="mx-input" v-model="type" name="type" id="" @change="selectMarkets($event, true)">
              <option value="1" :selected="type == 1">Tỷ giá marketing</option>
              <option value="2" :selected="type == 2">Tỷ giá công nợ</option>
            </select>
          </div>
          <div class="form-input-maket">
            <div class="label">
              <label class="required">Tỷ giá mới</label>
            </div>
            <input class="mx-input" v-model="rate" type="text">
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <button class="btn-active md-button" @click="createChangeRate">Đồng ý</button>
        <button class="btn-stroke md-button" @click="closeModal">Hủy</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import AdminService from '../../../store/services/AdminService';
import moment from 'moment/moment';
export default {
  name: 'change-rate-modal',
  computed: {
    show() {
      return this.showDialog;
    }
  },
  data() {
    return {
      markets: [],
      expired_time: moment().format('YYYY-MM-DD'),
      type: 1,
      rate: '',
      selected: null,
      country: []
    }
  },
  
  mounted() {
    // this.getGroups();
  },
  
  methods: {
    beforeOpened(event) {
      // let market = event.params;
      this.markets = event.params;
    
      for (let index = 0; index < event.params.length; index++) {
        const element = event.params[index];
        if(!this.country.includes(element.country)){
          this.country.push(element.country);
        }
      }
    },
    selectMarkets(event, type = false){
      let value = event.target.value;
      if(type){
        if(this.selected == null){
          return;
        }
        this.type = parseInt(value);
        value = this.selected.country;
      }

      if(value == 0){ 
        return;
       }

      const selfType = this.type;
      let item = this.markets.find((i) => {
        return i.country == value && i.type == selfType;
      });

      if(item){
        this.type = item.type;
        this.rate = item.rate;
        this.selected = item;
      }
      this.selected.type = this.type;
    },

    async createChangeRate(){
      if(this.selected == null){
        return;
      }
      const params = {
        currency: this.selected.id,
        change: {
          name: this.selected.name,
          abbr: this.selected.abbr,
          iso_code: this.selected.iso_code,
          country: this.selected.country,
          expired_time: moment(this.expired_time).format('YYYY-MM-DD HH:mm:ss'),
          rate: this.rate,
          default: this.selected.default,
          active: 1,
          type: this.selected.type
        }
      };

      await AdminService.createChangeRate(params).then(res => {
        if(res.status){
          this.$store.dispatch("alerts/success", 'Thay đổi tỷ giá thành công');
          this.$emit('reload-data');
          this.closeModal();
        } else {
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
        }
      });
    },

    closeModal(){
      this.$modal.hide('change-rate-modal');
      // this.markets = [],
      this.expired_time = moment().format('YYYY-MM-DD'),
      this.type = 1,
      this.rate = '',
      this.selected = null;
      // this.country = null;
    }
  }
}
</script>

<style lang="scss">
.change-rate-modal{
  .mx-datepicker{
    width: 100% !important;
    margin-bottom: 10px;
  }
}
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
  .loading-ex{
    position: unset !important;
  }
}
.form-input-maket {
    margin-bottom: 10px;
    label{
      font-weight: 500;
    }
    .input-date{
      padding: 6px !important
    }
  }
.mx-datepicker-popup{
  z-index: 99999 !important; 
}
</style>
