<template>
  <div class="timesheet-container" v-if="$route.params.tab === 'chi-tiet' || $route.params.tab === 'request'">
    <vue-element-loading :active="isLoading" is-full-screen spinner="bar-fade-scale"/>
    <div class="worksheet__container" v-show="$route.params.tab === TimesheetTeleport.BANG_CONG">
      <div class="header-label">
        <div class="label">
          Bảng công chi tiết
        </div>
        <div class="d-flex-center">
          <div class="synchronization-excel-btn ml-10px" @click="finalTotalWorkMonth">
            <!-- <md-icon>sync</md-icon> -->
            <span class="ml-5px">Chốt công Tháng {{ currentMonth }}</span>
          </div>
          <div class="synchronization-excel-btn ml-10px" @click="$modal.show('import-checkin-online-file-modal')">
            <span class="ml-5px">Đồng bộ checkin online</span>
          </div>
          <div class="synchronization-excel-btn ml-10px" @click="$modal.show('import-checkin-file-modal')">
            <span class="ml-5px">Đồng bộ chấm công</span>
          </div>
        </div>
      </div>
      <div class="filter-container mt-20px">
        <div class="d-flex-center search-container">
          <multiselect v-model="customer.value"
                       deselect-label="Can't remove this value"
                       track-by="id"
                       placeholder="Chọn nhân viên"
                       :options="customer.list"
                       :allow-empty="true"
                       :selectLabel="''"
                       :deselectLabel="''"
                       :selectedLabel="''"
                       :searchable="true"
                       :custom-label="helpers.getNameForUser"
                       :multiple="true"
                       @search-change="getEmployees($event)"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                      <span class="option__title">
                         <md-avatar>
                          <img :src="props.option.avatar ? props.option.avatar : '/img/default-avatar.png'" alt="avatar">
                        </md-avatar>
                        <span class="ml-5px">{{ helpers.getNameForUser(props.option) }}</span>
                      </span>
              </div>
            </template>
          </multiselect>
          <v-select class="keyword-type" :options="keywordTypeList" label="name" placeholder="Chọn loại nhóm"
                    :reduce="(option) => option.type"
                    :searchable="false"
                    v-model="keyword_type">
            <template #search="{attributes, events}">
              <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
              />
            </template>
            <template slot="option" slot-scope="origin">
              <div class="flex">
                <div class="col text-overflow-ellipsis">
                  <span class="text-400-medium neutral-1">{{ origin.name }}</span>
                </div>
              </div>
            </template>
          </v-select>
        </div>
        <div class="mt-10px filter-sale-date-picker d-flex-center-between">
          <div class="filter d-flex-center">
            <!--           filter-->
            <FilterCondition @onLoadPage="onLoadPage" @teamIds="changeTeamIds" :permission="permissionTimeSheet" :notUse="['status']"></FilterCondition>
            <!--           filter-->
          </div>
          <div class="filter-datepicker d-flex-center-between">
            <div class="datepicker">
              <date-picker
                  class="datetime-picked"
                  placeholder="Select date range"
                  v-model="timeRange"
                  range-separator=" - "
                  defaultDate="01/05/2023 - 31/05/2023"
                  format="DD/MM/YYYY"
                  type="month"
                  :clearable="false"
                  :confirm="false"
                  :confirm-text="'Xác nhận thời gian tìm kiếm.'"
                  :lang="lang"
                  @change="changeDate"
                  :range="false"
              >
                <template v-slot:input>
                  <input v-model="dateSelection" type="text" name="date"
                         readonly
                         autocomplete="off"
                         placeholder="Chọn thời gian"
                         class="mx-input"/>
                </template>
                <template v-slot:icon-calendar>
                  <md-icon>arrow_drop_down</md-icon>
                </template>
              </date-picker>
            </div>
            <div class="filter-button">
              <button class="btn filter-report" @click="getTimeSheet">
                Tạo báo cáo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="float-right mt-20px">
        <pagination-template :class="'mb-10px float-right'" :params="pagingParams" @change-params="changePagingParam" />
      </div>
      <div class="ve-table-layout aza-table">
        <ve-table border-y
                  id="azatable"
                  row-key-field-name="rowKey"
                  :columns="columns"
                  :tableData="tableData"
                  :sort-option="sortOption"
                  :scroll-width="1100"
        />
      </div>
    </div>
    <div class="leave-request__container" v-if="$route.params.tab === TimesheetTeleport.LEAVE_REQUEST">
      <time-off-content />
    </div>
    <Teleport to="#menu-card">
      <div class="menu-item" :class="{'menu-item-active': $route.params.tab === TimesheetTeleport.BANG_CONG}"
           @click="$router.push(`/bang-cong/${TimesheetTeleport.BANG_CONG}`)">Bảng công chi tiết
      </div>
      <div class="menu-item" :class="{'menu-item-active': $route.params.tab === TimesheetTeleport.LEAVE_REQUEST}"
           @click="$router.push(`/bang-cong/${TimesheetTeleport.LEAVE_REQUEST}`)">Danh sách đề xuất
      </div>
    </Teleport>
    <OverPanel v-if="overPanel.show && $route.params.tab === TimesheetTeleport.BANG_CONG">
      <template v-slot:header>
        <div class="timesheet-detail-infomation">
                <div class="back-page d-flex-center-between cursor-pointer" @click="closeDetailUser">
                        <md-icon>arrow_back</md-icon>
                    </div>
                <!-- <div class="timesheet-avatar">
                    <md-avatar>
                        <img src="/img/default-avatar.png" alt="avatar">
                    </md-avatar>
                </div> -->
                <div class="timesheet-fullname-department">
                    <div class="fullname">{{ overPanel.data.current_date.format("MMMM, YYYY") }}</div>
                    <div class="department-name">{{ overPanel.data.fullname }} ({{overPanel.data.username}}) </div>
                </div>
            </div>
            <div class="filter-detail">
                <div class="button-overview cursor-pointer" 
                  :class="{'filter-active': overPanel.header_button_active === 1}"
                  @click="() => overPanel.header_button_active = 1"
                  >
                  Tổng quan
                </div>
                <div class="button-worksheet cursor-pointer" 
                  :class="{'filter-active': overPanel.header_button_active === 2}"
                  @click="() => overPanel.header_button_active = 2"
                  >
                  Bảng công
                </div>
            </div>
      </template>
      <panelDetailUser :tab="overPanel.header_button_active" :v_data="overPanel.data" />
    </OverPanel>
  </div>
</template>

<script>

import moment from "moment";
import FilterCondition from "@/pages/Workplace/Pages/ManagementSale/FilterCondition";
import vue2DatePicker from "@/mixins/config-vue2-datepicker";
import DatePicker from 'vue2-datepicker';
import TimeSheetManagementService from "@/store/services/TimeSheetManagementService";
import {keywordTypeList, PERMISSION_CODE, TimesheetTeleport} from "../../../const/const";
import PaginationTemplate from "../../../components/PaginationTemplate";
import OverPanel from "./SidePanel/OverPanel";
import panelDetailUser from "./TimeSheet/panelDetailUser"
import EmployeeService from "@/store/services/EmployeeService";
import multiselect from "vue-multiselect";
import TimeOffContent from "@/pages/Workplace/Pages/TimeSheet/TimeOffContent";
import {helpers} from "@/helper/helpers";

export default {
  components: {
    FilterCondition,
    DatePicker,
    PaginationTemplate,
    OverPanel,
    panelDetailUser,
    multiselect,
    TimeOffContent,
},

  mixins: [vue2DatePicker],

  watch: {
    timeRange(newVal) {
      // console.log(newVal)
    },

  },

  data() {
    const date = new Date();
    return {
      helpers,
      keyword_type: 'fullname',
      keywordTypeList,
      leaveRequestList: [],
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      permissionTimeSheet: PERMISSION_CODE.ADMIN_TIMESHEET,
      closeOnClick: true,
      isLoading: false,
      columns: [],
      dateColumns: [],
      defaultColumns: [
        {
          field: "stt", key: "tt_1", title: "STT", align: "center", width: 50,
          fixed: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return ++rowIndex;
          },
        },
        {
          field: "username",
          key: "username",
          title: "Mã nhân viên",
          align: "left",
          fixed: "left",
          width: 120,
          sortBy: "asc",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div class="table-team">
                <div class="team-name">{row.username}</div>
                <div class="team-detail">ID: {row.id}</div>
              </div>
            );
          }
        },
        {
          field: "fullname",
          key: "a",
          title: "Nhân viên",
          align: "left",
          fixed: "left",
          width: 250,
          sortBy: "",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
              <div class="table-fullname">
                <div class="full-name-avatar">
                  {/* <img src={row.avatar} alt="Avatar"/> */}
                </div>
                <div class="name-with-team">
                  <div class="fullname">{row.fullname}</div>
                  <div class="team">{row.role.display_name}</div>
                  <div class="leave-work">{row.status && row.status === 1 ? '' : 'Đã nghỉ việc'}</div>
                </div>
              </div>
            );
          },
        },
        {
          field: "team",
          key: "division_1",
          title: "Phòng ban",
          align: "left",
          width: 200,
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
              <div class="table-team">
                <div class="team-name">{row.team.team}</div>
                <div class="team-detail">{row.team.agency}</div>
              </div>
            );
          },
        },
        {
          field: "timesheet_user_id",
          key: "timesheet_user_id_1",
          title: "Mã chấm công",
          align: "center",
          fixed: "left",
          width: 100,
        },
      ],
      tableData: [],
      options: ['list', 'of', 'options'],
      dateSelection: '',
      timesheetFilterValue: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        team_ids: [],
        sort_by: 'username',
        sort_type: 'asc'
      },
      currentMonth: null,
      timeRange: new Date(date.getFullYear(), date.getMonth(), 1),// ngày tháng đầu tiên của tháng
      sortParams: {
        shouldSort: false,
        sortType: 'asc',
        sortBy: "username"
      },
      overPanel: {
        show: false,
        data: {},
        header_button_active: 1
      },
      customer: {
        value: null,
        list: []
      },
      TimesheetTeleport,
      exporting_timesheet: false
    };
  },
  created() {
    if(!this.$route.params.tab){
      this.$router.push(`bang-cong/${TimesheetTeleport.BANG_CONG}`);
    }
    // this.getTimeSheet();
    let dateNow = new Date();
    let start_date = moment(dateNow).startOf('month');
    let end_date = moment(dateNow).endOf('month');
    this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
    this.finalWorkMonth();
    this.getEmployees();
  },
  mounted() {
  },
  methods: {
    async getEmployees(keyword = "") {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        let params = {
          page: 1,
          per_page: 20,
          keyword: keyword,
          keyword_type: this.keyword_type,
          team_ids: this.timesheetFilterValue.team_ids
        }
        let dataEmployee = new Set();
        EmployeeService.getEmployees(params).then(res => {
          if (res && res.data) {
            res.data.forEach(item => dataEmployee.add(item));
            this.customer.list = Array.from(dataEmployee);
          }
        });
      }, 800);
    },

    onLoadPage(event){
      if(event){
        this.getTimeSheet();
      }
    },

    refreshTableColumn() {
      this.dateColumns = this.getDateColumnInRange(this.timesheetFilterValue.start_date, this.timesheetFilterValue.end_date);
      this.columns = this.defaultColumns.concat(this.dateColumns);
      // Thêm field chi tiết vào cuối
      this.columns.push({
          field: "paction", key: "paction", width: 100, title: "Thao tác", align: "center", fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
                  <div class="action-detail" on-click={() => this.openDetailUser(row)}>
                    Chi tiết
                  </div>
              );
          }
        });
      //   console.log(this.columns);
    },

    async getTimeSheet() {
      this.isLoading = true;
      let resData = [];
      let paging = {
        page: this.pagingParams.page,
        limit: this.pagingParams.perPage,
        per_page: this.pagingParams.perPage
      };
      let params = {...this.timesheetFilterValue, ...paging};
      if (this.customer.value) {
        params.user_ids = this.customer.value.map(item => item.id);
      }
      if (this.sortParams.shouldSort) {
        params.sort_type = this.sortParams.sortType;
        params.sort_by = this.sortParams.sortBy;
      }else{
        this.refreshTableColumn();
      }
      await TimeSheetManagementService.getTimesheet(params).then(res => {
        this.pagingParams.lastPage = res.lastPage;
        this.pagingParams.total = res.total;
        resData = res.data;
      });
      this.tableData = this.convertResDataToTableData(resData);
      this.isLoading = false;
      this.finalWorkMonth();
    },

    getDateColumnInRange(startDate, endDate) {
      let dateColumnArray = [];
      let currentDate = new Date(startDate);
      let today = new Date();

      while (currentDate <= new Date(endDate)) {
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1; // Lưu ý: getMonth trả về giá trị từ 0 đến 11
        let newCurrent = new Date(currentDate);
        // Định dạng ngày và thêm vào mảng
        let formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}`;
        let formattedColumn = {
          field: formattedDate, key: formattedDate, title: formattedDate, align: "center", width: 100,
          renderBodyCell: ({row, column, rowIndex}, h) => {
            if (newCurrent.getDay() === 0) return (<div></div>);
            let emptyTime = '--:--';
            let future = (newCurrent > today) ? "future" : "";
            let checkinTime = row[formattedDate] && row[formattedDate].checkin_time ? row[formattedDate].checkin_time : emptyTime;
            let checkoutTime = row[formattedDate] && row[formattedDate].checkout_time ? row[formattedDate].checkout_time : emptyTime;
            return (
                <div class = {future}>
                  <div class={`checkin-time ${checkinTime === emptyTime ? 'dont-checkin' : ''}`}>
                    <md-icon>arrow_drop_up</md-icon>
                    <span>{checkinTime}</span>
                  </div>
                  <div class={`checkin-time ${checkoutTime === emptyTime ? 'dont-checkin' : ''}`}>
                    <md-icon>arrow_drop_down</md-icon>
                    <span>{checkoutTime}</span>
                  </div>
                </div>
            );
          }
        };

        dateColumnArray.push(formattedColumn);

        // Tăng ngày cho vòng lặp
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateColumnArray;
    },

    convertResDataToTableData(resData) {
      let tableData = [];
      for (let i = 0; i < resData.length; i++) {
        let tableRow = {};
        tableRow.fullname = resData[i].fullname;
        tableRow.username = resData[i].username;
        tableRow.avatar = resData[i].avatar;
        tableRow.agency = resData[i].agency;
        tableRow.id = resData[i].id;
        tableRow.timesheet_user_id = resData[i].timesheet_user_id;
        tableRow.role = resData[i].role;
        tableRow.team = resData[i].team;
        tableRow.status = resData[i].status;
        resData[i].workdays.forEach(item => {
          if (item.date) {
            let date =  moment(item.date, 'YYYY-MM-DD');
            let dayKey = date.format('DD-MM')
            tableRow[dayKey] = {
              checkin_time: this.convertSecondsToTime(item.checkin_time),
              checkout_time: this.convertSecondsToTime(item.checkout_time)
            }
          }
        })
        tableData.push(tableRow);
      }
      return tableData;
    },

    changeDate(value) {
      let start_date = moment(value).startOf('month');
      let end_date = moment(value).endOf('month');

      this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
      this.timesheetFilterValue = {
        start_date: start_date.format('YYYY-MM-DD'),
        end_date: end_date.format('YYYY-MM-DD'),
      }
      // =====================getTopRevenue================ //
    },
    convertSecondsToTime(seconds) {
      if (seconds == null) {
        return null;
      }
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);

      // Định dạng thời gian thành chuỗi HH:MM
      let formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

      return formattedTime;
    },

    changeTeamIds(event){
      this.timesheetFilterValue.team_ids = event;
      this.getEmployees();
    },

    changePagingParam(value) {
      this.pagingParams = value;
      this.getTimeSheet();
    },

    refreshPagingParams() {
      this.pagingParams = {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      };
    },
    sortChange(params){
      this.refreshPagingParams();
      let shouldSort  = false;
      for (const property in params) {
        if (params[property] === 'asc') {
          shouldSort = true;
          this.sortParams.sortBy = property;
          this.sortParams.sortType = 'asc';
          break;
        } else if (params[property] === 'desc'){
          shouldSort = true;
          this.sortParams.sortBy = property;
          this.sortParams.sortType = 'desc';
          break;
        }
      }
      this.sortParams.shouldSort = shouldSort;
      this.getTimeSheet();
    },

    openDetailUser(data){
      document.getElementById('azatable').querySelector('.ve-table').style.height = '100px';
      this.overPanel.show = true;
      this.overPanel.data = data;
      this.overPanel.data.current_date = moment(this.timesheetFilterValue.start_date);
    },

    closeDetailUser(){
      document.getElementById('azatable').querySelector('.ve-table').style.height = null;
      this.overPanel.show = false;
      this.overPanel.data = {};
      this.overPanel.header_button_active = 1;
    },

    finalWorkMonth(){
      this.currentMonth = parseInt(moment(this.timesheetFilterValue.start_date).format('MM'));
    },

    async finalTotalWorkMonth(){
      // stop
      // if(this.exporting_timesheet) return;

      this.exporting_timesheet = true;
      const params = {
        year_month: moment(this.timesheetFilterValue.start_date).format('YYYY-MM')
      }
      let baseUrl = process.env.VUE_APP_API_BASE_URL_WORK + '/v1';
      let url = `${baseUrl}/exportExcelFinalWork?${helpers.convertObjToQueryUrl(
        params
      )}`;
      window.open(url, '_blank');
    //   try {
    //     await TimeSheetManagementService.exportTimeSheet(params).then(res => {
    //       // console.log(res);
    //       var file = window.URL.createObjectURL(res);
    // window.location.assign(file);
    //       // console.log('done');
    //     });
    //   } catch (error) {
    //     console.log(error);
    //     this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
    //   }
      // this.exporting_timesheet = false
    }
  }
};
</script>
<style lang="scss">
.md-menu-content {
  z-index: 9999;
}

.timesheet-container {
  .search-container {
    .multiselect {
      width: 250px;
    }
    .keyword {
      height: 40px;
      border: 1px solid #EDEFF5;
    }
    .keyword-type {
      width: 165px !important;
      border-radius: initial !important;
      .vs__dropdown-toggle {
        cursor: pointer;
      }
      .vs__actions {
        .vs__clear {
          display: none;
        }
      }
    }
  }
  .header-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkin-time {
    display: flex;
    color: black;

    .md-icon {
      margin: 0 !important;
    }
  }

  .dont-checkin {
    color: #ff7800;
    .md-icon {
      color: #ff7800;
    }
  }

  .future {
    * {
        color: #bdbdbd !important;
    }
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F3F4;
    font-size: 14px;

    .actions {
      display: flex;
      grid-gap: 20px;
      padding: 10px;

      .tab-name {
        font-weight: 500;
        color: #898989;
        position: relative;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }

        .tab-active {
          color: #1200ff;
          margin-left: 15px;

          &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            top: 50%;
            left: 0;
            background: #1200ff;
            transform: translateY(-50%);
          }
        }
      }
    }

    .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem;
      align-items: center;
      grid-gap: 10px;

      .total-records-pagination {
        font-size: 13px;

        .total {
          color: #000;
          font-weight: 500;
        }
      }

      .md-menu {
        align-items: center;
        display: flex;
      }

      .md-button {
        background-color: unset !important;
        color: #000 !important;
        height: max-content;
        box-shadow: unset;

        .md-ripple {
          padding: 0 !important;

          .md-button-content {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .ve-table-layout {
    .ve-table-container {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #8888887a;
        border-radius: 5px;

        &:hover {
          background: #555;
        }
      }
    }
  }

  .overpanel{
    .filter-detail{
      float: right;
      display: flex;
      align-items: center;
      grid-gap: 10px;
      line-height: 20px;
      letter-spacing: -0.05000000074505806px;
      text-align: left;
      .filter-active{
        font-weight: 600;
        color: rgb(92, 162, 34);
        border: 1px solid rgba(211, 211, 211, 1);
        padding: 6px 15px;
        border-radius: 4px;
      }
    }
    
    .timesheet-detail-infomation {
      float: left;
      display: flex;
      grid-gap: 10px;
    }
    .timesheet-fullname-department{
      text-transform: uppercase;
        .fullname{
          font-size: 16px;
          font-weight: 500;
        }
        .department-name{
          font-size: 12px;
        }
    }
  }

  .filter {
    .multiselect {
      max-width: 400px;
    }
  }
}
</style>
