<template>
  <div class="statistical-layout aza-table">
    <TableEasy
        :columns="columnsTable"
        :table-data="tableData"
        :scrollWidth="0"
        :borderAround="false"
        :borderX="true"
        :borderY="true"
    />
  </div>
</template>

<script>

import PaginationMixin from "@/mixins/pagination-mixin";
import {helpers} from "@/helper/helpers";
import TableEasy from "@/pages/Workplace/Pages/TableEasy";

export default {
  components: {
    TableEasy,
  },

  mixins: [PaginationMixin],

  data() {
    return {
      helpers,
      columnsTable: [
        {
          field: "created_at",
          key: "created_at",
          title: "Thời gian",
          align: "left",
          fixed: "left",
          width: 200,
        },
        {
          field: "updatedBy",
          key: "updatedBy",
          title: "Người thay đổi",
          fixed: "left",
          align: "left",
          width: 200,
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div>
                  {row.user.fullname}
                </div>
            );
          }
        },
        {
          field: "content",
          key: "content",
          title: "Nội dung",
          fixed: "left",
          align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div>
                  {`Đổi tên shop từ ${row.old_name} sang ${row.new_name}`}
                </div>
            );
          }
        }
      ],
    };
  },
  props: {
    tableData : {}
  }
};
</script>
<style lang="scss">
.statistical-layout {
  padding: 0 !important;

  .statistical-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;

    .statistical-title {
      font-weight: 600;
      text-transform: uppercase;
      color: rgba(16, 24, 64, 1);
    }

    .statistical-see-detail {
      a {
        color: rgba(23, 101, 252, 1) !important;
        font-size: 14px;
      }

      .md-icon {
        color: rgba(23, 101, 252, 1) !important;
        font-size: 18px !important;
      }
    }
  }

  .vue-table-root {
    .ve-table-header-tr {
      &:nth-child(2) {
        th {
          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            border-right: none !important;
          }

          border-right: 1px solid #eee !important;
          background-color: #fff !important;
          border-right: none;
        }
      }
    }

    .achieved-amout {
      color: rgba(92, 162, 34, 1);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.05000000074505806px;
      text-align: right;
    }

    .not-reached {
      color: rgba(16, 24, 64, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.05000000074505806px;
      text-align: right;
      text-decoration: line-through;
    }

    .total-header-revenue {
      color: rgb(255 0 0);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.05000000074505806px;
    }

    .total-daily-label {
      color: rgba(16, 24, 64, 1);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.05000000074505806px;
      text-align: right;

    }

    .duplicate-new-product {
      color: #ab3000;
      font-weight: 400;
    }

    .pecent-cpqc-lg {
      color: #d14343;
      height: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }

  .reward-name {
    /*word-break: break-all;*/
  }

  .total-reward {
    .reward-blue {
      color: rgba(36, 0, 255, 1);
      font-weight: 600;
    }

    .reward-red {
      color: rgba(255, 79, 79, 1);
      font-weight: 600;
      text-decoration: line-through;
    }
  }
}
</style>
