<template>
  <div>
    <modal name="detail-worktime-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom detail-worktime-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
    >
      <md-card class="modal-header-custom">
        <div class="header-detail-worktime">
            <div class="neutral_1 heading-700 fullname">{{ user.fullname }}</div>
            <div class="current-date cursor-pointer heading-500 mt-10px">
              {{ date }}
            </div>
        </div>
      </md-card>

      <md-card-content>
        <div class="md-gutter">
          <div class="item-rows type-of-work">
            <div class="item-row-label">
              Loại công
            </div>
            <div class="item-row-value">
              <div class="row-value">
                {{ typeOfWorkTitle }}
              </div>
              <div class="sub-row" v-if="data.s1 && data.s1.is_checkin_online || data.s2 && data.s2.is_checkin_online">
                <span>(Checkin online)</span>
              </div>
            </div>
          </div>
          <div class="item-rows worktime-checkin">
            <div class="item-row-label">
              Giờ checkin
            </div>
            <div class="item-row-value" v-if="data.daily_timesheet">
              <template v-if="data.daily_timesheet.checkin_online_time || data.daily_timesheet.checkout_online_time">
                {{ data.daily_timesheet.checkin_online_time ? data.daily_timesheet.checkin_online_time : '--:--' }}
              </template>
              <template v-else>
                {{ data.daily_timesheet.checkin_time ? data.daily_timesheet.checkin_time : '--:--' }}
              </template>
            </div>
          </div>
          <div class="item-rows worktime-checkout">
            <div class="item-row-label">
              Giờ checkout
            </div>
            <div class="item-row-value" v-if="data.daily_timesheet">
              <template v-if="data.daily_timesheet.checkin_online_time || data.daily_timesheet.checkout_online_time">
                {{ data.daily_timesheet.checkout_online_time ? data.daily_timesheet.checkout_online_time : '--:--' }}
              </template>
              <template v-else>
                {{ data.daily_timesheet.checkout_time ? data.daily_timesheet.checkout_time : '--:--' }}
              </template>
            </div>
          </div>
          <div class="item-rows late">
            <div class="item-row-label">
              {{ data.is_checkonline ? 'Muộn sáng' : 'Đi muộn' }}
            </div>
            <div class="item-row-value">
              {{ data.late > 0 && data.work_day.checkin_time ? `${data.late} phút` : '--:--' }}
            </div>
          </div>
          <div class="item-rows early">
            <div class="item-row-label">
              {{ data.is_checkonline ? 'Muộn chiều' : 'Về sớm' }}
            </div>
            <div class="item-row-value">
              {{ renderEarlyInfo() }}
            </div>
          </div>
        </div>
      </md-card-content>

      <md-dialog-actions>
        <button  class="btn-active md-button" @click="editDailyTimesheetModal">Chỉnh sửa chấm công</button>
        <button  class="btn-active md-button" @click="convertedToPaidLeave">Chuyển thành nghỉ có công</button>
        <button class="btn-stroke md-button" @click="closeModal">Đóng</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import TimeSheetManagementService from "../../../store/services/TimeSheetManagementService";
export default {
  computed: {},
  mounted() {
  },
  data: () => ({
    CONST: {
      empty: '--:--'
    },
    data: {},
    user: {},
    date: '',
    dateConfirm: '',
  }),
  computed: {
    typeOfWorkTitle(){
      const data = this.data;
      // nghỉ lễ
      // Nghỉ phép
      if(
          data.holiday ||
          (data.s1_leave_valid && data.s2_leave_valid) ||
          data.is_leave_valuable
        )
      {
        return data.title
      }

      // Quên chấm công
      if(data.miss_checkin){
        return data.title.split("/")[0].replace(/ /g," ")
      }
      if(data.late){
        return this.CONST.empty
      }

      if(data.is_leave_invalid){
        return data.title
      }

      let timesheet = false;
      for (let x in data.daily_timesheet) {
        if(data.daily_timesheet[x] !== null){
          timesheet = true;
        }
      }
      if((!timesheet)){
        return this.CONST.empty
      }
      return 'Công chuẩn';
    }
  },
  methods: {
    beforeOpened(event) {
      this.user = event.params.user;
      this.data = event.params.data;
      this.data.is_checkonline = this.data.s1 && this.data.s1.is_checkin_online || this.data.s2 && this.data.s2.is_checkin_online;
      this.date = this.data.date.format('dddd, DD/MM');
      this.dateConfirm = this.data.date.format('YYYY/DD/MM');
    },

    closeModal(){
      this.$modal.hide('detail-worktime-modal')
    },

    renderEarlyInfo(){
      let title = this.CONST.empty;
      if(this.data.early > 0 && this.data.daily_timesheet.checkout_time){
        title = `${this.data.early} phút`;
        if (!this.data.early_valid && !this.data.is_leave_afternoon){
          title += ' - Không phép';
        }
      }  
      return title;
    },

    renderLateInfo(){
      let title = this.CONST.empty;
      if(this.data.late > 0 && this.data.daily_timesheet.checkin_time){
        title = `${this.data.late} phút`;
      }  
      return title;
    },

    convertedToPaidLeave(){
      this.$modal.show('dialog', {
          title: 'Chuyển thành nghỉ có công',
          text: `Bạn có chắc muốn chuyển ngày <strong>${this.dateConfirm}</strong> thành nghỉ có công?`,
          buttons: [
              {
                  title: 'Đóng',
                  handler: () => {
                      this.$modal.hide('dialog')
                  }
              },
              {
                  title: 'Đồng ý',
                  handler: () => {
                    TimeSheetManagementService.createLeaveRequest(this.createParamLeaveRequest()).then(res => {
                      if (res && res.status) {
                        this.$store.dispatch("alerts/success", 'Thành công');
                        this.$modal.hide('detail-worktime-modal')
                        this.$modal.hide('dialog')
                      } else {
                        this.$store.dispatch("alerts/error", res.message + ' Vui lòng thử lại!');
                      }
                    });
                  }
              }
          ]
      })
    },

    createParamLeaveRequest()
    {
      let mySelf = this.user;
      let name = mySelf.fullname;
      if(mySelf.team.team){
        name += ` - ${mySelf.team.team}`;
      } else if(mySelf.team.department){
        name += ` - ${mySelf.team.department}`;
      } else if(mySelf.team.agency){
        name += ` - ${mySelf.team.agency}`;
      }

      name += ` - Nghỉ có công`;

      return {
        name: name,
        reason: 'Nghỉ có công',
        ts_leave_category_id: 6,
        use_decision_ids: [806],
        request_data: {
          shift_from: 1,
          shift_to: 2,
          date_from: this.dateConfirm,
          date_to: this.dateConfirm
        },
        type: 4,
        status: 1,
        role: [14, 7]
      }
    },

    editDailyTimesheetModal(){
      // this.$modal.hide('detail-worktime-modal')
      this.$emit('edit-worktime-modal');
    }
  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
  .loading-ex{
    position: unset !important;
  }
}
.detail-worktime-modal{
  .header-detail-worktime {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #cfcfcf;
  }
  .item-rows{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .item-row-value{
    color: rgba(16, 24, 64, 1);
    font-size: 14px;
    font-weight: 600;
    .sub-row {
      font-size: 12px;
      color: #a7a7a7;
    }
  }
  .current-date{
    text-transform: capitalize;
  }
}
</style>
