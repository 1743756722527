<template>
  <div>
    <modal name="add-holiday-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom add-holiday-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <md-card class="modal-header-custom">
        <md-dialog-title>
            <span class="neutral_1 heading-500">{{ holiday_id !== null ? 'Chỉnh sửa' : 'Thêm' }} ngày lễ</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="closeModal">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content>
        <div class="md-gutter">
          <div class="holiday-name">
            <div class="label">
              <label class="required">Tên ngày nghỉ</label>
            </div>
            <input type="text" class="mx-input" v-model="name" placeholder="Ví dụ: Nghỉ Tết Nguyên đán" required>
          </div>
          <div class="start-day">
            <div class="label">
              <label class="required">Ngày bắt đầu</label>
            </div>
            <date-picker v-model="start_day" format="YYYY-MM-DD" type="date" placeholder="Ngày bắt đầu"></date-picker>
          </div>
          <div class="end-day">
            <div class="label">
              <label class="required">Ngày kết thúc</label>
            </div>
            <date-picker v-model="end_day" format="YYYY-MM-DD" type="date" placeholder="Ngày kết thúc"></date-picker>
          </div>
        </div>
      </md-card-content>

      <md-dialog-actions>
        <button type="submit" class="btn-active md-button" @click="updateOrCreateHoliday">Đồng ý</button>
        <button class="btn-stroke md-button" @click="closeModal">Huỷ</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import AdminService from "../../../store/services/AdminService";
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
  name: 'add-holiday-modal',
  components: {
    DatePicker
  },
  mounted() {
  },
  data: () => ({
    name: '',
    start_day: null,
    end_day: null,
    holiday_id: null
  }),
  computed: {
  },
  methods: {
    beforeOpened(event) {
      let edit_data = event.params?.edit_data ?? null;
      if(edit_data){
        this.name = edit_data.name;
        this.start_day = new Date(edit_data.start_day);
        this.end_day = new Date(edit_data.end_day);
        this.holiday_id = edit_data.id;
      }
    },
    beforeClosed(event) {
      this.name = '';
      this.start_day = null;
      this.end_day = null;
      this.holiday_id = null;
    },

    closeModal(){
      this.$modal.hide('add-holiday-modal')
    },

    async updateOrCreateHoliday(){
      if(this.validation()){
        return;
      }

      const params = {
        name : this.name,
        start_day: moment(this.start_day).format('YYYY-MM-DD'),
        end_day: moment(this.end_day).format('YYYY-MM-DD')
      }

      try {
        if(this.holiday_id){
          await AdminService.updateHoliday(params, this.holiday_id).then(res => {
            if(res){
              this.$emit('reload-data');
              this.$modal.hide('add-holiday-modal');
            }
          });
        }else{
          await AdminService.createHoliday(params).then(res => {
            if(res){
              this.$emit('reload-data');
              this.$modal.hide('add-holiday-modal');
            }
          });
        }
      } catch (error) {
        this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
      }
    },

    validation(){
      if(this.name === '' || (this.start_day === '' && this.end_day === '')){
        this.$store.dispatch("alerts/error", 'Vui lòng nhập dữ liệu.');
        return true;
      } else if (moment(this.start_day).format('YYYY-MM-DD') > moment(this.end_day).format('YYYY-MM-DD')){
        this.$store.dispatch("alerts/error", 'Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc');
        return true;
      }
    }
  }
}
</script>

<style lang="scss">
.add-holiday-modal{
  .mx-datepicker{
    width: 100% !important;
    margin-bottom: 10px;
  }
  .holiday-name{
    margin-bottom: 10px;
  }
}
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
  .loading-ex{
    position: unset !important;
  }
}
.mx-datepicker-popup{
  z-index: 99999 !important; 
}
</style>
