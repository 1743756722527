<template>
    <div class="md-layout holiday-container">
        <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
        <div class="header-label">
            <div class="button-export">
                <div class="export-excel-btn" @click="$modal.show('add-holiday-modal')">
                    <span> Thêm ngày nghỉ </span>
                </div>
            </div>
        </div>
        <div class="table-holiday" style="min-height: 500px;">
            <TableEasy 
                :columns="columns"
                :tableData="tableData"
            />
        </div>
        <AddHolidayModal @reload-data="getHoliday" />
    </div>
  </template>
  <script>
  import TableEasy from './TableEasy.vue';
  import AddHolidayModal from "../../Components/Modal/AddHolidayModal"
  import AdminService from "../../../store/services/AdminService";

  export default {
    components: {
        TableEasy,
        AddHolidayModal
    },
    created() {
        this.getHoliday();
    },
  
    data() {
      return {
        columns: [
            // { 
            //   field: "",
            //   key: "a",
            //   type: "checkbox",
            //   title: "",
            //   width: 50,
            //   align: "center",
            // },
            { 
              field: "name",
              key: "b",
              title: "Ngày nghỉ",
              align: "left",
              height: 50
            },
            { 
              field: "start_day",
              key: "c",
              title: "ngày bắt đầu",
              align: "left"
            },
            { 
              field: "end_day",
              key: "d",
              title: "ngày kết thúc",
              align: "left",
            }
            ,
            { 
              field: "action",
              key: "e",
              title: "Thao tác",
              align: "left",
              width: 150,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="actions">
                    <button class="update-holiday create-order-btn" onclick={
                        () => this.$modal.show('add-holiday-modal', 
                            {
                                edit_data: row
                            }
                        )
                        }> Sửa </button>
                    <button class="delete-holiday create-order-btn" onclick={ () => this.showConfirmDelete(row.id)}> Xóa </button>
                  </div>
                );
              }
            }
        ],
        tableData: [],
        checkboxOption: {
            // row select change event
            selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
                // console.log(row, isSelected, selectedRowKeys);
            },
            // selected all change event
            selectedAllChange: ({ isSelected, selectedRowKeys }) => {
                // console.log(isSelected, selectedRowKeys);
            },
        },
        params: {
            start_date:'',
            from_date: '',
            // per_page: 
        },
        isLoading: false
      };
    },
  
    methods: {
        async getHoliday(){
            this.isLoading = true;
            try {
                await AdminService.getHoliday(this.params).then(res => {
                    if(res && res.data){
                        this.tableData = res.data;
                        this.$modal.hide('add-holiday-modal');
                    }
                });
            } catch (error) {
                this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
            }
            this.isLoading = false;
        },
        
        deteleHoliday(id){
            try {
                AdminService.deleteHoliday(id).then(res => {
                if(res){
                    this.getHoliday();
                }
                });
            } catch (error) {
                this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra. Vui lòng thử lại!');
            }
        },

        showConfirmDelete(id) {
            this.$modal.show('dialog', {
                title: 'Xóa ngày nghỉ lễ',
                text: 'Bạn có chắc muốn ngày nghỉ lễ',
                buttons: [
                    {
                        title: 'Không',
                        handler: () => {
                            this.$modal.hide('dialog')
                        }
                    },
                    {
                        title: 'Xóa',
                        handler: () => {
                            this.deteleHoliday(id);
                            this.$modal.hide('dialog')
                        }
                    }
                ]
            })
        },
    }
  };
  </script>
  <style lang="css">
  .holiday-container {
        .header-label {
            display: flex;
            justify-content: end;
            align-items: center;
            width: 100%;
        }
        .actions{
            display: flex;
            grid-gap: 10px;
        }
  }
  </style>
  