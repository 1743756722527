<template>
  <div>
    <modal name="updated-shop-histories-modal"
           :click-to-close="false"
           width='900px'
           height="auto"
           class="modal-custom updated-shop-histories-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <md-card class="modal-header-custom">
        <md-dialog-title>
            <span class="neutral_1 heading-500">{{`Lịch sử cập nhật thông tin shop (${shopInfo.name})`}}</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="closeModal">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content style="min-height: 300px">
        <updated-shop-histories-table :table-data="shopInfo.history" />
      </md-card-content>
      <md-dialog-actions>
        <button class="btn-stroke md-button" @click="closeModal">Huỷ</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import UpdatedShopHistoriesTable from "@/pages/Workplace/Pages/ShopManagement/UpdatedShopHistoriesTable";

export default {
  name: 'updated-shop-histories-modal',
  components: {
    UpdatedShopHistoriesTable
  },
  mounted() {
  },
  data: () => ({
    shopInfo: {
      name: '',
      history: []
    },
    shopHistories: [],
    loading: false
  }),
  computed: {
  },
  methods: {
    beforeOpened(event) {
      this.shopInfo = event.params.shopData;
    },
    beforeClosed(event) {
    },

    closeModal(){
      this.$modal.hide('updated-shop-histories-modal')
    },
  }
}
</script>

<style lang="scss">
.updated-shop-histories-modal{
  .mx-datepicker{
    width: 100% !important;
    margin-bottom: 10px;
  }
  .holiday-name{
    margin-bottom: 10px;
  }
  .input{
    height: 35px;
    display: block;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    padding: 6px 8px;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  .input-fake{
    background-color: #eee;
  }
  .date-in-month{
    .multiselect__tags{
      padding: 8px 40px 0 0;
      .multiselect__single{
        font-size: 13px;
      }
    }
  }
}
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
  .loading-ex{
    position: unset !important;
  }
}
.mx-datepicker-popup{
  z-index: 99999 !important; 
}
</style>
